export interface PropertyPrice {
  value: string
  label: string
}

export const propertyPrices: PropertyPrice[] = [
  {
    value: '0',
    label: '$0',
  },
  {
    value: '100',
    label: '$100',
  },
  {
    value: '200',
    label: '$200',
  },
  {
    value: '300',
    label: '$300',
  },
  {
    value: '400',
    label: '$400',
  },
  {
    value: '500',
    label: '$500',
  },
  {
    value: '600',
    label: '$600',
  },
  {
    value: '700',
    label: '$700',
  },
  {
    value: '800',
    label: '$800',
  },
  {
    value: '900',
    label: '$900',
  },
  {
    value: '1000',
    label: '$1k',
  },
  {
    value: '1250',
    label: '$1.25k',
  },
  {
    value: '1500',
    label: '$1.5k',
  },
  {
    value: '1750',
    label: '$1.75k',
  },
  {
    value: '2000',
    label: '$2k',
  },
  {
    value: '2250',
    label: '$2.25k',
  },
  {
    value: '2500',
    label: '$2.5k',
  },
  {
    value: '2750',
    label: '$2.75k',
  },
  {
    value: '3000',
    label: '$3k',
  },
  {
    value: '4000',
    label: '$4k',
  },
  {
    value: '5000',
    label: '$5k',
  },
  {
    value: '6000',
    label: '$6k',
  },
  {
    value: '7000',
    label: '$7k',
  },
  {
    value: '8000',
    label: '$8k',
  },
  {
    value: '9000',
    label: '$9k',
  },
  {
    value: '10000',
    label: '$10k',
  },
]
