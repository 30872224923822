import React from 'react'
import { useAppDispatch } from 'src/app/hooks'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import { setScheduleModalStepFiveOpen } from 'src/app/reducers/modalSlice';
import { ModalBody, ModalSubHeaderContainer } from 'src/listing/styles/SubmittedApplicationModal.style';

interface SubmittedApplicationModalProps {
    isScheduleModalStepFiveOpen: boolean
}

export const SubmittedApplicationModal = ({
  isScheduleModalStepFiveOpen,
}: SubmittedApplicationModalProps) => {
  const dispatch = useAppDispatch()

  const closeStepFiveModal = () => dispatch(setScheduleModalStepFiveOpen(false))

  const onClickHandler = () => {
    closeStepFiveModal()
  }

  return (
    <Modal
      onClose={closeStepFiveModal}
      height="50rem"
      width="54rem"
      isOpen={isScheduleModalStepFiveOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={closeStepFiveModal}>X</ModalCloseButton>
        <ModalOuterContainer>
          <ModalHeader>Schedule a Tour</ModalHeader>
          <ModalBody>
            <ModalSubHeaderContainer>
              <ModalSubHeader>Thanks! We’ll be in touch soon.</ModalSubHeader>
            </ModalSubHeaderContainer>
            <ModalPrimaryButton type="button" onClick={onClickHandler}>Done</ModalPrimaryButton>
          </ModalBody>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
