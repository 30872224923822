import React from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  AdditionalCheckboxFilterContainer,
} from 'src/renterDashboard/styles/AdditionalCheckboxFilter.style';

interface AdditionalCheckboxFilterProps {
    checked: boolean
    onChangeHandler: (any) => void
    label: string
    name: string
}

export const AdditionalCheckboxFilter = ({
  checked,
  onChangeHandler,
  label,
  name,
}: AdditionalCheckboxFilterProps) => (
  <AdditionalCheckboxFilterContainer>
    <FormControlLabel
      sx={{ '& .MuiTypography-root': { fontSize: 13 } }}
      control={(
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
          name={name}
          checked={checked}
          onChange={onChangeHandler}
        />
            )}
      label={label}
    />
  </AdditionalCheckboxFilterContainer>
)
