import React from 'react'
import { MapContainer } from 'src/renterDashboard/styles/PropertySearchMap.style'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { LatLng } from 'use-places-autocomplete';
import { Listing } from 'src/listing/types/listing.type';
import MarkerIcon from 'src/renterDashboard/assets/MapMarkerIcon.png'
// eslint-disable-next-line no-undef
import Animation = google.maps.Animation;

interface PropertySearchMapProps {
  listings: Listing[]
  mapHeight: string
  mapWidth: string
  containerWidth: string
  borderRadius: boolean
  noZoomControl: boolean
  containerHeight: string | null
  center: LatLng
  zoom: number
  // eslint-disable-next-line react/require-default-props
  listingCardOnHover?: string
  alt?: boolean
}

export const PropertySearchMap = ({
  mapHeight,
  mapWidth,
  containerWidth,
  borderRadius,
  noZoomControl,
  listings = [],
  containerHeight = null,
  center,
  zoom,
  listingCardOnHover = '',
  alt,
}: PropertySearchMapProps) => {
  const containerStyle = {
    width: mapWidth,
    height: mapHeight,
    borderRadius: borderRadius ? '20px' : '0px',
  };

  return (
    <MapContainer width={containerWidth} height={containerHeight} alt={alt}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          scaleControl: true,
          zoomControl: !noZoomControl,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          gestureHandling: 'greedy',
        }}
      >
        {listings?.length > 0 && listings.map((listing) => listing.latitude && listing.longitude && (
        <Marker
          key={listing.mls_id}
          position={{ lat: listing.latitude, lng: listing.longitude }}
          animation={listingCardOnHover === listing.mls_id ? Animation.BOUNCE : undefined}
          icon={MarkerIcon}
        />
        ))}
      </GoogleMap>
    </MapContainer>
  )
}
