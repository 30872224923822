import axios from 'axios'
import { BASE_URL } from 'src/app/constants/baseUrl';
import _ from 'lodash';
import { agentSchedule } from 'src/agentDashboard/constants/agentSchedule';
import { setAgentSchedule } from 'src/agentDashboard/reducers/agentSchedule';
import { findDayOfWeek } from 'src/agentDashboard/utils/findDayOfWeek';
import { getNumberFromTimeString } from 'src/agentDashboard/utils/getNumberFromTimeString';
import { getLabelFromTimeString } from 'src/agentDashboard/utils/getLabelFromTimeString';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { setAgentAvailabilityModalOpen } from 'src/app/reducers/modalSlice';

export const putAgentAvailability = (agentId, accessToken, pendingAgentSchedule) => (dispatch: any) => {
  const url = `${BASE_URL}/availability/${agentId}`

  const weekSchedule = [
    { day: 'Mon', schedule: pendingAgentSchedule.monday.schedule },
    { day: 'Tue', schedule: pendingAgentSchedule.tuesday.schedule },
    { day: 'Wed', schedule: pendingAgentSchedule.wednesday.schedule },
    { day: 'Thu', schedule: pendingAgentSchedule.thursday.schedule },
    { day: 'Fri', schedule: pendingAgentSchedule.friday.schedule },
    { day: 'Sat', schedule: pendingAgentSchedule.saturday.schedule },
    { day: 'Sun', schedule: pendingAgentSchedule.sunday.schedule },
  ]

  const formattedSchedule: any = []

  weekSchedule.forEach((day) => {
    day.schedule.forEach((scheduleEntry) => {
      formattedSchedule.push({
        start_time: scheduleEntry.startTime,
        end_time: scheduleEntry.endTime,
        day_of_week: day.day,
      })
    })
  })

  const body = {
    availabilities: formattedSchedule,
  }

  dispatch(setIsLoading(true))

  axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      const scheduleResponse = _.cloneDeep(agentSchedule)
      if (res.data.length > 0) {
        res.data.forEach((scheduleEntry) => {
          const entryDay = findDayOfWeek(scheduleEntry.day_of_week)
          if (entryDay) {
            const startTime = scheduleEntry.start_time
            const startTimeNum = getNumberFromTimeString(scheduleEntry.start_time)
            const startTimeLabel = getLabelFromTimeString(scheduleEntry.start_time)
            const endTime = scheduleEntry.end_time
            const endTimeNum = getNumberFromTimeString(scheduleEntry.end_time)
            const endTimeLabel = getLabelFromTimeString(scheduleEntry.end_time)
            scheduleResponse[entryDay].schedule.push({
              startTime,
              startTimeNum,
              startTimeLabel,
              endTime,
              endTimeNum,
              endTimeLabel,
            })
            scheduleResponse[entryDay].isActive = true
          }
        })
      }
      dispatch(setAgentSchedule(scheduleResponse))
      dispatch(setAgentAvailabilityModalOpen(false))
    })
    .catch((err) => console.error(err.message))
    .finally(() => dispatch(setIsLoading(false)))
}
