import React from 'react'
import 'react-calendar/dist/Calendar.css';
import { useAppDispatch } from 'src/app/hooks'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import { setScheduleModalStepFourOpen, setScheduleModalStepFiveOpen } from 'src/app/reducers/modalSlice';
import { BookingDetails } from 'src/listing/types/BookingDetails.type';
import {
  FormControlLabel, Radio, RadioGroup, FormControl,
} from '@mui/material';
import {
  BookingContainer,
  TimesColumn,
  TimesContainer,
  TimesRadioField,
} from 'src/listing/styles/BookingAvailabilityModal.style';
import { postBooking } from 'src/listing/actions/postBooking';

interface BookingAvailabilityModalProps {
    isScheduleModalStepFourOpen: boolean
    setBookingDetails: any
    bookingDetails: BookingDetails
    userId: string
    accessToken: string
    selectedListingId: string
    availabilitySchedule: any
}

export const BookingAvailabilityModal = ({
  isScheduleModalStepFourOpen,
  setBookingDetails,
  bookingDetails,
  userId,
  accessToken,
  selectedListingId,
  availabilitySchedule,
}: BookingAvailabilityModalProps) => {
  const [timeSelected, setTimeSelected] = React.useState('')
  const dispatch = useAppDispatch()

  const closeStepFourModal = () => dispatch(setScheduleModalStepFourOpen(false))
  const openStepFiveModal = () => dispatch(setScheduleModalStepFiveOpen(true))

  const onClickHandler = () => {
    const cleanupOnSuccess = () => {
      setBookingDetails({ ...bookingDetails, availabilityId: null })
      setTimeSelected('')
      closeStepFourModal()
      openStepFiveModal()
    }
    dispatch(postBooking(bookingDetails.bookingDate, timeSelected, accessToken, userId, cleanupOnSuccess, selectedListingId))
  }

  const onRadioChange = (e) => {
    setTimeSelected(e.target.value)
    setBookingDetails({ ...bookingDetails, availabilityId: null })
  }

  return (
    <Modal
      onClose={closeStepFourModal}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isScheduleModalStepFourOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={closeStepFourModal}>
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          <ModalHeader>Schedule a Tour</ModalHeader>
          <BookingContainer>
            <ModalSubHeader>Please select from available time slots</ModalSubHeader>
            <FormControl>
              <RadioGroup>
                <TimesContainer>
                  <TimesColumn>
                    {availabilitySchedule.slice(0, 8).map((option) => (
                      <TimesRadioField>
                        <input
                          name="time"
                          type="radio"
                          id={option.value}
                          key={option.value}
                          value={option.value}
                          disabled={!option.isValid}
                          onChange={onRadioChange}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                      </TimesRadioField>
                    ))}
                  </TimesColumn>
                  <TimesColumn>
                    {availabilitySchedule.slice(8, availabilitySchedule.length).map((option) => (
                      <TimesRadioField>
                        <input
                          name="time"
                          type="radio"
                          id={option.value}
                          key={option.value}
                          value={option.value}
                          disabled={!option.isValid}
                          onChange={onRadioChange}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                      </TimesRadioField>
                    ))}
                  </TimesColumn>
                </TimesContainer>
              </RadioGroup>
            </FormControl>
          </BookingContainer>
          <ModalPrimaryButton type="button" onClick={onClickHandler} disabled={!timeSelected}>Done</ModalPrimaryButton>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
