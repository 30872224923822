import styled, { css } from 'styled-components'
import { AppColors } from 'src/app/constants/appColors';
import { Link } from 'react-router-dom'

interface ColorProps {
  isOpen?: boolean
  isWhite?: boolean
  isAbsolute?: boolean
}

export const HeaderWrap = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  top: 0;
  left: 0;
  position: ${({ isAbsolute }) => isAbsolute ? 'absolute' : 'relative'}; 
  z-index: 10;

  @media (min-width: 1040px) {
    height: 120px;
  }
`

export const Header = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

`

export const Logo = styled(Link)<ColorProps>`
  color: ${({ isWhite, isOpen }) => isWhite && !isOpen ? 'white' : 'black'};  position: relative;
  z-index: 2;

  @media (min-width: 1040px) {
    color: ${({ isWhite }) => isWhite ? 'white' : 'black'};
  }
`

export const Nav = styled.ul<ColorProps>`
  display: none;

  ${({ isOpen }) => isOpen && css`
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: ${AppColors.LIGHT_BISQUE};
    padding: 100px 20px 0 20px;
  `}

  @media (min-width: 1040px) {
    display: inline-flex;
    align-items: center;
    height: auto;
    width: auto;
    background: transparent;
    padding: 0;
    gap: 40px;
    position: static;
  }
`

export const NavItem = styled.li`
  font-size: 2.4rem;
  display: block;
  font-family: 'DM Serif Text', serif;
  margin-bottom: 60px;

  @media (min-width: 1040px) {
    font-size: 1.5rem;
    margin-bottom: 0;
}`

export const NavAnchor = styled(Link)<ColorProps>`
  color: ${({ isWhite, isOpen }) => isWhite && !isOpen ? 'white' : 'black'};
  display: block;

  @media (min-width: 1040px) {
    color: ${({ isWhite }) => isWhite ? 'white' : 'black'};
  }
`

export const NavButton = styled.button<ColorProps>`
  color: ${({ isWhite, isOpen }) => isWhite && !isOpen ? 'white' : 'black'};
  padding: 0 32px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  color: ${({ isWhite, isOpen }) => isWhite && !isOpen ? 'white' : 'black'};
  border: ${({ isWhite, isOpen }) => isWhite && !isOpen ? `1px solid ${AppColors.WHITE}` : `1px solid ${AppColors.BLACK}`};
  font-family: 'DM Serif Text', serif;
  width: calc(50% - 7px);
  line-height: 46px;
  transition: 0.2s opacity;

  @media (min-width: 1040px) {
    width: auto;
    font-size: 1.4rem;
    line-height: 38px;
    color: ${({ isWhite }) => isWhite ? 'white' : 'black'};
    border: ${({ isWhite }) => isWhite ? `1px solid ${AppColors.WHITE}` : `1px solid ${AppColors.BLACK}`};
  }

  &:hover {
    opacity: 0.8;
  }

  &:first-of-type {
    background: ${AppColors.BLACK};
    color: ${AppColors.WHITE};

    @media (min-width: 1040px) {
      background: transparent;
      color: ${({ isWhite }) => isWhite ? 'white' : 'black'};
      border: ${({ isWhite }) => isWhite ? `1px solid ${AppColors.WHITE}` : `1px solid ${AppColors.BLACK}`};
    }
  }

  &:last-of-type {
    @media (min-width: 1040px) {
      border: none;
      padding: 0;
    }
  }

  & + & {
    margin-left: 14px;

    @media (min-width: 1040px) {
      margin: 0;
    }
  }
`

export const Hamburger = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isWhite }) => isWhite ? AppColors.WHITE : AppColors.BLACK};
  position: relative;
  z-index: 2;

  @media (min-width: 1040px) {
    display: none;
  }
`
