import React from 'react'
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  accessToken: string
  redirectPath: string
  children: any
}

export const ProtectedRoute = ({ accessToken, redirectPath, children }: ProtectedRouteProps) => {
  if (!accessToken) return <Navigate to={redirectPath} replace />
  return children
};
