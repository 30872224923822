import React from 'react'
import { useParams } from 'react-router-dom'
import { PropertyText } from 'src/listing/components/PropertyText'
import { PropertyImages } from 'src/listing/components/PropertyImages'
import TourSchedule from 'src/listing/components/TourSchedule'
import { PropertySearchMap } from 'src/renterDashboard/components/PropertySearchMap'
import { PropertyDisclaimerMessages } from 'src/listing/components/PropertyDisclaimerMessages'
import {
  ListingContainer,
  ListingReturnLink,
  ListingBody,
  LeftContainer,
  RightContainer,
  ListingMapContainer,
} from 'src/listing/styles/Listing.style'
import { Listing as ListingType } from 'src/listing/types/listing.type'
import { ListingGallery } from 'src/listing/components/ListingGallery';
import { getSelectedListing } from 'src/listing/actions/getSelectedListing';
import { connect } from 'react-redux';
import { useAppDispatch } from 'src/app/hooks';
import { setSelectedListing } from 'src/listing/reducers/selectedListingSlice';
import _ from 'lodash';
import Width from 'src/app/components/Width'
import { ReactComponent as BackArrow } from 'src/listing/assets/BackArrow.svg'

interface ListingProps {
  selectedListing: ListingType
  accessToken: string
  cognitoUser: any
}

export const Listing = ({ selectedListing, accessToken, cognitoUser }: ListingProps) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getSelectedListing(id))
  }, [])

  React.useEffect(() => () => {
    dispatch(setSelectedListing({}))
  }, [])

  if (!_.isEmpty(selectedListing)) {
    return (
      <ListingContainer>
        <Width>
          <ListingReturnLink to="/home/renter">
            <BackArrow /> Back to Listings
          </ListingReturnLink>
          <ListingBody>
            <LeftContainer>
              <PropertyImages images={selectedListing.images || []} />
              <PropertyText description={selectedListing.description || '-'} />
              <ListingGallery images={selectedListing.images || []} />
            </LeftContainer>
            <RightContainer>
              <TourSchedule listing={selectedListing} />
              <ListingMapContainer>
                <PropertySearchMap
                  alt
                  listings={[selectedListing]}
                  mapWidth="100%"
                  mapHeight="30vh"
                  containerWidth="100%"
                  borderRadius
                  noZoomControl
                  containerHeight={null}
                  center={{ lat: selectedListing.latitude || 44, lng: selectedListing.longitude || -80 }}
                  zoom={15}
                />
              </ListingMapContainer>
              <PropertyDisclaimerMessages
                listPrice={selectedListing.list_price || 0}
                agent={selectedListing.listing_agent}
                office={selectedListing.listing_office}
              />
            </RightContainer>
          </ListingBody>
        </Width>
      </ListingContainer>
    )
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

const mapStateToProps = (state) => ({
  selectedListing: state.selectedListing.selectedListing,
  accessToken: state.user.accessToken,
  cognitoUser: state.user.cognitoUser,
})

export default connect(mapStateToProps)(Listing)
