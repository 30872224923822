interface BookingOptions {
    value: string
    label: string
    num: number
}

export const bookingOptions: BookingOptions[] = [
  {
    label: '9:00am',
    value: '09:00:00',
    num: 900,
  },
  {
    label: '9:30am',
    value: '09:30:00',
    num: 930,
  },
  {
    label: '10:00am',
    value: '10:00:00',
    num: 1000,
  },
  {
    label: '10:30am',
    value: '10:30:00',
    num: 1030,
  },
  {
    label: '11:00am',
    value: '11:00:00',
    num: 1100,
  },
  {
    label: '11:30am',
    value: '11:30:00',
    num: 1130,
  },
  {
    label: '12:00pm',
    value: '12:00:00',
    num: 1200,
  },
  {
    label: '12:30pm',
    value: '12:30:00',
    num: 1230,
  },
  {
    label: '1:00pm',
    value: '13:00:00',
    num: 1300,
  },
  {
    label: '1:30pm',
    value: '13:30:00',
    num: 1330,
  },
  {
    label: '2:00pm',
    value: '14:00:00',
    num: 1400,
  },
  {
    label: '2:30pm',
    value: '14:30:00',
    num: 1430,
  },
  {
    label: '3:00pm',
    value: '15:00:00',
    num: 1500,
  },
  {
    label: '3:30pm',
    value: '15:30:00',
    num: 1530,
  },
  {
    label: '4:00pm',
    value: '16:00:00',
    num: 1600,
  },
  {
    label: '4:30pm',
    value: '16:30:00',
    num: 1630,
  },
]
