import { ScheduleEntryDropdown } from 'src/agentDashboard/components/ScheduleEntryDropdown';
import { AvailabilityOption } from 'src/agentDashboard/constants/availabilityOptions';
import React from 'react';
import { ScheduleEntryDropdownDivider } from 'src/agentDashboard/styles/AgentAvailabilityModal.style';
import { Schedule } from 'src/agentDashboard/constants/agentSchedule';

interface ScheduleEntryDropdownComboProps {
    onChangeHandler: any
    startOptions: AvailabilityOption[]
    endOptions: AvailabilityOption[]
    isLastEntry: boolean
    scheduleEntry: Schedule
    entryIndex: number
    dayOfWeek: string
}

export const ScheduleEntryDropdownCombo = ({
  onChangeHandler, startOptions, endOptions, isLastEntry, scheduleEntry, entryIndex, dayOfWeek,
}: ScheduleEntryDropdownComboProps) => (
  <>
    <ScheduleEntryDropdown
      options={startOptions}
      disabled={!isLastEntry}
      value={scheduleEntry.startTimeLabel}
      dayOfWeek={dayOfWeek}
      entryIndex={entryIndex}
      inputField="START"
      onChangeHandler={onChangeHandler}
    />
    <ScheduleEntryDropdownDivider>-</ScheduleEntryDropdownDivider>
    <ScheduleEntryDropdown
      options={endOptions}
      disabled={!isLastEntry || !scheduleEntry.startTime}
      value={scheduleEntry.endTimeLabel}
      dayOfWeek={dayOfWeek}
      entryIndex={entryIndex}
      inputField="END"
      onChangeHandler={onChangeHandler}

    />
  </>
)
