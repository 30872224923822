import React from 'react'
import Pagination from '@mui/material/Pagination'
import { connect } from 'react-redux'
import Table from 'src/agentDashboard/components/Table'
import {
  AgentDashboardContainer,
  AgentDashboardHeader, AgentDashboardLayout,
  AvailabilityModalBtn,
  PaginationContainer,
} from 'src/agentDashboard/styles/AgentDashboard.style'
import { useAppDispatch } from 'src/app/hooks'
import { getAgentListings } from 'src/agentDashboard/actions/getAgentListings'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { PublishModalPayload, setAgentAvailabilityModalOpen } from 'src/app/reducers/modalSlice';
import { SortOption } from 'src/agentDashboard/types/SortOption.type';
import AgentAvailabilityModal from 'src/agentDashboard/components/modals/AgentAvailabilityModal';
import PublishModal from 'src/agentDashboard/components/modals/PublishModal';
import { getAgentAvailability } from 'src/agentDashboard/actions/getAgentAvailability';

interface AgentDashboardProps {
    accessToken: string
    publishModalOpen: PublishModalPayload
    agentId: string
}

export const AgentDashboard = ({
  accessToken,
  publishModalOpen,
  agentId,
}: AgentDashboardProps) => {
  const [totalPages, setTotalPages] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [sortOption, setSortOption] = React.useState<SortOption>({})
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!publishModalOpen.isOpen) {
      // Race condition where getListings w/o timeout completes before patch finishes executing on server-side
      setTimeout(() => dispatch(getAgentListings(currentPage, setTotalPages, setCurrentPage, accessToken, sortOption)), 1500)
    }
  }, [publishModalOpen.isOpen])

  const updateListingsOnSort = (sort) => dispatch(getAgentListings(currentPage, setTotalPages, setCurrentPage, accessToken, sort))

  const handlePageChange = (e, page: number) => {
    dispatch(getAgentListings(page, setTotalPages, setCurrentPage, accessToken, sortOption))
  }
  return (
    <AgentDashboardContainer>
      <AgentDashboardLayout>
        <AgentDashboardHeader>Agent Dashboard</AgentDashboardHeader>
        <AvailabilityModalBtn
          type="button"
          onClick={() => {
            dispatch(getAgentAvailability(agentId, accessToken))
            dispatch(setAgentAvailabilityModalOpen(true))
          }}
        >
          <CalendarMonthRoundedIcon />
          <h4>Set Availability</h4>
        </AvailabilityModalBtn>
      </AgentDashboardLayout>
      <Table setSortOption={setSortOption} sortOption={sortOption} updateListingsOnSort={updateListingsOnSort} />
      <PaginationContainer>
        <Pagination
          count={totalPages}
          page={currentPage}
          defaultPage={1}
          onChange={handlePageChange}
        />
      </PaginationContainer>
      <AgentAvailabilityModal />
      <PublishModal publishModalOpen={publishModalOpen} />
    </AgentDashboardContainer>
  )
}

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  publishModalOpen: state.modal.isPublishModalOpen,
  agentId: state.user.userId,
})

export default connect(mapStateToProps)(AgentDashboard)
