import React from 'react'
import { Button } from 'src/app/styles/Button.style'

interface IProps {
  children?: React.ReactNode
  to?: string
  color?: 'black' | 'white'
}

export default function Buttonn({ children, to, color = 'black' }:IProps) {
  return (
    <Button className={color} to={to}>{children}</Button>
  )
}
