import styled from 'styled-components'

export const PropertyImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`

export const PropertyHeadlineImage = styled.img`
    border-radius: 20px;
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    cursor: pointer;

    @media (min-width: 1040px) {
        height: 35vw;
        object-fit: cover;
    }
`

export const PropertyImageRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
`

export const ThumbnailContainer = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    position: relative;
`

export const PropertyImageThumbnail = styled.img`
    border-radius: 10px;
    height: 70px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;

    @media (min-width: 600px) {
        height: 90px;
    }

    @media (min-width: 800px) {
        height: 110px;
    }

    @media (min-width: 1040px) {
        height: 90px;
    }

    @media (min-width: 1200px) {
        height: 120px;
    }
`

export const ThumbnailOverlay = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 0;
    border-radius: 10px;
    z-index: 2;

    h4{
        color: white;
        width: 50%;
        text-align: center;
        line-height: 150%;
        font-family: 'DM Sans';
        font-size: 1.6rem;
        font-weight: bold;
        width: 100%;
    }
    

`

export const HeadlineImageBtn = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
`
