import React from 'react';
import {
  DrawerContainer,
  DrawerHeader,
  DrawerSection,
  DrawerText,
} from 'src/agentDashboard/styles/AgentDashboard.style';

interface TableRowDrawerProps {
    description: string
    mlsId: string
}

export const TableRowDrawer = React.memo(({
  description,
  mlsId,
}: TableRowDrawerProps) => (
  <DrawerContainer>
    <DrawerSection width="6.5%" />
    <DrawerSection width="6.5%" />
    <DrawerSection width="52.5%">
      <DrawerHeader>Description</DrawerHeader>
      <DrawerText>
        {description}
      </DrawerText>
    </DrawerSection>
    <DrawerSection width="14.2%" />
    <DrawerSection width="6.5%">
      <DrawerHeader>MLS ID</DrawerHeader>
      <DrawerText>{mlsId}</DrawerText>
    </DrawerSection>
    <DrawerSection width="4.5%" />
  </DrawerContainer>
))
