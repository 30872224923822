import styled from 'styled-components';
import { AppColors } from 'src/app/constants/appColors';

export const AgentDashboardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 40px 40px 0 40px;
    overflow-y: scroll;
`

export const AgentDashboardLayout = styled.div`
    display: flex;
    justifyContent: space-between;
    width: 100%;
    alignItems: center;
`

export const AgentDashboardHeader = styled.h2`
     font-size: 5.5rem; 
     font-family: 'DM Serif Display', serif; 
     margin: 20px 0;
`

export const ListingTable = styled.div`
    border-radius: 25%;
    width: 100%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`

export const ListingTableHeader = styled.div<{width: string}>`
    padding: 20px 0;
    width: ${({ width }) => width};
    text-align: left;
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px;
`

interface ListingTableRowProps {
    isShaded: boolean,
    borderBottom: boolean,
    borderRadius: {
        topRight: number,
        topLeft: number,
        bottomRight: number,
        bottomLeft: number
    }
}

export const ListingTableRow = styled.div<ListingTableRowProps>`
    background-color: ${({ isShaded }) => (isShaded ? AppColors.WHITE_SMOKE : AppColors.WHITE)};
    border-bottom: ${({ borderBottom }) => (borderBottom ? ' 1px solid rgba(0, 0, 0, 0.08)' : 'none')};
    display: flex;
    flex-direction: column;
    justify-content: center
    align-items: center;
    width: 100%;
`

export const InnerRowContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const ListingTableCell = styled.div<{width: string, omitPadding: boolean}>`
    padding: ${({ omitPadding }) => omitPadding ? 0 : '20px 0'};
    width: ${({ width }) => width};
    font-size: 1.4rem;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px;
`

export const StatusHighlight = styled.button<{isPublished: boolean}>`
    background-color: ${({ isPublished }) => (isPublished ? AppColors.FADED_GREEN : AppColors.FADED_RED)};
    padding: 5px 7px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`

export const StatusText = styled.p`
    font-size: 1.5rem;
`

export const ShowDescriptionBtn = styled.button`
    font-weight: bold;
    font-size: 1.4rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
`

export const PlaceholderImage = styled.div`
     background-color: ${AppColors.SILVER};
     width: 80%;
     height: 88%;
`

export const SortButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ListingImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ListingImage = styled.img`
    border-radius: 6px;
`

export const DrawerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

export const DrawerSection = styled(ListingTableCell)`
    flex-direction: column; 
    padding: 0;
`

export const DrawerHeader = styled.h4`
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 1.5rem;
`

export const DrawerText = styled.p`
    line-height: 155%;
    font-size: 1.5rem;
    text-align: left;
`

export const PaginationContainer = styled.div`
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const AvailabilityModalBtn = styled.button`
    display: flex;
    width: 130px;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin: 1px;
    
    &:hover {
        border-bottom: 1px solid silver;
        margin-bottom: 0;
    }
`
