import React from 'react'
import { propertyPrices, PropertyPrice } from 'src/renterDashboard/constants/propertyPrices'
import { useAppDispatch } from 'src/app/hooks';
import {
  InputSideContainer,
  SelectSideContainer,
  SearchBarContainer,
  SearchSelect,
  FilterModalBtn,
} from 'src/renterDashboard/styles/PropertySearchBar.style';
import { PlacesAutocomplete } from 'src/renterDashboard/components/PlacesAutocomplete'
import {
  InitialSearchState,
  setBathrooms,
  setBedrooms,
  setMaxPrice,
  setMinPrice,
} from 'src/renterDashboard/reducers/searchSlice';
import FilterIcon from 'src/renterDashboard/assets/FilterIcon.png'
import { setFilterModalOpen } from 'src/app/reducers/modalSlice';
import FilterModal from 'src/renterDashboard/components/modals/FilterModal';

interface PropertySearchBarProps {
  searchFilters: InitialSearchState
  value: any
  setValue: (any) => void
  status: string
  data: any
  handleSelect: (description, placeId) => void
  getListings: any
  isFilterModalOpen: boolean
}

export const PropertySearchBar = ({
  searchFilters,
  value,
  setValue,
  status,
  data,
  handleSelect,
  getListings,
  isFilterModalOpen,
}: PropertySearchBarProps) => {
  const dispatch = useAppDispatch()

  const { minPrice, maxPrice } = searchFilters

  const minPriceOptions: PropertyPrice[] = React.useMemo(() => {
    if (maxPrice && maxPrice !== 'any') {
      const matchingIndex = (option: PropertyPrice) => option.value === maxPrice
      const maxPriceIndex = propertyPrices.findIndex(matchingIndex)
      const startIndex = maxPriceIndex - 10 < 0 ? 0 : maxPriceIndex - 10
      return propertyPrices.slice(startIndex, maxPriceIndex)
    }
    return propertyPrices.slice(1, 11)
  }, [maxPrice])

  const maxPriceOptions: PropertyPrice[] = React.useMemo(() => {
    if (minPrice && minPrice !== 'any') {
      const matchingIndex = (option: PropertyPrice) => option.value === minPrice
      const minPriceIndex = propertyPrices.findIndex(matchingIndex)
      return propertyPrices.slice(minPriceIndex + 1, minPriceIndex + 10)
    }
    return propertyPrices.slice(1, 11)
  }, [minPrice])

  const handleFilterSelect = (e) => {
    const searchFilterObj = {
      ...searchFilters,
      [e.target.name]: e.target.value,
    }
    getListings(1, searchFilterObj)
    switch (e.target.name) {
      case 'bedrooms':
        return dispatch(setBedrooms(e.target.value))
      case 'bathrooms':
        return dispatch(setBathrooms(e.target.value))
      case 'minPrice':
        return dispatch(setMinPrice(e.target.value))
      case 'maxPrice':
        return dispatch(setMaxPrice(e.target.value))
      default:
        return null
    }
  }

  return (
    <SearchBarContainer>
      <InputSideContainer>
        <PlacesAutocomplete
          value={value}
          setValue={setValue}
          status={status}
          data={data}
          handleSelect={handleSelect}
        />
      </InputSideContainer>
      <SelectSideContainer>
        <SearchSelect
          name="bedrooms"
          onChange={(e: any) => handleFilterSelect(e)}
        >
          <option value="" selected disabled hidden>Bedrooms</option>
          <option value="any">Any</option>
          <option value="1">1+ Bedrooms</option>
          <option value="2">2+ Bedrooms</option>
          <option value="3">3+ Bedrooms</option>
          <option value="4">4+ Bedrooms</option>
          <option value="5">5+ Bedrooms</option>
        </SearchSelect>
        <SearchSelect
          name="bathrooms"
          onChange={(e: any) => handleFilterSelect(e)}
        >
          <option value="" selected disabled hidden>Bathrooms</option>
          <option value="any">Any</option>
          <option value="1">1+ Bathrooms</option>
          <option value="2">2+ Bathrooms</option>
          <option value="3">3+ Bathrooms</option>
          <option value="4">4+ Bathrooms</option>
          <option value="5">5+ Bathrooms</option>
        </SearchSelect>
        <SearchSelect
          name="minPrice"
          onChange={(e: any) => handleFilterSelect(e)}
        >
          <option value="" selected disabled hidden>Min. Price</option>
          <option value="any">Any</option>
          {minPriceOptions.map((price) => (
            <option key={price.value} value={price.value}>
              {price.label}
              +
            </option>
          ))}
        </SearchSelect>
        <SearchSelect
          name="maxPrice"
          onChange={(e: any) => handleFilterSelect(e)}
        >
          <option value="" selected disabled hidden>Max. Price</option>
          {maxPriceOptions.map((price) => (
            <option key={price.value} value={price.value}>
              {price.label}
            </option>
          ))}
          <option value="any">Any</option>
        </SearchSelect>
        <FilterModalBtn onClick={() => dispatch(setFilterModalOpen(true))}>
          <img src={FilterIcon} height="17px" width="17px" alt="Additional Filter Options" />
        </FilterModalBtn>
        <FilterModal isFilterModalOpen={isFilterModalOpen} getListings={getListings} />
      </SelectSideContainer>
    </SearchBarContainer>
  )
}
