import styled from 'styled-components';

export const PropertyTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 95%;
`

export const PropertyHeadline = styled.h1`
    font-weight: 700;
    font-size: 2rem;
    font-family: 'DM Sans', sans-serif;

`

export const PropertyBodyText = styled.p`
    display: inline-block;
    margin-top: 16px;
    font-size: 1.6rem;
    line-height: 150%;
    font-family: 'DM Sans', sans-serif;
`
