import _ from 'lodash';
import React from 'react'
import { RootState } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { Helmet } from 'react-helmet';
import {
  HeroContainer,
  HeroHeader,
  HeroSubHeader,
  HeroButton,
  How,
  HowHeading,
  HowBlocks,
  HowBlock,
  HowBlockHeading,
  HowBlockImg,
  HowBlockP,
  Make,
  Featured,
  FeaturedHeading,
  FeaturedBlocks,
  MoreHomes,
  HowBlockImage,
} from 'src/home/styles/HomeView.style'
import Footer from 'src/app/components/Footer'
import Width from 'src/app/components/Width'
import { setSignUpModalOpen } from 'src/app/reducers/modalSlice'
import { connect, useSelector } from 'react-redux'
import { useAppDispatch } from 'src/app/hooks'
import StartBG from 'src/home/assets/StartBG.jpg'
import Hand from 'src/home/assets/Hand.svg'
import Keys from 'src/home/assets/Keys.svg'
import Boost from 'src/home/assets/Boost.svg'
import Apply from 'src/home/assets/Apply.svg'
import MoveIn from 'src/home/assets/MoveIn.svg'
import BuildEquity from 'src/home/assets/BuildEquity.svg'
import IGPhoto1 from 'src/home/assets/Get_The_Home_Of_Your_Dreams.png'
import IGPhoto2 from 'src/home/assets/How_Does_HomeSlice_Work.png'
import IGPhoto3 from 'src/home/assets/What_Is_HomeSlice.png'
import FeaturedBlockk from 'src/home/components/FeaturedBlock'
import StartBlock from 'src/app/components/Start'
import HomeSlide from 'src/home/components/HomeSlide';

interface HomeProps {
    isSignUpModalOpen: boolean
}

export const Home = ({ isSignUpModalOpen }: HomeProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const cognitoUser = useSelector((state: RootState) => state.user.cognitoUser)

  const toggleSignUpModal = (e:any) => {
    e.preventDefault()
    dispatch(setSignUpModalOpen(!isSignUpModalOpen))
  }

  return (
    <>
      <Helmet>‍
        <title>Grow your wealth while you rent | HomeSlice</title>‍
        <meta
          name="description"
          content="HomeSlice makes it easy to find an affordable home to rent.
          With HomeSlice you build equity when the value of your rental home goes up.
          Now you can participate in the American dream by growing your wealth while renting.
          Are you searching for an affordable home to rent in a neighborhood with good schools?
          HomeSlice will help you find the perfect home for you and your family."
        />
      </Helmet>
      <HeroContainer>
        <Width>
          <HeroHeader>Get The Home <br /> Of Your Dreams</HeroHeader>
          <HeroSubHeader>Rent the home you want in the <br /> neighborhood you want to be in.</HeroSubHeader>
          {_.isEmpty(cognitoUser) && <HeroButton onClick={(e: any) => toggleSignUpModal(e)}>Get Started</HeroButton>}
          {!_.isEmpty(cognitoUser) && <HeroButton onClick={() => navigate('/home/renter')}>Browse Homes</HeroButton>}
        </Width>
      </HeroContainer>
      <Make>
        <Width>
          <Swiper className="home-swiper" spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]}>
            <SwiperSlide>
              <HomeSlide color="blue" image={Hand} title="We Make It Easy To Get The Home Of Your Dreams">
                Live in the home of your dreams while building wealth like an owner.
              </HomeSlide>
            </SwiperSlide>
            <SwiperSlide>
              <HomeSlide reverse color="purple" image={Keys} title="We Provide Flexibility">
                Home prices are too high and it feels impossible to find the right rental.
                We provide more choices and the flexibility of renting with the added benefit of equity
                so you get to build your personal wealth just like a homeowner.
              </HomeSlide>
            </SwiperSlide>
            <SwiperSlide>
              <HomeSlide color="orange" image={Boost} title="What If You Could Rent And Earn Equity?">
                We&apos;ll partner with you to purchase the home of your dreams and you&apos;ll start
                building equity from day one.
              </HomeSlide>
            </SwiperSlide>
          </Swiper>
        </Width>
      </Make>
      <How>
        <Width>
          <HowHeading>Here’s How it works</HowHeading>
          <HowBlocks>
            <HowBlock>
              <HowBlockImage><HowBlockImg src={Apply} /></HowBlockImage>
              <HowBlockHeading width={440}>Search</HowBlockHeading>
              <HowBlockP>Finding a desirable rental home feels impossible in today&apos;s market.
                We give you access to search thousands of homes to find the one you want in
                the neighborhood you want to live in.
              </HowBlockP>
            </HowBlock>
            <HowBlock>
              <HowBlockImage><HowBlockImg src={MoveIn} /></HowBlockImage>
              <HowBlockHeading>Apply</HowBlockHeading>
              <HowBlockP>After you find the house you want, fill out an application and we do the rest.
                Once we close on the purchase of your new home, you&apos;ll pay a security deposit and your
                first month&apos;s rent then it&apos;s time to move in.
              </HowBlockP>
            </HowBlock>
            <HowBlock>
              <HowBlockImage><HowBlockImg src={BuildEquity} /></HowBlockImage>
              <HowBlockHeading>Build Wealth Like A Homeowner</HowBlockHeading>
              <HowBlockP>As the value of your home grows, you get 50% of the equity while you rent.</HowBlockP>
            </HowBlock>
          </HowBlocks>
        </Width>
      </How>
      <Featured>
        <Width>
          <FeaturedHeading>
            Follow Us On Instagram&nbsp;
            <a
              href="https://www.instagram.com/homeslicerentals/"
            >
              @homeslicerentals
            </a>
          </FeaturedHeading>
          <FeaturedBlocks>
            <FeaturedBlockk
              title="6612 Winnebago St"
              address="St. Louis, MO 63109"
              beds="3"
              baths="2"
              sqft="1,540"
              price="$2,500"
              url="https://www.instagram.com/reel/CqYH4gvPN8k/?utm_source=ig_web_copy_link"
              image={IGPhoto1}
            />

            <FeaturedBlockk
              title="6612 Winnebago St"
              address="St. Louis, MO 63109"
              beds="3"
              baths="2"
              sqft="1,540"
              price="$2,500"
              url="https://www.instagram.com/reel/CqYHMHashYI/?utm_source=ig_web_copy_link"
              image={IGPhoto2}
            />

            <FeaturedBlockk
              title="6612 Winnebago St"
              address="St. Louis, MO 63109"
              beds="3"
              baths="2"
              sqft="1,540"
              price="$2,500"
              url="https://www.instagram.com/reel/CqYGVk1pZXF/?utm_source=ig_web_copy_link"
              image={IGPhoto3}
            />

            {/* <MoreHomes to="/home/renter">View More<br /> Homes</MoreHomes> */}
          </FeaturedBlocks>
        </Width>
      </Featured>
      <StartBlock color="purple" bg={StartBG} />
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  isSignUpModalOpen: state.modal.isSignUpModalOpen,
})

export default connect(mapStateToProps)(Home)
