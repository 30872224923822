import { Route } from 'react-router-dom'
import React from 'react'
import { ProtectedRoute } from 'src/app/components/ProtectedRoute'
import { RouteType } from 'src/app/types/routes.type'

export const renderRoute = (accessToken: string, route: RouteType) => (
  route.protected
    ? (
      <Route
        key={route.path}
        path={route.path}
        element={(
          <ProtectedRoute redirectPath="/" accessToken={accessToken}>
            {route.component}
          </ProtectedRoute>
)}
      />
    )
    : <Route key={route.path} path={route.path} element={route.component} />)
