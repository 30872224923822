import { TextField } from '@mui/material';
import React from 'react';
import { RowValue } from 'src/agentDashboard/styles/PublishModal.style';
import { dollarFormatter } from 'src/app/utils/dollarFormatter';

interface RentEditProps {
  editRent: boolean
  info: any
  updatedRent: number
  setUpdatedRent: any
  warning: boolean
}

export const RentEdit = ({
  editRent,
  info,
  updatedRent,
  setUpdatedRent,
  warning,
}: RentEditProps) => editRent
  ? (
    <TextField
      sx={{ '& .MuiOutlinedInput-input': { py: 1, width: 35 } }}
      id="outlined-basic"
      variant="outlined"
      error={warning}
      value={updatedRent}
      onChange={(e) => setUpdatedRent(e.target.value)}
    />
  )
  : <RowValue color={warning && info.color}>{dollarFormatter(updatedRent)}</RowValue>
