import React from 'react'
import {
  WealthBlockC,
  WealthBlockDesc,
  WealthBlockHeading,
  WealthBlockImg,
  WealthBlockSource,
} from '../styles/WhoWeAreView.style'

interface IProps {
  image: string
  heading: string
  children: React.ReactNode
  source: string
  color?: 'blue' | 'orange'
}

export default function WealthBlock({
  image,
  heading,
  children,
  source,
  color = 'blue',
}:IProps) {
  return (
    <WealthBlockC className={color}>
      <WealthBlockImg src={image} />
      <WealthBlockHeading>{heading}</WealthBlockHeading>
      <WealthBlockDesc>{children}</WealthBlockDesc>
      <WealthBlockSource>{source}</WealthBlockSource>
    </WealthBlockC>
  )
}
