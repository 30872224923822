import _ from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Width from 'src/app/components/Width'
import { useAppDispatch } from 'src/app/hooks'
import { setSignUpModalOpen } from 'src/app/reducers/modalSlice'
import { RootState } from 'src/app/store';
import { Button } from '../styles/Button.style'
import {
  PageHeaderFlex,
  PageHeaderFlexLeft,
  PageHeaderHeading,
  PageHeaderImg,
  PageHeaderP,
  PageHeaderr,
} from '../styles/PageHeader.style'

interface IProps {
  image: string
  heading: string
  children: React.ReactNode
  wide?: boolean
}

interface HomeProps {
  isSignUpModalOpen: boolean
}

export default function PageHeader({
  image,
  heading,
  children,
  wide,
}:IProps, { isSignUpModalOpen }: HomeProps) {
  const dispatch = useAppDispatch()
  const cognitoUser = useSelector((state: RootState) => state.user.cognitoUser)

  const toggleSignUpModal = (e:any) => {
    e.preventDefault()
    dispatch(setSignUpModalOpen(!isSignUpModalOpen))
  }

  const navigate = useNavigate();

  return (
    <PageHeaderr>
      <Width>
        <PageHeaderFlex>
          <PageHeaderFlexLeft wide>
            <PageHeaderHeading>{heading}</PageHeaderHeading>
            <PageHeaderP>{children}</PageHeaderP>
            {_.isEmpty(cognitoUser) && <Button onClick={(e: any) => toggleSignUpModal(e)}>Get Started</Button>}
            {!_.isEmpty(cognitoUser) && <Button onClick={() => navigate('/home/renter')}>Browse Homes</Button>}
          </PageHeaderFlexLeft>
          <PageHeaderImg src={image} />
        </PageHeaderFlex>
      </Width>
    </PageHeaderr>
  )
}
