import React from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useAppDispatch } from 'src/app/hooks'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import {
  setScheduleModalStepThreeOpen,
  setScheduleModalStepFourOpen,
} from 'src/app/reducers/modalSlice';
import {
  CalendarApplicationContainer,
  CalendarContainer,
  ModalSubheaderContainer,
} from 'src/listing/styles/CalendarApplicationModal.style'
import { getListingAvailability } from 'src/listing/actions/getListingAvailability';

interface CalendarApplicationModalProps {
  isScheduleModalStepThreeOpen: boolean
  setBookingDetails: any
  accessToken: string
  setAvailabilitySchedule: any
}

export const CalendarApplicationModal = ({
  isScheduleModalStepThreeOpen,
  setBookingDetails,
  accessToken,
  setAvailabilitySchedule,
}: CalendarApplicationModalProps) => {
  const [date, setDate] = React.useState<any>(null);
  const dispatch = useAppDispatch()

  const closeStepThreeModal = () => {
    setDate(null)
    dispatch(setScheduleModalStepThreeOpen(false))
  }
  const openStepFourModal = () => dispatch(setScheduleModalStepFourOpen(true))

  const onClickHandler = () => {
    const selectedDate = moment(date).format('MM/DD/YYYY')
    setBookingDetails({ bookingDate: selectedDate })
    dispatch(getListingAvailability(selectedDate, accessToken, setAvailabilitySchedule))
    setDate(null)
    closeStepThreeModal()
    openStepFourModal()
  }

  return (
    <Modal
      onClose={closeStepThreeModal}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isScheduleModalStepThreeOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={closeStepThreeModal}>
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          <ModalHeader>Schedule a Tour</ModalHeader>
          <CalendarApplicationContainer>
            <ModalSubheaderContainer>
              <ModalSubHeader>Please select a date that you’re available for a tour</ModalSubHeader>
            </ModalSubheaderContainer>
            <CalendarContainer>
              <Calendar minDate={new Date()} onChange={setDate} value={date} />
            </CalendarContainer>
          </CalendarApplicationContainer>
          <ModalPrimaryButton type="button" disabled={!date} onClick={onClickHandler}>Continue</ModalPrimaryButton>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
