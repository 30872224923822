import React from 'react'
import ContentLoader from 'react-content-loader'
import { RenterDashboardLoader } from '../styles/RenterDashboard.style'

export const CardLoader = () => (
  <RenterDashboardLoader>
    <ContentLoader
      speed={2}
      width={180}
      height={200}
      viewBox="0 0 180 200"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="2" y="15" rx="5" ry="5" width="220" height="103" />
      <rect x="2" y="128" rx="5" ry="5" width="220" height="10" />
      <rect x="2" y="158" rx="5" ry="5" width="220" height="10" />
      <rect x="60" y="187" rx="5" ry="5" width="58" height="10" />
      <rect x="1" y="187" rx="5" ry="5" width="58" height="10" />
      <rect x="119" y="187" rx="5" ry="5" width="60" height="10" />
    </ContentLoader>
  </RenterDashboardLoader>
)
