import { BASE_URL } from 'src/app/constants/baseUrl'
import { setListings, setTotalListingCount } from 'src/listing/reducers/listingsSlice';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { SortOption } from 'src/agentDashboard/types/SortOption.type';
import _ from 'lodash'
import axios from 'axios';

export const getAgentListings = (
  page: number,
  setTotalPages: (num: number) => void,
  setCurrentPage: (num: number) => void,
  accessToken: string,
  sortOption: SortOption,
) => (dispatch: any) => {
  let url = BASE_URL
  if (page) {
    url = `${BASE_URL}/protected/listing?page=${page}&page_size=10`
  }
  if (!_.isEmpty(sortOption)) url += `&sort=${sortOption.name}&sort_order=${sortOption.asc ? 'asc' : 'desc'}`

  dispatch(setIsLoading(true))

  axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res?.data?.listings) {
        const { listings, count } = res.data
        const formattedListings = listings.map((listing) => ({ ...listing }))
        dispatch(setListings(formattedListings))
        dispatch(setTotalListingCount(count))
        setTotalPages(Math.ceil(count / 10))
        setCurrentPage(page)
      }
    })
  // eslint-disable-next-line no-console
    .catch((err) => {
      console.error(err.message)
    })
    .finally(() => dispatch(setIsLoading(false)))
}
