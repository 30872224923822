import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { agentSchedule, AgentSchedule } from 'src/agentDashboard/constants/agentSchedule';

const initialState = {
  agentSchedule,
}

export const agentScheduleSlice = createSlice({
  name: 'agentSchedule',
  initialState,
  reducers: {
    setAgentSchedule: (state, action: PayloadAction<AgentSchedule>) => {
      state.agentSchedule = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setAgentSchedule,
  reset,
} = agentScheduleSlice.actions

export default agentScheduleSlice.reducer
