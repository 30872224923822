export const agentTableHeaders = [
  {
    label: '',
    width: '6.5%',
    sortable: false,
  },
  {
    label: 'Status',
    width: '8%',
    sortable: false,
    name: 'status',
  },
  {
    label: 'Street Address',
    width: '12%',
    sortable: false,
    name: 'streetAddress',
  },
  {
    label: 'City',
    width: '6.5%',
    sortable: false,
    name: 'city',
  },
  {
    label: 'State',
    width: '5%',
    sortable: false,
    name: 'state',
  },
  {
    label: 'Zip',
    width: '5%',
    sortable: false,
    name: 'zip',
  },
  {
    label: 'List Price',
    width: '6.5%',
    sortable: true,
    name: 'list_price',
  },
  {
    label: 'Bed',
    width: '4%',
    sortable: true,
    name: 'beds',
  },
  {
    label: 'Bath',
    width: '4%',
    sortable: true,
    name: 'bath',
  },
  {
    label: 'Sq. Ft.',
    width: '5%',
    sortable: false,
  },
  {
    label: 'Listed',
    width: '6.5%',
    sortable: false,
  },
  {
    label: 'Updated',
    width: '6.5%',
    sortable: false,
  },
  {
    label: '',
    width: '5.5%',
    sortable: false,
  },
]
