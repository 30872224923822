import React from 'react';
import { ModalOptionLabel } from 'src/app/styles';
import { FilterContainer, LinkedSelectContainer } from 'src/renterDashboard/styles/FilterModal.style';
import { AdditionalSelectFilter } from 'src/renterDashboard/components/AdditionalSelectFilter';
import { AdditionalFilters, MinMaxObject } from 'src/renterDashboard/types/AdditionalFilters.type';

interface AdditionalFilterRangeProps {
  name: string
  label: string
  filter: MinMaxObject
  setFilters: any
  filters: AdditionalFilters
  options: any[]
}

export const AdditionalFilterRange = ({
  label, filter, setFilters, filters, name, options,
}: AdditionalFilterRangeProps) => {
  const minOptions = React.useMemo(() => {
    if (filter.max && filter.max !== 'any') {
      const matchingIndex = (option) => option.value === filter.max
      const maxIndex = options.findIndex(matchingIndex)
      const startIndex = maxIndex - 10 < 0 ? 0 : maxIndex - 10
      return options.slice(startIndex, maxIndex)
    }
    return options.slice(0, 10)
  }, [filter.max])

  const maxOptions = React.useMemo(() => {
    if (filter.min && filter.min !== 'any') {
      const matchingIndex = (option) => option.value === filter.min
      const minIndex = options.findIndex(matchingIndex)
      return options.slice(minIndex + 1, minIndex + 10)
    }
    return options.slice(1, 11)
  }, [filter.min])

  const onChangeHandler = (e, key) => {
    setFilters({
      ...filters,
      [name]: {
        ...filter,
        [key]: e.target.value,
      },
    })
  }

  return (
    <FilterContainer>
      <ModalOptionLabel>{label}</ModalOptionLabel>
      <LinkedSelectContainer>
        <AdditionalSelectFilter
          filterValue={filter.min || ''}
          onChangeHandler={(e) => onChangeHandler(e, 'min')}
          options={minOptions}
        />
        <span>-</span>
        <AdditionalSelectFilter
          filterValue={filter.max || ''}
          onChangeHandler={(e) => onChangeHandler(e, 'max')}
          options={maxOptions}
        />
      </LinkedSelectContainer>
    </FilterContainer>
  )
}
