import styled from 'styled-components';
import { AppColors } from 'src/app/constants/appColors';

export const CalendarApplicationContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CalendarContainer = styled.div`
  margin: 10px auto 10px auto;
  
  .react-calendar{
    border: none;
    background-color: transparent;
  }
  
  .react-calendar__navigation__arrow:disabled {
    background-color: transparent;
    abbr {
      color: #bcbcba
    }
  }
  
  .react-calendar__tile {
    border-radius: 50%;
  }
  
  .react-calendar__tile:disabled  {
    background-color: transparent;
    abbr {
      color: #bcbcba
    }
  }
  
  .react-calendar__tile--active {
    background-color: #006edc;
    border-radius: 50%;
    abbr {
      color: white;
    }
  }
  
  .react-calendar__tile--active {
    background-color: #2573BA;
    abbr {
      color: white;
    }
  }
  
  .react-calendar__tile--now {
    background-color: ${AppColors.WHITE_SMOKE};
    abbr {
      color: black;
    }
  }
`

export const ModalSubheaderContainer = styled.div`
     width: 60%;
     margin: 0 auto;
`
