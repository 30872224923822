import React from 'react'
import Water from 'src/comingSoon/assets/Water.svg'
import Button from 'src/app/components/Button'
import ErrorPage from 'src/app/components/ErrorPage'

export default function ComingSoon() {
  return (
    <ErrorPage image={Water}>
      <p>Sorry, but we can’t find what you’re looking for.</p>
      <Button to="/">Go Back</Button>
    </ErrorPage>
  )
}
