import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  isLoadingCount: 0,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) state.isLoadingCount += 1
      if (!action.payload) state.isLoadingCount -= 1
    },
    reset: () => initialState,
  },
})

export const {
  setIsLoading,
  reset,
} = loadingSlice.actions

export default loadingSlice.reducer
