import { AppColors } from 'src/app/constants/appColors';
import styled from 'styled-components'

export const ModalOuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 96%;
`

export const ModalInnerContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`

export const ModalHeader = styled.h1`
    text-align: center;
    font-size: 1.8rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
`

export const ModalSubHeader = styled.h2`
    text-align: center;
    font-size: 2.4rem;
    font-family: 'DM Sans', sans-serif;
    margin: 40px 0 32px;
    font-weight: bold;
    line-height: 150%;
`

export const ModalTagline = styled.h3`
    text-align: center;
    font-size: 1.8rem;
    font-family: DM Sans, sans-serif;
    margin-top: -20px;
    margin-bottom: 32px;
`

export const ModalForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
`

export const ModalPrimaryButton = styled.button<{width?: number}>`
    font-family: 'DM Serif Display', serif;
    border: none;
    cursor: pointer;
    background-color: ${AppColors.BLACK};
    color: ${AppColors.WHITE};
    width: ${({ width }) => width ? `${width}%` : '50%'};
    border-radius: 50px;
    margin: 20px 0;
    line-height: 56px;
    font-size: 1.8rem;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.8;
    }
    
    :disabled {
        background-color: rgba(0,0,0,0.2);
        cursor: not-allowed;
    }
`

export const ModalAltText = styled.h5`
    font-family: 'DM Sans', sans-serif;
    font-size: 1.6rem;
`

export const ModalAltButton = styled.button`
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-size: 1.6rem;
    padding: 0;
    display: inline-block;
    margin-left: 5px;
    color: ${AppColors.BLUE_LINK};
    
    &:hover {
        text-decoration: underline;
    }
`

export const ModalCloseButton = styled.button`
    border: none;
    color: ${AppColors.SILVER};
    background-color: inherit;
    cursor: pointer;
    font-size: 2rem;
    width: 20px;
    transition: 0.2s color;
    position: absolute;
    top: 30px;
    left: 30px;

    &:hover {
        color: ${AppColors.BLACK};
    }
`

export const ModalInput = styled.input`
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.6rem;
    line-height: 52px;
    padding: 0 24px;

    & + & {
        margin-top: 20px;
    }
`

export const ModalAltContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`

export const ModalOptionLabel = styled.h5`
    font-size: 1.7rem;
    margin-left: 8px;
    font-family: 'DM Sans';
    font-weight: 700;
    line-height: 125%;
`
