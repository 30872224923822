import React from 'react'
import ReactModal from 'react-modal'
import { AppColors } from 'src/app/constants/appColors'

interface ModalProps {
  height: string
  width: string
  maxWidth?: string
  children: React.ReactElement
  isOpen: boolean
  onClose: Function
  customOverlayStyles?: any
  customContentStyles?: any
}

export const Modal = ({
  height,
  width,
  children,
  isOpen,
  onClose,
  customOverlayStyles,
  customContentStyles,
  maxWidth,
}: ModalProps) => (
  <ReactModal
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    onRequestClose={onClose}
    ariaHideApp={false}
    style={{
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 10,
        display: 'flex',
        ...customOverlayStyles,
      },
      content: {
        width,
        height,
        display: 'flex',
        padding: '32px',
        maxWidth,
        maxHeight: '90%',
        justifyContent: 'center',
        backgroundColor: AppColors.WHITE_SMOKE,
        border: 'none',
        margin: 'auto',
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        position: 'relative',
        ...customContentStyles,
      },
    }}
    isOpen={isOpen}
  >
    {children}
  </ReactModal>
)
