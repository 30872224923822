import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { BASE_URL } from 'src/app/constants/baseUrl';
import axios from 'axios';

export const postBooking = (
  bookingDate: string | undefined,
  timeSelected: string,
  accessToken: string,
  userId: string,
  cleanupOnSuccess: any,
  selectedListingId: string,
) => async (dispatch: any) => {
  const body = {
    renter_id: userId,
    booking_date_time: `${bookingDate} ${timeSelected}`,
  }
  const url = `${BASE_URL}/booking/${selectedListingId}`
  dispatch(setIsLoading(true))
  axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      cleanupOnSuccess()
    })
    .catch((err) => console.error(err.message))
    .finally(() => dispatch(setIsLoading(false)))
}
