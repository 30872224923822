export interface PropertySquareFeet {
    value: string
    label: string
}

export const propertySquareFeet: PropertySquareFeet[] = [
  {
    value: '100',
    label: '100 sqft',
  },
  {
    value: '200',
    label: '200 sqft',
  },
  {
    value: '300',
    label: '300 sqft',
  },
  {
    value: '400',
    label: '400 sqft',
  },
  {
    value: '500',
    label: '500 sqft',
  },
  {
    value: '600',
    label: '600 sqft',
  },
  {
    value: '700',
    label: '700 sqft',
  },
  {
    value: '800',
    label: '800 sqft',
  },
  {
    value: '900',
    label: '900 sqft',
  },
  {
    value: '1000',
    label: '1,000 sqft',
  },
  {
    value: '1500',
    label: '1,500 sqft',
  },
  {
    value: '2000',
    label: '2,000 sqft',
  },
  {
    value: '2500',
    label: '2,500 sqft',
  },
  {
    value: '3000',
    label: '3,000 sqft',
  },
]
