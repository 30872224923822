import React, { ChangeEvent } from 'react';
import { ModalInput } from 'src/app/styles/modalStyles';

interface InputFieldProps {
  placeholder: string
  value: string
  name: string
  type: string
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void
}

export const InputField = ({
  placeholder,
  value,
  name,
  type,
  onChangeHandler,
}: InputFieldProps) => (
  <ModalInput
    placeholder={placeholder}
    type={type}
    value={value}
    name={name}
    onChange={(e: any) => onChangeHandler(e)}
  />
)
