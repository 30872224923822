import React from 'react'
import { Helmet } from 'react-helmet';
import StartBg from 'src/howItWorks/assets/StartBg.jpg'
import StartBlock from 'src/app/components/Start'
import BecomeBlock from 'src/howItWorks/components/BecomeBlock'
import PageHeader from 'src/app/components/PageHeader'
import Width from 'src/app/components/Width'
import HowItWorksHero from 'src/howItWorks/assets/HowItWorksHero.webp'
import Apply from 'src/howItWorks/assets/Apply.svg'
import Choose from 'src/howItWorks/assets/Choose.svg'
import Rent from 'src/howItWorks/assets/Rent.svg'
import Buy from 'src/howItWorks/assets/Buy.svg'
import Footer from 'src/app/components/Footer'
import House from 'src/howItWorks/assets/House.svg'
import Key from 'src/whoWeAre/assets/Key.svg'
import { ReactComponent as LeftPath } from 'src/howItWorks/assets/LeftPath.svg'
import { ReactComponent as LeftPath2 } from 'src/howItWorks/assets/LeftPath2.svg'
import { ReactComponent as RightPath } from 'src/howItWorks/assets/RightPath.svg'
import { ReactComponent as DesktopPath } from 'src/howItWorks/assets/DesktopPath.svg'
import {
  Wealth,
  // WealthBlockC,
  WealthBlockD,
  // WealthBlockDesc,
  WealthBlockDescPadded,
  // WealthBlockHeading,
  WealthBlockHeadingPadded,
  WealthFlex,
  WealthHeading,
  WealthSubHeading,
} from 'src/whoWeAre/styles/WhoWeAreView.style'
import WealthBlock from 'src/whoWeAre/components/WealthBlock'
// import { ContactForm, contactInitialState } from 'src/home/types/contact'
import {
  Become,
  BecomeBlocks,
  BuildEquity,
  BuildEquityHeading,
  ColorBlock,
  ColorBlocks,
  ColorBlocksFlex,
  ColorBlockText,
  ColorBlockTitle,
  Questions,
  QuestionsField,
  QuestionsFlex,
  QuestionsForm,
  QuestionsHeading,
  QuestionsSubheading,
  QuestionsSubmit,
  QuestionsTextarea,
  QuestionsWidth,
} from './styles/HowItWorksView.style'

export default function WhoWAre() {
  // const [contact, setContact] = React.useState<ContactForm>(contactInitialState)
  // const [error, setError] = React.useState('')

  // const resetContact = () => {
  //   setContact(contactInitialState)
  //   setError('')
  // }

  // const contactSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   // await postContact(contact, navigate, setError, dispatch, resetContact)
  // }

  return (
    <>
      <Helmet>‍
          <title>You Can Find An Affordable Home To Rent | HomeSlice</title>‍
          <meta
            name="description"
            content="HomeSlice makes it easty to find the home you want in the neighborhood you want to live in with a yard
            and a good school district. With HomeSlice it's as easy as one, two, three. HomeSlice helps you find an
            affordable home rental. HomeSlice partners with you to buy the home. Start renting and grow your wealth with
            HomeSlice."
          />
      </Helmet>
      <PageHeader heading="The Best Way To Get The Home You Want" image={HowItWorksHero}>
        HomeSlice makes it easy to get the home you want in the neighborhood you want to live in.
      </PageHeader>

      <Wealth>
        <Width>
          <WealthHeading>HomeSlice Helps You Rent While You Build Equity Like An Owner</WealthHeading>
          <WealthFlex>
            <WealthBlock image={Key} color="orange" source="" heading="You Can&apos;t Buy A Home And You can&apos;t Find One To Rent">
              We help you get the home you want and we give you options for your future—all while
              sharing a slice of the pie so you can make the most of your money and grow your wealth.
            </WealthBlock>
            <WealthBlock image={House} source="" heading="HomeSlice Has The Solution.">
              What if you you could choose the home you want and have HomeSlice buy it for you? You rent it from us.
              You don&apos;t pay a premium or any extra fees. You&apos;ll pay fair market rent while participating in
              any upside if the home increases in value while you live there. That&apos;s HomeSlice.
            </WealthBlock>
          </WealthFlex>
          <WealthFlex>
            <WealthSubHeading>
              HomeSlice gives you access to the kind of home and neighborhood you want to live in.<br />
              No catch. No premium. No hidden fees.
            </WealthSubHeading>
          </WealthFlex>
          <WealthFlex>
            <WealthBlockD>
              <WealthBlockHeadingPadded>It Sounds To Good To Be True.<br />What&apos;s The Catch?</WealthBlockHeadingPadded>
              <WealthBlockDescPadded>
                You want to be sure you&apos;re not being taken advantage of by a process you don&apos;t understand.
                HomeSlice partners with families like yours to buy homes. We both benefit when the home we buy together
                goes up in value.
              </WealthBlockDescPadded>
              <WealthBlockHeadingPadded>We Buy. You Rent.<br />We Both Get A Slice of The Pie.</WealthBlockHeadingPadded>
            </WealthBlockD>
          </WealthFlex>
        </Width>
      </Wealth>

      <BuildEquity>
        <BuildEquityHeading>A Simple Path To Get The Home Of Your Dreams</BuildEquityHeading>
      </BuildEquity>

      <Become>
        <Width>
          <BecomeBlocks>
            <DesktopPath />
            <BecomeBlock className="apply" maxWidth={320} title="Apply" image={Apply}>
              Getting prequalified is fast, free, and doesn’t affect your credit score.
              <LeftPath />
            </BecomeBlock>
            <BecomeBlock className="choose" maxWidth={480} title="Choose the home you love" image={Choose}>
              Pick from any qualifying for-sale homes that you like. You can tour the homes with us, and we’ll help you find the right fit.
              <RightPath />
            </BecomeBlock>
            <BecomeBlock className="rent" maxWidth={460} title="Move in and start renting" image={Rent}>
              After you’ve decided on a home (and we’ve bought it), it’s time to move into your new rental.
              <LeftPath2 />
            </BecomeBlock>
            <BecomeBlock className="move" maxWidth={576} title="Buy your home, continue renting, or cash out" image={Buy}>If the home price goes up from when we bought it, you get half of the upside. We help you become eligible for a mortgage within 3 years. However, if that does not work for you, you can roll your equity into a new lease or cash out the upside (minus a relisting fee).</BecomeBlock>
          </BecomeBlocks>
        </Width>
      </Become>

      <ColorBlocks>
        <Width>
          <ColorBlocksFlex>
            <ColorBlock className="blue">
              <ColorBlockTitle>How does pricing work?</ColorBlockTitle>
              <ColorBlockText>
                <p>You only pay fair market rent for your rental. HomeSlice calculates fair market rent based on comparable houses in the neighborhood. There are no additional fees or hidden costs. Home purchase includes a standard 3% fee paid to our real estate brokerage. Cashing out includes a relisting fee equivalent to 10% of the savings.</p>
              </ColorBlockText>
            </ColorBlock>
            <ColorBlock className="purple">
              <ColorBlockTitle>Is HomeSlice right for you?</ColorBlockTitle>
              <ColorBlockText>
                <p>HomeSlice is right for you if:</p>
                <ol>
                  <li>You are saving for a down payment.</li>
                  <li>You qualify for an FHA mortgage.</li>
                  <li>You want to try owning without the risk of buying.</li>
                </ol>
              </ColorBlockText>
            </ColorBlock>
          </ColorBlocksFlex>
        </Width>
      </ColorBlocks>
      <Questions>
        <Width>
          <QuestionsWidth>
            <QuestionsHeading>More Questions?</QuestionsHeading>
            <QuestionsSubheading>
            <a
              href="mailto:info@homeslice.rentals"
              className="text-gray-900 underline"
            >
              Drop us a line
            </a> and we&apos;ll get you an answer to any questions you still have!
            </QuestionsSubheading>
            {/* <QuestionsForm>
              <QuestionsFlex>
                <QuestionsField type="text" placeholder="Name" />
                <QuestionsField type="email" placeholder="Email" />
              </QuestionsFlex>
              <QuestionsTextarea placeholder="Message" />
              <QuestionsSubmit type="submit">Send</QuestionsSubmit>
            </QuestionsForm> */}
          </QuestionsWidth>
        </Width>
      </Questions>
      <StartBlock color="orange" alt bg={StartBg} />
      <Footer />
    </>
  )
}
