import styled from 'styled-components';

export const BookingContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TimesColumn = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const TimesContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`

export const TimesRadioField = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 4px 0;

    label {
      font-size: 1.6rem;

      @media (min-width: 1040px) {
        font-size: 1.8rem;
      }
    }
`
