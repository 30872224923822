import React from 'react'
import { useAppDispatch } from 'src/app/hooks'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import {
  setScheduleModalStepTwoOpen,
  setScheduleModalStepThreeOpen,
} from 'src/app/reducers/modalSlice';
import { PrequalApplication } from 'src/listing/components/modals/PrequalApplicationForm/PrequalApplication';
import { ApplicationAnswerType } from 'src/listing/types/applicationAnswers.type';

interface PrequalApplicationModalProps {
    isScheduleModalStepTwoOpen: boolean
}

export const PrequalApplicationModal = ({
  isScheduleModalStepTwoOpen,
}: PrequalApplicationModalProps) => {
  const [selectedAnswers, setSelectedAnswers] = React.useState<ApplicationAnswerType>({
    questionOne: '',
    questionTwo: {},
    questionThree: {},
  })
  const dispatch = useAppDispatch()

  const closeStepTwoModal = () => dispatch(setScheduleModalStepTwoOpen(false))
  const openStepThreeModal = () => dispatch(setScheduleModalStepThreeOpen(true))

  const onClickHandler = () => {
    closeStepTwoModal()
    openStepThreeModal()
  }

  // When uncommented this code will disable the modal's submit button until every field is filled out
  // const formComplete = selectedAnswers.questionOne
  //     && ((selectedAnswers.questionTwo.hasSecondaryApplicant
  //         && selectedAnswers.questionTwo.secondaryApplicant?.name
  //         && selectedAnswers.questionTwo.secondaryApplicant?.email)
  //         || selectedAnswers.questionTwo.hasSecondaryApplicant === false)
  //     && (selectedAnswers.questionThree.occupation
  //         && selectedAnswers.questionThree.streetAddress
  //         && selectedAnswers.questionThree.zipCode
  //         && selectedAnswers.questionThree.phoneNumber
  //         && selectedAnswers.questionThree.socialTaxID)

  return (
    <Modal
      onClose={closeStepTwoModal}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isScheduleModalStepTwoOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={closeStepTwoModal}>
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          <ModalHeader>Prequalification Application</ModalHeader>
          <PrequalApplication selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} />
          <ModalPrimaryButton
            width="100"
            // disabled={!formComplete}
            type="button"
            onClick={onClickHandler}
          >
            Continue
          </ModalPrimaryButton>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
