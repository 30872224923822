import { BASE_URL } from 'src/app/constants/baseUrl';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import axios from 'axios';
import { getNumberFromTimeString } from 'src/agentDashboard/utils/getNumberFromTimeString';
import moment from 'moment';
import { bookingOptions } from 'src/listing/constants/bookingOptions';

export const getListingAvailability = (
  selectedDate: string,
  accessToken: string,
  setAvailabilitySchedule: any,
) => async (dispatch: any) => {
  const url = `${BASE_URL}/availability`

  dispatch(setIsLoading(true))
  axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      const formattedAvailability = res.data.map((availability) => ({
        dayOfWeek: availability.day_of_week,
        startTime: availability.start_time,
        startTimeNum: getNumberFromTimeString(availability.start_time),
        endTime: availability.end_time,
        endTimeNum: getNumberFromTimeString(availability.end_time),
      }))
      const bookedDayOfWeek = moment(selectedDate).format('ddd')
      const availabilitiesInDay = formattedAvailability.filter((entry) => entry.dayOfWeek === bookedDayOfWeek)
      const validOptions = bookingOptions.map((option) => {
        const isValid = availabilitiesInDay.some((entry) => entry.startTimeNum <= option.num && option.num <= entry.endTimeNum - 100)
        return {
          ...option,
          isValid,
        }
      })
      setAvailabilitySchedule(validOptions)
    })
    .catch((err) => console.error(err.message))
    .finally(() => dispatch(setIsLoading(false)))
}
