import React from 'react'
import {
  PropertyTextContainer,
  PropertyHeadline,
  PropertyBodyText,
} from 'src/listing/styles/PropertyText.style'

interface PropertyTextProps {
  description: string
}

export const PropertyText = ({ description }: PropertyTextProps) => (
  <PropertyTextContainer>
    <PropertyHeadline>Overview</PropertyHeadline>
    <PropertyBodyText>
      {description}
    </PropertyBodyText>
  </PropertyTextContainer>
)
