import styled, { css } from 'styled-components';
import { AppColors } from 'src/app/constants/appColors';

export const QuestionHeader = styled.h3`
    font-size: 1.6rem;
    font-family: "DM Sans";
    font-weight: bold;
    margin: 10px 0;
    letter-spacing: 0.05px;

    @media (min-width: 1040px) {
        font-size: 1.8rem;
    }
`

export const QuestionSubHeader = styled.h4`
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    letter-spacing: 0.05px;
    line-height: 170%;
    font-family: "DM Sans";

    @media (min-width: 1040px) {
        font-size: 1.8rem;
    }
`

export const AnswerChoice = styled.label`
    padding: 0 0 1px 5px;
    letter-spacing: 0.05px;
    font-size: 1.6rem;

    @media (min-width: 1040px) {
        font-size: 1.8rem;
    }
`

export const AnswerDivider = styled.div`
    margin: 7px 0;
    display: flex;
    align-items: center;
    gap: 8px;
`

export const AnswerInput = styled.input`
    background: ${AppColors.WHITE};
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 0 16px;
    width: 100%;
    margin: 10px 0;
    color: ${AppColors.BLACK};
    font-size: 1.6rem;
    letter-spacing: 0.08px;
    line-height: 52px;
    
    ::placeholder {
      color: ${AppColors.SILVER};
      letter-spacing: 0.08px;
    }
`

export const AnswerInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`

export const QuestionContainer = styled.div<{bottomBorder?: boolean}>`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 30px auto 0 auto;
    ${({ bottomBorder }) => bottomBorder && css`
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding: 0 0 30px 0;
        margin: 30px auto;
    `}
`

export const PrequalFormContainer = styled.div`
    overFlow-y: scroll;
`
