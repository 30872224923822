import React from 'react'
import Footer from 'src/app/components/Footer'
import { ErrorPageBody, ErrorPageWrap } from '../styles/ErrorPage.style'

interface IProps {
  image: string
  children: React.ReactNode
}

export default function ErrorPage({ image, children }: IProps) {
  return (
    <ErrorPageWrap>
      <ErrorPageBody>
        <div>
          <img src={image} alt="illustration" />
          {children}
        </div>
      </ErrorPageBody>
      <Footer />
    </ErrorPageWrap>
  )
}
