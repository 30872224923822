export const getLabelFromTimeString = (time) => {
  const num = parseInt(time.replace(':', '').slice(0, time.length - 2), 10)
  if (num === 1200) {
    return '12:00  pm'
  } if (num > 1200) {
    const pmTime = (num - 1200).toString()
    const minutes = pmTime.slice(pmTime.length - 2, pmTime.length)
    const hours = pmTime.slice(0, pmTime.length - 2)
    return `${hours}:${minutes}  pm`
  }
  const amTime = num.toString()
  const minutes = amTime.slice(amTime.length - 2, amTime.length)
  const hours = amTime.slice(0, amTime.length - 2)
  return `${hours}:${minutes}  am`
}
