import React from 'react';
import {
  CardContainer,
  CardImageText,
  CardImageTextContainer,
  GridContainer,
  CardImageContainer,
  CardDetailsContainer,
  CardDetailsPrimary,
  HomeDetailsAltContainer,
  HomeDetailsAltText,
  HomeDetailsSpan,
  PrimaryHomeImage, NoResultsContainer, NoResultsText, CardDetailsSecondary,
} from 'src/renterDashboard/styles/PropertySearchGrid.style'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CardLoader } from 'src/renterDashboard/components/CardLoader';
import { useNavigate } from 'react-router-dom';
import { Listing } from 'src/listing/types/listing.type';
import { dollarFormatter } from 'src/app/utils/dollarFormatter';
import _ from 'lodash';

interface PropertySearchGridProps {
  listings: Listing[]
  loading: any
  setLoading: any
  currentPage: any
  hasNextPage: any
  getListings: any
  setListingCardOnHover: any
}

export const PropertySearchGrid = ({
  listings,
  loading,
  setLoading,
  currentPage,
  hasNextPage,
  getListings,
  setListingCardOnHover,
}: PropertySearchGridProps) => {
  const navigate = useNavigate()
  const loadMore = () => {
    setLoading(true)
    if (hasNextPage) {
      getListings(currentPage + 1)
    }
    setLoading(false)
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 1px 0px',
    delayInMs: 1000,
  });

  return (
    <GridContainer>
      {listings?.map((listing) => (
        <CardContainer
          key={listing.mls_id}
          onClick={() => navigate(`/listing/${listing.mls_id}`)}
          onMouseEnter={() => setListingCardOnHover(listing.mls_id)}
          onMouseLeave={() => setListingCardOnHover('')}
        >
          <CardImageContainer>
            <PrimaryHomeImage src={listing.images?.[0] || ''} height="100%" width="100%" alt="Picture of home" />
            <CardImageTextContainer>
              <CardImageText>{dollarFormatter(listing.rent_price || 0)}/mo</CardImageText>
            </CardImageTextContainer>
          </CardImageContainer>
          <CardDetailsContainer>
            <CardDetailsPrimary>{listing.address}</CardDetailsPrimary>
            <CardDetailsSecondary>{listing.city}, {listing.state} {listing.zip_code}</CardDetailsSecondary>
            <HomeDetailsAltContainer>
              <HomeDetailsAltText>{listing.beds} Bed</HomeDetailsAltText>
              <HomeDetailsAltText>{listing.bath} Bath</HomeDetailsAltText>
              <HomeDetailsAltText>{listing.sq_ft} sq ft.</HomeDetailsAltText>
            </HomeDetailsAltContainer>
          </CardDetailsContainer>
        </CardContainer>
      ))}
      {listings?.length > 0 && (loading || hasNextPage) && <div ref={sentryRef}><CardLoader /></div>}
      {_.isEmpty(listings) && (
      <NoResultsContainer>
        <NoResultsText>No results found.<br />Please search for a valid address.</NoResultsText>
      </NoResultsContainer>
      )}
    </GridContainer>
  )
}
