import { AppColors } from 'src/app/constants/appColors'
import styled from 'styled-components'

export const ErrorPageWrap = styled.div`
  @media (min-width: 1040px) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
  }
`

export const ErrorPageBody = styled.div`
  text-align: center;
  padding: 0 20px 60px;
  max-width: 80%;
  margin: 0 auto;

  @media (min-width: 1040px) {
    flex: 1;
    padding: 0 0 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin: 0 auto;
  }

  p {
    font-size: 1.8rem;
    margin: 24px 0;
  }
`
