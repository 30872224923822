import React from 'react'
import { Helmet } from 'react-helmet';
import Button from 'src/app/components/Button'
import Width from 'src/app/components/Width'
import WhyHomeSliceHero from 'src/whyHomeSlice/assets/WhyHomeSliceHero.webp'
import { ReactComponent as Graph } from 'src/whoWeAre/assets/RentingGraph.svg'
import { ReactComponent as Scale } from 'src/whoWeAre/assets/Scale.svg'
import { ReactComponent as House } from 'src/whoWeAre/assets/House.svg'
import StartBg from 'src/whoWeAre/assets/StartBg.jpg'
import Plants from 'src/whoWeAre/assets/Plants.svg'
import Key from 'src/whoWeAre/assets/Key.svg'
import WealthBlock from 'src/whoWeAre/components/WealthBlock'
import SplitBlock from 'src/whoWeAre/components/SplitBlock'
import StartBlock from 'src/app/components/Start'
import Footer from 'src/app/components/Footer'
import PageHeader from 'src/app/components/PageHeader'
import tw from 'twin.macro';
import {
  ChallengesHeading,
  Wealth,
  WealthFlex,
  WealthHeading,
} from './styles/WhyHomeSliceView.style'

const PrimaryButton = tw.button`bg-blue-800 text-white px-6 py-2 m-6 rounded-md hover:bg-blue-600`;

export default function WhyHomeSlice() {
  return (
    <>
    <Helmet>‍
        <title>About Us | HomeSlice</title>‍
        <meta
          name="description"
          content="HomeSlice makes it easy to find the home of your dreams. While home prices have sky-rocketed,
          what people earn has remained the same, preventing millions from achieving the American dream of owning a
          home and growing their personal and family wealthy through equity."
        />
    </Helmet>
    <PageHeader heading="Turning Renters Into Wealth Builders" image={WhyHomeSliceHero}>
        HomeSlice gives you affordable access to the benefits of homeownership while you rent.
    </PageHeader>
    <Wealth>
      <Width>
        <WealthHeading>Grow Your Wealth Like a Homeowner While You Rent</WealthHeading>
        <WealthFlex>
          <WealthBlock image={Plants} source="Source: Keeping Current Matters" heading="Homeowners Have Higher Net Worth Than Renters">
            According to the federal reserve, the average net worth of a homeowner is $255k and the average net worth of a renter is only $6.3k.
          </WealthBlock>
          <WealthBlock image={Key} color="orange" source="Source: First American" heading="The Largest Component Of Wealth For Homeowners Is Their Home">
            Families that own assets have higher net worth. A home is the single greatest asset anyone can have.
            It&apos;s a levered savings utility that doubles as a place to live. For all but the richest Americans,
            home is the primary source of wealth.
          </WealthBlock>
        </WealthFlex>
      </Width>
    </Wealth>
    <ChallengesHeading>The Challenges Of Renting</ChallengesHeading>
    <SplitBlock
      reverse
      heading="Income Is Not Keeping Up With House Prices"
      image={<Graph />}
      source="Source: Real Estate Witch House Price to Income Ratio Study, 2021"
    >
      Adjusting for inflation, average home values have increased 118% (from $171,942 to $374,900) since 1965, but median household income increased only 15%(from $59,920 to $69,178).
    </SplitBlock>

    <SplitBlock
      color="orange"
      heading="The Gap Between Renters And Homeowners Is Bigger Than Ever"
      image={<Scale />}
      source="Source: Keeping Current Matters"
    >
      Not only do homeowners have more wealth, but the rate that their wealth grows is significantly higher than renters.
    </SplitBlock>

    <SplitBlock
      className="more"
      reverse
      heading="More Households Are Renting Than Ever"
      image={<House />}
      source="Source: Homeowners Slider and Renters Rise"
    >
      Projecting to have 9 million new renter household formation over the next 10 years. This will result in a lost generation of wealth creation as new household formations lose access to homeownership due to income not keeping up with inflation.
    </SplitBlock>
    <StartBlock color="green" bg={StartBg} />
    <Footer />
    </>
  )
}
