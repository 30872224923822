import { Auth } from 'aws-amplify'
import {
  setAccessToken,
  setCognitoUser,
  setGlobalAccountType,
  setRefreshToken,
  setUserId,
} from 'src/app/reducers/userSlice'
import { setLoginModalOpen } from 'src/app/reducers/modalSlice'
import { LoginForm } from 'src/home/types/login';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import jwt_decode from 'jwt-decode';

export const postLogin = async (
  login: LoginForm,
  navigate: any,
  setError: (message: string) => void,
  dispatch: any,
  resetLogin?: any,
) => {
  dispatch(setIsLoading(true))
  try {
    const user = await Auth.signIn(login.username, login.password);
    const accessToken = user.signInUserSession.idToken.jwtToken
    const refreshToken = user.signInUserSession.refreshToken.token
    const accountType = user.signInUserSession.accessToken.payload['cognito:groups'][0].replace('s', '')
    const { sub }: { sub: string } = jwt_decode(accessToken)
    dispatch(setCognitoUser(user))
    dispatch(setAccessToken(accessToken))
    dispatch(setRefreshToken(refreshToken))
    dispatch(setGlobalAccountType(accountType))
    dispatch(setLoginModalOpen(false))
    dispatch(setUserId(sub))
    if (resetLogin) resetLogin()
    navigate(`/home/${accountType}`)
  } catch (error: any) {
    setError(error.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}
