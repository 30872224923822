import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors'

interface IProps {
  color: string
  bg: string
  alt?: boolean
}

export const Start = styled.div`
    margin-bottom: 60px;

    @media (min-width: 1040px) {
        margin-bottom: 100px;
    }
`

export const StartBlockk = styled.div<IProps>`
    background-color: ${AppColors.FADED_PURPLE};
    border-radius: 30px;
    background-size: auto 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-repeat: no-repeat;
    height: 540px;
    padding: 0 40px;

    @media (min-width: 1040px) {
        padding: 0 146px;
        background-image: ${({ bg }) => `url(${bg})`};
    }

    &.purple {
      background-color: ${AppColors.FADED_PURPLE};
    }

    &.green {
      background-color: #E2EBEA;
    }

    &.orange {
      background-color: #FCECD9;
    }
`

export const StartText = styled.div<IProps>`
    max-width: ${({ alt }) => alt ? '560px' : '440px'};
`

export const StartHeading = styled.h2`
    font-size: 4.8rem;
    margin-bottom: 15px;

    @media (min-width: 1040px) {
        font-size: 6.4rem;
    }
`

export const StartP = styled.p`
    font-size: 1.8rem;
    margin-bottom: 20px;
`
