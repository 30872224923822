import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import React from 'react';
import { NoWarningIconContainer, RentButton, WarningIconContainer } from 'src/agentDashboard/styles/PublishModal.style';
import { RentEdit } from 'src/agentDashboard/components/RentEdit';

interface RentInfoRowProps {
    info: any
    editRent: any
    warning: boolean
    setUpdatedRent: any
    onClickRent: any
    updatedRent: number
}

export const RentInfoRow = ({
  info, editRent, warning, updatedRent, setUpdatedRent, onClickRent,
}: RentInfoRowProps) => (
  <>
    <RentEdit
      editRent={editRent}
      info={info}
      updatedRent={updatedRent}
      setUpdatedRent={setUpdatedRent}
      warning={warning}
    />
    {warning ? (
      <WarningIconContainer>
        <WarningAmberRoundedIcon color="error" />
        <RentButton type="button" onClick={onClickRent}>{editRent ? 'Done' : 'Edit'}</RentButton>
      </WarningIconContainer>
    ) : (
      <NoWarningIconContainer>
        <RentButton type="button" onClick={onClickRent}>{editRent ? 'Done' : 'Edit'}</RentButton>
      </NoWarningIconContainer>
    )}
  </>
)
