import React from 'react'
import { Helmet } from 'react-helmet';
import Button from 'src/app/components/Button'
import Width from 'src/app/components/Width'
import WhoWeAreHero from 'src/whoWeAre/assets/WhoWeAreHero.webp'
import { ReactComponent as Graph } from 'src/whoWeAre/assets/RentingGraph.svg'
import { ReactComponent as Scale } from 'src/whoWeAre/assets/Scale.svg'
import { ReactComponent as House } from 'src/whoWeAre/assets/House.svg'
import StartBg from 'src/whoWeAre/assets/StartBg.jpg'
import Plants from 'src/whoWeAre/assets/Plants.svg'
import Founders from 'src/whoWeAre/assets/FoundersPhoto.webp'
import Key from 'src/whoWeAre/assets/Key.svg'
import WealthBlock from 'src/whoWeAre/components/WealthBlock'
import SplitBlock from 'src/whoWeAre/components/SplitBlock'
import StartBlock from 'src/app/components/Start'
import Footer from 'src/app/components/Footer'
import PageHeader from 'src/app/components/PageHeader'
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'
import { PageHeaderHeading, PageHeaderP } from 'src/app/styles/PageHeader.style'
import {
  ChallengesHeading,
  Wealth,
  WealthFlex,
  WealthHeading,
  VerticalFlex,
  WealthBlockImg,
  WealthBlockD,
  WealthBlockDescPadded,
  WealthBlockHeadingPadded,
  WealthBlockSource,
  FounderQuote,
  FounderQuoteBlock,
  FounderQuoteBlockOrange,
  SplitBlockk,
  SplitBlockLeft,
  SplitBlockRight,
  FounderImg,
  FounderSplitBlockRight,
  FounderHeading,
  FounderSplitBlockLeft,
} from './styles/WhoWeAreView.style'

const FounderP = tw.p`
  text-3xl
  leading-10
`
const FounderSectionBreak = tw.div`pt-32`

export default function WhoWeAre() {
  return (
    <>
    <Helmet>‍
        <title>Earn Equity While You Rent | HomeSlice</title>‍
        <meta
          name="description"
          content="HomeSlice turns renters into wealth builders. When you rent a home with HomeSlice you'll earn equity
          while you rent. At the end of your HomeSlice rental lease you can choose to get a check for your share of the
          increase in the home's value, roll your equity into a new HomeSlice rental or use your equity toward the
          downpayment of a home purchase. We buy. You rent and grow your wealth. Get more flexiblity for your finances
          while you save for the future with HomeSlice."
        />
    </Helmet>
    <PageHeader heading="Making Your Dream Home Affordable" image={WhoWeAreHero}>
      While home prices have sky-rocketed, what people earn has remained the same, preventing millions from achieving the American dream of owning a home and growing their personal and family wealthy through equity.
    </PageHeader>

    <Width>
      <SplitBlockk>
        <FounderSplitBlockLeft>
          <FounderHeading>Own A Slice of the American Dream</FounderHeading>
          <FounderP>
            As young entrepreneurs building businesses and starting a new family, founders Babak Mortazavi and Ellen Reid
            experienced firsthand the growing inaccessibility of homeownership.<br />
            <br />
            Throughout their careers building successful real estate companies of their own, this growing problem and how to solve it was the topic of many dinnertime
            conversations for Babak and Ellen.<br />
            <br />
            And then it struck them: what if they could create a business that worked
            like a steppingstone for renters—a way for people to get access to the home of their dreams, earn equity and
            build their personal and family wealthy?<br />
            <br />
            HomeSlice was born.
          </FounderP>
        </FounderSplitBlockLeft>
        <FounderSplitBlockRight>
          <FounderQuoteBlock>
            <FounderP>
              “Helping a new family get their first home is one of the most rewarding parts of my job. Real estate
              is more than buying and selling property—it&apos;s helping someone achieve one of their dreams.”
            </FounderP>
            <WealthBlockSource>
              ―Ellen Reid, Cofounder of HomeSlice
            </WealthBlockSource>
          </FounderQuoteBlock>
          <FounderSectionBreak />
          <FounderImg src={Founders} />
        </FounderSplitBlockRight>
      </SplitBlockk>
    </Width>

    <Width>
      <WealthFlex>
        <FounderQuoteBlockOrange>
          <FounderQuote>
            “I just kept feeling like the rent is just too damn high, and its not fair. We created HomeSlice to level the playing field for millions of Americans.”
            <br />
            <WealthBlockSource>
              ―Babak Mortazavi, Cofounder of HomeSlice
            </WealthBlockSource>
          </FounderQuote>
        </FounderQuoteBlockOrange>
      </WealthFlex>
    </Width>

    <FounderSectionBreak />

    <StartBlock color="green" bg={StartBg} />
    <Footer />
    </>
  )
}
