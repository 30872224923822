import React from 'react'
import { useAppDispatch } from 'src/app/hooks'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import {
  setScheduleModalStepOneOpen,
  setScheduleModalStepTwoOpen,
} from 'src/app/reducers/modalSlice';
import ScheduleTour from 'src/listing/assets/ScheduleTourIcon.png'
import { ScheduleContentContainer, ScheduleImageContainer } from 'src/listing/styles/ScheduleATourModal.style';

interface ScheduleATourProps {
    isScheduleModalStepOneOpen: boolean
}

export const ScheduleATourModal = ({
  isScheduleModalStepOneOpen,
}: ScheduleATourProps) => {
  const dispatch = useAppDispatch()

  const closeStepOneModal = () => dispatch(setScheduleModalStepOneOpen(false))
  const openStepTwoModal = () => dispatch(setScheduleModalStepTwoOpen(true))

  const onClickHandler = () => {
    closeStepOneModal()
    openStepTwoModal()
  }

  return (
    <Modal
      onClose={closeStepOneModal}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isScheduleModalStepOneOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={closeStepOneModal}>
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          <ModalHeader>Schedule a Tour</ModalHeader>
          <ScheduleContentContainer>
            <ScheduleImageContainer>
              <img src={ScheduleTour} height="150px" width="150px" alt="Schedule a tour" />
            </ScheduleImageContainer>
            <ModalSubHeader>Please finish the signup and prequalification application before scheduling a tour</ModalSubHeader>
            <ModalPrimaryButton type="button" onClick={onClickHandler}>Continue</ModalPrimaryButton>
          </ScheduleContentContainer>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
