export interface SignUpForm {
    email: string
    password: string
    firstName: string
    lastName: string
    username: string
}

export const signUpInitialState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  username: '',
}
