import React from 'react'
import { connect } from 'react-redux';
import { TableRow } from 'src/agentDashboard/components/TableRow';
import {
  ListingTableRow,
  ListingTableHeader,
  ListingTable,
  InnerRowContainer, SortButton,
} from 'src/agentDashboard/styles/AgentDashboard.style'
import { agentTableHeaders } from 'src/agentDashboard/constants/agentTableHeaders';
import { Listing } from 'src/listing/types/listing.type';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { SortOption } from '../types/SortOption.type';

interface TableProps {
    listings: Listing[]
    setSortOption: any
    sortOption: SortOption
    updateListingsOnSort: any
}

export const Table = ({
  listings,
  setSortOption,
  sortOption,
  updateListingsOnSort,
}: TableProps) => {
  const setSortHandler = (label) => {
    if (sortOption.name === label) {
      const option = {
        name: label,
        asc: !sortOption.asc,
      }
      setSortOption(option)
      updateListingsOnSort(option)
    } else {
      const option = {
        name: label,
        asc: true,
      }
      setSortOption(option)
      updateListingsOnSort(option)
    }
  }
  return (
    <ListingTable>
      <ListingTableRow
        borderBottom
        borderRadius={{
          topRight: 15,
          topLeft: 15,
        }}
      >
        <InnerRowContainer>
          {agentTableHeaders.map((header, i) => (
            <ListingTableHeader key={i} width={header.width}>
              {header.sortable ? (
                <SortButton type="button" onClick={() => setSortHandler(header.name)}>
                  <div>{header.label || ''}</div>
                  {sortOption.name === header.name && sortOption.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </SortButton>
              ) : (
                <span>{header.label || ''}</span>
              )}
            </ListingTableHeader>
          ))}
        </InnerRowContainer>
      </ListingTableRow>
      {listings?.length > 0 && listings.map((listing, index) => (
        <TableRow key={listing.address} listing={listing} isShaded={!!(index % 2)} />
      ))}
    </ListingTable>
  )
}

const mapStateToProps = (state) => ({
  listings: state.listings.listings,
})

export default connect(mapStateToProps)(Table)
