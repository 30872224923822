import styled from 'styled-components'

export const RenterDashboardWrap = styled.div`
   @media (min-width: 1040px) {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 120px);
   }
`

export const RenterDashboardContainer = styled.div`
   @media (min-width: 1040px) {
      display: flex;
      align-items: stretch;
      flex: 1;
      overflow: hidden;
   }
`

export const RenterDashboardLoader = styled.div`
   background: white;
   display: flex;
   align-items: center;
   justify-content: center;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 20px;
   height: 400px;
`
