import styled from 'styled-components'

export const DisclaimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
`

export const DisclaimerMessage = styled.p`
font-size: 1.4rem;
color: rgba(0, 0, 0, 0.5);
line-height: 150%;
margin: 5px 0;
letter-spacing: 0.03rem;
`
