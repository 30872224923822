export enum AppColors {
    LIGHT_BISQUE = '#F8F7F4',
    BISQUE = '#f9e7c8',
    LINEN = '#f5efe5',
    WHITE_SMOKE = '#f8f7f5',
    BLACK = '#000000',
    POWDER_BLUE = '#a0e8cc',
    CORNFLOWER_BLUE = '#59b7ff',
    DARK_SLATE_BLUE = '#585481',
    INDIAN_RED = '#de6b48',
    WHITE = '#ffffff',
    SILVER = '#bbb9b4',
    BLUE_LINK = '#2573ba',
    FADED_GREEN = '#d0f3e5',
    FADED_RED = '#efc1b4',
    FADED_PURPLE = '#e7e2ea',
    FADED_BLUE = '#E2EAEA',
    FADED_ORANGE = '#FCEBD8',
}
