import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors';

export const GridContainer = styled.div`
    display: grid;
    gap: 10px;
    overflow: auto;
    padding-bottom: 10px;

    @media (min-width: 760px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1040px) {
        grid-template-columns: repeat(1, 1fr);
        width: calc(47% + 20px);
        padding-right: 20px;
    }

    @media (min-width: 1240px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

export const CardContainer = styled.button`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: fit-content;
    flex-wrap: wrap;
    background-color: ${AppColors.WHITE};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    padding: 0;
    cursor: pointer;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 760px) {
        max-width: none;
    }

    &:hover{
        // transform: scale(1.03);
        transition: transform .2s;
    }
`

export const CardImageContainer = styled.div`
    width: 100%;
    min-height: 340px;
    object-fit: cover;
    display: flex;
    justify-content: flex-end;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: relative;
    z-index: 1;
`
export const CardImageTextContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
    color: white;
    padding: 0 24px;
    border-radius: 50px;
    font-weight: 500;
    z-index: 20;
`
export const CardImageText = styled.h4`
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    line-height: 40px;
`

export const CardDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
`
export const CardDetailsPrimary = styled.h4`
    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
`
export const CardDetailsSecondary = styled.h4`
    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
    margin: 10px 0;
`
export const HomeDetailsAltContainer = styled.ul`
    display: flex;
    align-items: center;
    font-size: 1.8rem;
`
export const HomeDetailsAltText = styled.li`
    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
    
    &:after {
        content: '|';
        color: rgba(0,0,0,0.3);
        margin: 0 10px;
    }

    &:last-of-type:after {
        display: none;
    }
`

export const HomeDetailsSpan = styled.span`
    display: inline-block;
    margin: 0 4px;
    color: ${AppColors.SILVER}
`

export const PrimaryHomeImage = styled.img`
    position: absolute;
    z-index: 2;
    left: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
`

export const NoResultsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`

export const NoResultsText = styled.h1`
    font-size: 2rem;
    font-family: DM Sans;
    text-align: center;
    line-height: 1.6;
`
