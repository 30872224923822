import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors'

export const Footer = styled.div`
  font-size: 1.8rem;
`

export const FooterFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 45px 0 0 0;
  flex-direction: column;

  @media (min-width: 1040px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    gap: 60px;
  }
`

export const FooterLogo = styled.div`
`

export const FooterUl = styled.ul`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  flex-direction: column;

  @media (min-width: 1040px) {
    flex-direction: row;
    gap: 40px;
  }
`

export const FooterLi = styled.li`
`

export const FooterLink = styled(Link)`
  color: ${AppColors.BLACK};
  font-weight: 500;
`

export const FooterCopy = styled.p`
  margin-left: auto;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  text-align: center;

  @media (min-width: 1040px) {
    text-align: right;
    flex: 1;
  }
`
