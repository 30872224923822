import styled, { css } from 'styled-components'
import { AppColors } from 'src/app/constants/appColors';

export const ListingInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%
`

export const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
`

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
`

export const PropertyInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-right: 5%;
`

export const SectionLabel = styled.h3`
    font-family: 'DM Sans';
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 125%;
    color: #BCB9B4;
    padding-left: 5px;
    padding-bottom: 5px;
`

export const RowLabel = styled.h4`
    font-family: 'DM Sans';
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 125%;
    width: 45%;
`

export const RowValue = styled.h4<{color?: string, isStatusRow?: boolean}>`
    font-family: 'DM Sans';
    font-size: 1.5rem;
    line-height: 125%;
    color: ${({ color }) => color || AppColors.BLACK};
    ${({ isStatusRow }) => isStatusRow && css`
        background-color: white;
        border: 1px solid #c7c7c7;
        cursor: pointer;
        padding: 3px;
        border-radius: 10px;
    `}
};
`

export const RowLabelValueContainer = styled.div<{ topBorder: boolean}>`
    display: flex;
    align-items: center;
    padding: 8px 5px;

    ${({ topBorder }) => topBorder && css`
        border-top: 1px solid rgba(0, 0, 0, 0.08);
    `}
`

export const DescriptionText = styled.p`
    padding: 8px 5px;
    line-height: 150%;
`

export const RentButton = styled.button`
    background: transparent;
    border: none;
    color: #2573BA;
    cursor: pointer;
    text-align: right;
`

export const ColumnsContainer = styled.div`
    display: flex;
    justify-content: space-around;
`

export const WarningIconContainer = styled.div`
    width: 20%;
`

export const NoWarningIconContainer = styled.div`
    margin-left: 20px;
    width: 20%;
`
