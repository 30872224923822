import React from 'react'
import { LoginForm, loginInitialState } from 'src/home/types/login'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'src/app/hooks'
import { InputField } from 'src/app/components/InputField'
import { InputFieldType } from 'src/app/types/inputField.type'
import { ErrorBanner } from 'src/app/styles/ErrorBanner.style'
import {
  ModalHeader,
  ModalSubHeader,
  ModalPrimaryButton,
  ModalAltText,
  ModalAltButton,
  ModalForm,
  ModalAltContainer,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { postLogin } from 'src/home/actions/postLogin'
import { Modal } from 'src/app/components/Modal';
import { setLoginModalOpen, setSignUpModalOpen } from 'src/app/reducers/modalSlice';

interface LoginProps {
  isLoginModalOpen: boolean
}

export const LoginModal = ({
  isLoginModalOpen,
}: LoginProps) => {
  const [login, setLogin] = React.useState<LoginForm>(loginInitialState)
  const [error, setError] = React.useState('')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const triggerSignUpModal = (flag: boolean) => dispatch(setSignUpModalOpen(flag))
  const triggerLoginModal = (flag: boolean) => dispatch(setLoginModalOpen(flag))
  const resetLogin = () => {
    setLogin(loginInitialState)
    setError('')
  }

  const switchModals = (e:any) => {
    e.preventDefault()
    triggerSignUpModal(true)
    triggerLoginModal(false)
  }

  const loginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await postLogin(login, navigate, setError, dispatch, resetLogin)
  }

  const onChangeHandler = (e: { target: { name: string; value: string; }; }) => {
    setLogin({ ...login, [e.target.name]: e.target.value })
  }

  const isFormComplete = login.username && login.password

  const inputFields: InputFieldType[] = [
    {
      placeholder: 'Username',
      value: login.username,
      name: 'username',
    },
    {
      placeholder: 'Password',
      value: login.password,
      name: 'password',
      type: 'password',
    },
  ]

  return (
    <Modal
      onClose={() => {
        resetLogin()
        triggerLoginModal(false)
      }}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isLoginModalOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton
          type="button"
          onClick={() => {
            resetLogin()
            triggerLoginModal(false)
          }}
        >
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          {error && (
            <ErrorBanner>
              <h2>{error}</h2>
            </ErrorBanner>
          )}
          <ModalHeader>Login</ModalHeader>
          <ModalSubHeader>Welcome! Please sign in to continue.</ModalSubHeader>
          <ModalForm onSubmit={(e: any) => loginSubmit(e)}>
            {inputFields.map((field) => (
              <InputField
                key={field.placeholder}
                placeholder={field.placeholder}
                value={field.value}
                name={field.name}
                onChangeHandler={onChangeHandler}
                type={field.type || 'text'}
              />
            ))}
            <ModalPrimaryButton width="100" disabled={!isFormComplete} type="submit">Log In</ModalPrimaryButton>
          </ModalForm>
          <ModalAltContainer>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <ModalAltText>Don't have an account yet?</ModalAltText>
            <ModalAltButton
              type="button"
              onClick={(e: any) => {
                resetLogin()
                switchModals(e)
              }}
            >
              Sign Up
            </ModalAltButton>
          </ModalAltContainer>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
