import { Auth } from 'aws-amplify';
import {
  setAccessToken,
  setCognitoUser,
  setGlobalAccountType,
  setRefreshToken,
  setUserId,
  reset,
} from 'src/app/reducers/userSlice';
import { setLoginModalOpen } from 'src/app/reducers/modalSlice';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import _ from 'lodash';
import jwt_decode from 'jwt-decode';

export const getCurrentUser = (dispatch: any) => {
  Auth.currentAuthenticatedUser().then((user) => dispatch(setCognitoUser(user)))
    .catch((err) => err)
}

export const postSessionRefresh = (dispatch: any, cognitoUser: any) => {
  dispatch(setIsLoading(true))

  return Auth.currentSession()
    .then((data: any) => {
      getCurrentUser(dispatch)
      const accessToken = data.idToken.jwtToken
      const refreshToken = data.refreshToken.token
      const accountType = data.accessToken.payload['cognito:groups'][0].replace('s', '')
      const { sub }: { sub: string } = jwt_decode(accessToken);
      dispatch(setAccessToken(accessToken))
      dispatch(setRefreshToken(refreshToken))
      dispatch(setGlobalAccountType(accountType))
      dispatch(setLoginModalOpen(false))
      dispatch(setUserId(sub))
    })
    .catch((err) => {
      if (!_.isEmpty(cognitoUser)) cognitoUser.globalSignOut()
      dispatch(reset())
    })
    .finally(() => dispatch(setIsLoading(false)))
}
