import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors'

export const TourScheduleContainer = styled.div`
  background: ${AppColors.WHITE};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 7%;
`

export const IconDetailContainer = styled.div`
  display: flex;
  align-items: center;
  
  h3{
    font-family: 'DM Sans', sans-serif;
    font-size: 1.6rem;
    margin-left: 13px;
  }
  
    @media screen and (max-width: 1150px) {
        margin: 10px 0;
    }
`

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  
  @media screen and (max-width: 1150px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`

export const HeadlineContainer = styled.div`
    margin-bottom: 20px;
`

export const ScheduleButton = styled.button`
    font-family: 'DM Serif Display', serif;
    font-size: 1.5rem;
    font-weight: normal;
    letter-spacing: 0.06rem;
    line-height: 125%;
    text-align: center;
    color: ${AppColors.WHITE};
    border: none;
    background-color: ${AppColors.BLACK};
    padding: 15px;
    border-radius: 25px;
    width: 100%;
    margin-top: 15px;
    &:hover{
        color: ${AppColors.BLACK};
        background-color: ${AppColors.WHITE};
        border: 1px solid ${AppColors.BLACK};
        margin-top: 13px;
        cursor: pointer;
    }
`

export const ScheduleTableRow = styled.div<{border: boolean, boldTitle: boolean, largeText: boolean}>`
    border-bottom: ${({ border }) => (border ? '1px solid rgba(0, 0, 0, 0.08)' : 'none')};
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h4{
      font-family: 'DM Sans', sans-serif;
      font-size: 1.6rem;
      font-weight: ${({ boldText }) => (boldText ? '700' : '500')};
    }
    
    h5{
      font-family: 'DM Sans', sans-serif;
      font-size: ${({ largeText }) => (largeText ? '2rem' : '1.6rem')};
      font-weight: ${({ boldText }) => (boldText ? '700' : '400')};
    }
`
