interface PropertyNumberOfStory {
    value: string
    label: string
}

export const propertyNumberOfStories: PropertyNumberOfStory[] = [
  {
    value: '1',
    label: '1+',
  },
  {
    value: '2',
    label: '2+',
  },
]
