import { Auth } from 'aws-amplify';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { setSignUpModalOpen } from 'src/app/reducers/modalSlice'
import { SignUpForm } from 'src/home/types/signUp'
import { postLogin } from 'src/home/actions/postLogin';

export const postSignUp = async (
  signUp: SignUpForm,
  setError: (message: string) => void,
  dispatch: any,
  resetSignUp: any,
  navigate: any,
) => {
  dispatch(setIsLoading(true))
  try {
    const { user } = await Auth.signUp({
      username: signUp.username,
      password: signUp.password,
      attributes: {
        email: signUp.email,
        given_name: signUp.firstName,
        family_name: signUp.lastName,
        'custom:user_type': 'renter',
      },
    });
    dispatch(dispatch(setSignUpModalOpen(false)))
    resetSignUp()
    await postLogin(
      {
        username: signUp.username,
        password: signUp.password,
      },
      navigate,
      setError,
      dispatch,
    )
    return user
  } catch (error: any) {
    setError(error.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}
