import React from 'react'
import {
  HomeBlock,
  HomeBlockLeft,
  HomeBlockLeftHeading,
  HomeBlockLeftP,
} from '../styles/HomeView.style'

interface IProps {
  title: string
  children: React.ReactNode
  image: string
  color: 'blue' | 'purple' | 'orange'
  reverse?: boolean
}

export default function HomeSlide({
  title,
  children,
  image,
  color,
  reverse,
}:IProps) {
  return (
    <HomeBlock image={image} className={`${color} ${reverse ? 'reverse' : ''}`}>
      <HomeBlockLeft>
        <HomeBlockLeftHeading>{title}</HomeBlockLeftHeading>
        <HomeBlockLeftP>{children}</HomeBlockLeftP>
      </HomeBlockLeft>
    </HomeBlock>
  )
}
