import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Listing } from 'src/listing/types/listing.type';
import _ from 'lodash';

interface InitialState {
    selectedListing: Listing
}

export const initialState: InitialState = {
  selectedListing: {},
}

export const selectedListingSlice = createSlice({
  name: 'selectedListing',
  initialState,
  reducers: {
    setSelectedListing: (state, action: PayloadAction<Listing>) => {
      if (!_.isEmpty(action.payload)) {
        state.selectedListing = { ...action.payload }
      } else {
        state.selectedListing = {}
      }
    },
    reset: () => initialState,
  },
})

export const {
  setSelectedListing,
  reset,
} = selectedListingSlice.actions

export default selectedListingSlice.reducer
