import React from 'react'
import { ApplicationAnswerType } from 'src/listing/types/applicationAnswers.type';
import {
  AnswerChoice,
  AnswerDivider,
  QuestionContainer,
  QuestionHeader,
} from 'src/listing/styles/PrequalApplication.style';

interface QuestionOneProps {
    selectedAnswers: ApplicationAnswerType
    setSelectedAnswers: (any) => void
}

export const QuestionOne = ({ selectedAnswers, setSelectedAnswers }: QuestionOneProps) => {
  const onChangeHandler = (e) => {
    setSelectedAnswers({
      ...selectedAnswers,
      questionOne: e.target.value,
    })
  }
  const answers = ['Not sure yet', 'ASAP', 'In 1-2 months', 'In 3-4 months', 'In 4+ months']
  return (
    <QuestionContainer bottomBorder>
      <QuestionHeader>1. When are you planning to move?</QuestionHeader>
      {answers.map((answer) => (
        <AnswerDivider key={answer}>
          <input
            type="radio"
            id={answer}
            value={answer}
            checked={selectedAnswers.questionOne === answer}
            onChange={onChangeHandler}
          />
          <AnswerChoice htmlFor={answer}>{answer}</AnswerChoice>
        </AnswerDivider>
      ))}
    </QuestionContainer>
  )
}
