import styled, { css } from 'styled-components';

export const MapContainer = styled.div<{width: string, height?: string, alt?: boolean}>`
    height: 300px;
    margin-bottom: 20px;

    @media (min-width: 1040px) {
        width: ${({ alt }) => alt ? '100%' : '53%'};
        height: auto;
        order: 2;
        margin-bottom: 0;
    }
`

export const MapComponent = styled.div<{hasBorderRadius: boolean}>`
    ${({ hasBorderRadius }) => hasBorderRadius && css`border-radius: 20px;`}
`
