import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Listing } from 'src/listing/types/listing.type';

interface InitialState {
  listings: Listing[]
  totalListingCount: number
}

export const initialState: InitialState = {
  listings: [],
  totalListingCount: 0,
}

export const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setListings: (state, action: PayloadAction<Listing[]>) => {
      state.listings = action.payload
    },
    setTotalListingCount: (state, action: PayloadAction<number>) => {
      state.totalListingCount = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setListings,
  setTotalListingCount,
  reset,
} = listingsSlice.actions

export default listingsSlice.reducer
