import _ from 'lodash';
import React from 'react';
import { DayContainer, DayScheduleContainer, NoScheduleEntryText } from 'src/agentDashboard/styles/AgentAvailabilityModal.style';
import { AvailabilityCheckbox } from 'src/agentDashboard/components/AvailabilityCheckbox';
import { ScheduleEntryRow } from 'src/app/components/ScheduleEntryRow';
import { AgentSchedule, DaySection } from 'src/agentDashboard/constants/agentSchedule';

interface ScheduleDayRowProps {
    pendingAgentSchedule: AgentSchedule
    daySection: DaySection
    setPendingAgentSchedule: any
    dayOfWeek: string
}

export const ScheduleDayRow = ({
  daySection,
  pendingAgentSchedule,
  setPendingAgentSchedule,
  dayOfWeek,
}: ScheduleDayRowProps) => {
  const {
    name, isActive, label, hasUnfinished, schedule,
  } = daySection

  const onCheckboxChange = (e) => {
    const dayOfWeek = pendingAgentSchedule[e.target.name]
    setPendingAgentSchedule({
      ...pendingAgentSchedule,
      [e.target.name]: {
        ...dayOfWeek,
        hasUnfinished: !dayOfWeek.isActive,
        isActive: !dayOfWeek.isActive,
        schedule: dayOfWeek.isActive ? [] : [{
          startTime: '',
          startTimeNum: null,
          startTimeLabel: '',
          endTime: '',
          endTimeNum: null,
          endTimeLabel: '',
        }],
      },
    })
  }

  const onChangeHandler = (value, dayOfWeek, entryIndex, inputField) => {
    if (inputField === 'START') {
      const filteredCurrentSchedule = pendingAgentSchedule[dayOfWeek].schedule.filter((entry, index) => index !== entryIndex)
      filteredCurrentSchedule.push({
        startTime: value.value,
        startTimeNum: value.num,
        startTimeLabel: value.label,
        endTime: '',
        endTimeNum: null,
        endTimeLabel: '',
      })
      setPendingAgentSchedule({
        ...pendingAgentSchedule,
        [dayOfWeek]: {
          ...pendingAgentSchedule[dayOfWeek],
          schedule: filteredCurrentSchedule,
        },
      })
    }

    if (inputField === 'END') {
      const filteredCurrentSchedule = pendingAgentSchedule[dayOfWeek].schedule.filter((entry, index) => index !== entryIndex)
      const currentScheduleEntry = pendingAgentSchedule[dayOfWeek].schedule.find((entry, index) => index === entryIndex)
      setPendingAgentSchedule({
        ...pendingAgentSchedule,
        [dayOfWeek]: {
          ...pendingAgentSchedule[dayOfWeek],
          hasUnfinished: false,
          schedule: [
            ...filteredCurrentSchedule,
            {
              ...currentScheduleEntry,
              endTime: value.value,
              endTimeNum: value.num,
              endTimeLabel: value.label,
            },
          ],
        },
      })
    }
  }

  return (
    <DayContainer key={name}>
      <AvailabilityCheckbox checked={isActive} onChangeHandler={onCheckboxChange} label={label} name={name} />
      <DayScheduleContainer>
        {!_.isEmpty(schedule)
          ? schedule.map((scheduleEntry, index) => (
            <ScheduleEntryRow
              key={index}
              scheduleEntry={scheduleEntry}
              hasUnfinished={hasUnfinished}
              pendingAgentSchedule={pendingAgentSchedule}
              daySection={daySection}
              setPendingAgentSchedule={setPendingAgentSchedule}
              onChangeHandler={onChangeHandler}
              entryIndex={index}
              dayOfWeek={dayOfWeek}
              schedule={schedule}
            />
          )) : <NoScheduleEntryText>Unavailable</NoScheduleEntryText>}
      </DayScheduleContainer>
    </DayContainer>
  )
}
