import styled from 'styled-components';

export const ScheduleContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
`

export const ScheduleImageContainer = styled.div`
    margin: 40px 0 0 0;
`
