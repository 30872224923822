import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux';

import BathIcon from 'src/listing/assets/BathIcon.png'
import BedIcon from 'src/listing/assets/BedIcon.png'
import SqFtIcon from 'src/listing/assets/SqFtIcon.png'

import { ScheduleHeadline } from 'src/listing/styles/Listing.style';
import {
  TourScheduleContainer,
  HeadlineContainer,
  BodyContainer,
  IconDetailContainer,
  ScheduleTableRow,
  ScheduleButton,
} from 'src/listing/styles/TourSchedule.style'

import { Listing } from 'src/listing/types/listing.type';
import { useAppDispatch } from 'src/app/hooks';
import { setScheduleModalStepOneOpen } from 'src/app/reducers/modalSlice';
import { dollarFormatter } from 'src/app/utils/dollarFormatter';
import { postScheduleTourRefresh } from 'src/listing/actions/postScheduleTourRefresh';

import { ScheduleATourModal } from 'src/listing/components/modals/ScheduleATourModal';
import { PrequalApplicationModal } from 'src/listing/components/modals/PrequalApplicationModal';
import { CalendarApplicationModal } from 'src/listing/components/modals/CalendarApplicationModal';
import { SubmittedApplicationModal } from 'src/listing/components/modals/SubmittedApplicationModal';
import { BookingDetails } from 'src/listing/types/BookingDetails.type';
import { BookingAvailabilityModal } from 'src/listing/components/modals/BookingAvailabilityModal';

interface TourScheduleProps {
  listing: Listing
  accessToken: string
  cognitoUser: any
  userId: string
  isScheduleModalStepOneOpen: boolean
  isScheduleModalStepTwoOpen: boolean
  isScheduleModalStepThreeOpen: boolean
  isScheduleModalStepFourOpen: boolean
  isScheduleModalStepFiveOpen: boolean
  selectedListingId: string
}

export const TourSchedule = ({
  listing,
  accessToken,
  cognitoUser,
  isScheduleModalStepOneOpen,
  isScheduleModalStepTwoOpen,
  isScheduleModalStepThreeOpen,
  isScheduleModalStepFourOpen,
  isScheduleModalStepFiveOpen,
  userId,
  selectedListingId,
}: TourScheduleProps) => {
  const [bookingDetails, setBookingDetails] = React.useState<BookingDetails>({})
  const [availabilitySchedule, setAvailabilitySchedule] = React.useState([])
  const dispatch = useAppDispatch()

  const onClickHandler = () => {
    if (accessToken) dispatch(setScheduleModalStepOneOpen(true))
    postScheduleTourRefresh(dispatch, cognitoUser)
  }

  const securityDeposit = React.useMemo(() => dollarFormatter(listing.security_deposit || 0), [listing.security_deposit])
  const listPrice = React.useMemo(() => dollarFormatter(listing.rent_price || 0), [listing.rent_price])
  const listingGrowth = React.useMemo(() => dollarFormatter(listing.growth || 0), [listing.growth])
  return (
    <TourScheduleContainer>
      <HeadlineContainer>
        <ScheduleHeadline>{listing.address}</ScheduleHeadline>
        <ScheduleHeadline>{listing.city}, {listing.state} {listing.zip_code}</ScheduleHeadline>
      </HeadlineContainer>
      <BodyContainer>
        <IconDetailContainer>
          <img src={BedIcon} alt="Bedrooms" />
          <h3>{listing.beds} Beds</h3>
        </IconDetailContainer>
        <IconDetailContainer>
          <img src={BathIcon} alt="Bathrooms" />
          <h3>{listing.bath} Bath</h3>
        </IconDetailContainer>
        <IconDetailContainer>
          <img src={SqFtIcon} alt="Square Feet" />
          <h3>{listing.sq_ft} sq ft.</h3>
        </IconDetailContainer>
      </BodyContainer>
      <div>
        <ScheduleTableRow border>
          <h4>Move in from</h4>
          <h5>{moment().format('LL')}</h5>
        </ScheduleTableRow>
        <ScheduleTableRow border>
          <h4>Security Deposit</h4>
          <h5>{securityDeposit}</h5>
        </ScheduleTableRow>
        <ScheduleTableRow border>
          <h4>Projected 3 yr. Growth</h4>
          <h5>{listingGrowth}</h5>
        </ScheduleTableRow>
        <ScheduleTableRow boldText largeText>
          <h4>Monthly Rent</h4>
          <h5>{listPrice}</h5>
        </ScheduleTableRow>
      </div>
      <ScheduleButton onClick={onClickHandler} type="button">Schedule Tour</ScheduleButton>
      <ScheduleATourModal isScheduleModalStepOneOpen={isScheduleModalStepOneOpen} />
      <PrequalApplicationModal isScheduleModalStepTwoOpen={isScheduleModalStepTwoOpen} />
      <CalendarApplicationModal
        isScheduleModalStepThreeOpen={isScheduleModalStepThreeOpen}
        setBookingDetails={setBookingDetails}
        accessToken={accessToken}
        setAvailabilitySchedule={setAvailabilitySchedule}
      />
      <BookingAvailabilityModal
        isScheduleModalStepFourOpen={isScheduleModalStepFourOpen}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        userId={userId}
        accessToken={accessToken}
        selectedListingId={selectedListingId}
        availabilitySchedule={availabilitySchedule}
      />
      <SubmittedApplicationModal isScheduleModalStepFiveOpen={isScheduleModalStepFiveOpen} />
    </TourScheduleContainer>
  )
}

const mapStateToProps = (state) => ({
  isScheduleModalStepOneOpen: state.modal.isScheduleModalStepOneOpen,
  isScheduleModalStepTwoOpen: state.modal.isScheduleModalStepTwoOpen,
  isScheduleModalStepThreeOpen: state.modal.isScheduleModalStepThreeOpen,
  isScheduleModalStepFourOpen: state.modal.isScheduleModalStepFourOpen,
  isScheduleModalStepFiveOpen: state.modal.isScheduleModalStepFiveOpen,
  accessToken: state.user.accessToken,
  cognitoUser: state.user.cognitoUser,
  userId: state.user.userId,
  selectedListingId: state.selectedListing.selectedListing.mls_id,
})

export default connect(mapStateToProps)(TourSchedule)
