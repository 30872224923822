import { BASE_URL } from 'src/app/constants/baseUrl';
import { setSelectedListing } from 'src/listing/reducers/selectedListingSlice';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import axios from 'axios'

export const getSelectedListing = (mlsId: string | undefined) => (dispatch) => {
  const url = `${BASE_URL}/listing/${mlsId}`
  dispatch(setIsLoading(true))

  axios.get(url)
    .then((res) => {
      if (res?.data) dispatch(setSelectedListing(res.data))
    })
    .catch((err) => {
      console.error(err.message)
    })
    .finally(() => dispatch(setIsLoading(false)))
}
