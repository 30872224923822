import { reset as userReset } from 'src/app/reducers/userSlice';
import { reset as listingsReset } from 'src/listing/reducers/listingsSlice';
import { reset as searchReset } from 'src/renterDashboard/reducers/searchSlice';
import { reset as modalReset } from 'src/app/reducers/modalSlice';
import { reset as selectedListingReset } from 'src/listing/reducers/selectedListingSlice'
import { reset as loadingReset } from 'src/app/reducers/loadingSlice'
import { reset as agentScheduleReset } from 'src/agentDashboard/reducers/agentSchedule'

export const resetStore = () => (dispatch) => {
  dispatch(userReset())
  dispatch(listingsReset())
  dispatch(searchReset())
  dispatch(modalReset())
  dispatch(selectedListingReset())
  dispatch(loadingReset())
  dispatch(agentScheduleReset())
}
