import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CognitoUser } from 'amazon-cognito-identity-js';

const initialState = {
  accessToken: '',
  refreshToken: '',
  globalAccountType: '',
  cognitoUser: {},
  userId: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload
    },
    setGlobalAccountType: (state, action: PayloadAction<string>) => {
      state.globalAccountType = action.payload
    },
    setCognitoUser: (state, action: PayloadAction<any>) => {
      state.cognitoUser = action.payload
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setAccessToken,
  setRefreshToken,
  setGlobalAccountType,
  setCognitoUser,
  setUserId,
  reset,
} = userSlice.actions

export default userSlice.reducer
