import styled from 'styled-components'

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`

export const ModalSubHeaderContainer = styled.div`
    margin-bottom: 20px;
`
