import React from 'react';
import {
  PropertySearchInput,
  SuggestionContainer,
  SuggestionItem, SuggestionOuterContainer,
} from 'src/renterDashboard/styles/PropertySearchBar.style';

interface PlacesAutocompleteProps {
  value: any
  setValue: (any) => void
  status: string
  data: any
  handleSelect: (description, placeId) => void
}

export const PlacesAutocomplete = ({
  value,
  setValue,
  status,
  data,
  handleSelect,
}: PlacesAutocompleteProps) => (
  <>
    <PropertySearchInput
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search Properties"
    />
    {status === 'OK' && (
    <SuggestionOuterContainer>
      <SuggestionContainer>
        {data.map(({ place_id, description }) => (
          <SuggestionItem
            key={place_id}
            onClick={() => handleSelect(description, place_id)}
          >
            {description}
          </SuggestionItem>
        ))}
      </SuggestionContainer>
    </SuggestionOuterContainer>
    )}
  </>
)
