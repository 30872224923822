import { LightgalleryItem } from 'react-lightgallery';
import React from 'react'

interface ListingGalleryProps {
  images: string[]
}

export const ListingGallery = ({ images }: ListingGalleryProps) => (
  <>
    {images.map((img) => (
      <LightgalleryItem key={img} group="listing" src={img} thumb={img} plugins={['lg-thumbnail.js']} />
    ))}
  </>
)
