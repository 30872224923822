import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AppColors } from 'src/app/constants/appColors';

export const ScheduleHeadline = styled.h2`
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 130%;
`

export const ListingContainer = styled.div`
`

export const ListingReturnLink = styled(Link)`
  margin: 0 0 20px;
  display: inline-block;
  color: ${AppColors.BLUE_LINK}; 
  text-decoration: none;
  font-size: 1.6rem;
  font-family: 'DM Sans', sans-serif;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 8px 0 0;
  }
`

export const ListingBody = styled.div`
  display: flex; 
  flex-direction: column;

  @media (min-width: 1040px) {
    flex-direction: row;
    gap: 20px;
  }
`

export const LeftContainer = styled.div`
  @media (min-width: 1040px) {
    width: 65%;
  }
`

export const RightContainer = styled.div`
  @media (min-width: 1040px) {
    width: 35%;
  }
`

export const ListingMapContainer = styled.div`
  margin-top: 15px;
`
