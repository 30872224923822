import React from 'react'
import { Link } from 'react-router-dom'
import {
  FeaturedBlock,
  FeaturedBlockAddress,
  FeaturedBlockDetail,
  FeaturedBlockDetails,
  FeaturedBlockImage,
  FeaturedBlockImg,
  FeaturedBlockPrice,
  FeaturedBlockText,
  FeaturedBlockTitle,
} from '../styles/HomeView.style'

interface IProps {
  price: string
  image: string,
  title: string
  address: string
  beds: string
  baths: string
  sqft: string
  url: string
}

export default function FeaturedBlockk({
  price,
  image,
  title,
  address,
  beds,
  baths,
  sqft,
  url,
} : IProps) {
  return (
    <FeaturedBlock to={url}>
      <FeaturedBlockImage>
        <FeaturedBlockImg src={image} />
        {/* <FeaturedBlockPrice>{price}/mo</FeaturedBlockPrice> */}
      </FeaturedBlockImage>
      {/* <FeaturedBlockText>
        <FeaturedBlockTitle>{title}</FeaturedBlockTitle>
        <FeaturedBlockAddress>{address}</FeaturedBlockAddress>
        <FeaturedBlockDetails>
          <FeaturedBlockDetail>{beds} Bed</FeaturedBlockDetail>
          <FeaturedBlockDetail>{baths} Bath</FeaturedBlockDetail>
          <FeaturedBlockDetail>{sqft} sq ft.</FeaturedBlockDetail>
        </FeaturedBlockDetails>
      </FeaturedBlockText> */}
    </FeaturedBlock>
  )
}
