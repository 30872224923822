import React from 'react'
import { FormGroup } from '@mui/material';
import { connect } from 'react-redux';

import { Modal } from 'src/app/components/Modal';
import { useAppDispatch } from 'src/app/hooks';

import { setFilterModalOpen } from 'src/app/reducers/modalSlice';
import { InitialSearchState, setAdditionalFilters } from 'src/renterDashboard/reducers/searchSlice';

import { AdditionalFilters } from 'src/renterDashboard/types/AdditionalFilters.type';

import { propertyLotSizes } from 'src/renterDashboard/constants/propertyLotSizes';
import { propertyYearBuilt } from 'src/renterDashboard/constants/propertyYearBuilt';
import { propertySquareFeet } from 'src/renterDashboard/constants/propertySquareFeet';
import { propertyParkingSpots } from 'src/renterDashboard/constants/propertyParkingSpots';
import { propertyNumberOfStories } from 'src/renterDashboard/constants/propertyNumberOfStories';

import { AdditionalCheckboxFilter } from 'src/renterDashboard/components/AdditionalCheckboxFilter';
import { AdditionalFilterRange } from 'src/renterDashboard/components/AdditionalFilterRange';
import { AdditionalSelectFilter } from 'src/renterDashboard/components/AdditionalSelectFilter';

import {
  FilterContainer,
  LinkedSelectContainer,
  customFilterOverlayStyles,
  customFilterContentStyles,
} from 'src/renterDashboard/styles/FilterModal.style';
import {
  ModalPrimaryButton,
  ModalForm,
  ModalOuterContainer,
  ModalOptionLabel,
  ModalInnerContainer,
} from 'src/app/styles/modalStyles'

import { isFormChanged } from 'src/app/utils/isFormChanged'

interface FilterModalProps {
  isFilterModalOpen: boolean
  searchFilters: InitialSearchState
  getListings: any
}

export const FilterModal = ({ isFilterModalOpen, searchFilters, getListings }: FilterModalProps) => {
  const [filters, setFilters] = React.useState<AdditionalFilters>(searchFilters.additionalFilters)
  const dispatch = useAppDispatch()

  const triggerFilterModalClose = () => dispatch(setFilterModalOpen(false))
  const resetFilters = () => setFilters(searchFilters.additionalFilters)

  const getUpdatedListings = async () => {
    const updatedSearchFilters = { ...searchFilters, additionalFilters: filters }
    await getListings(1, updatedSearchFilters)
    dispatch(setAdditionalFilters(filters))
    triggerFilterModalClose()
  }

  const formChanged = React.useMemo(
    () => isFormChanged(searchFilters.additionalFilters, filters),
    [searchFilters.additionalFilters, filters],
  )

  const onCheckboxChange = (e) => {
    setFilters({ ...filters, [e.target.name]: !filters[e.target.name] })
  }

  const onSelectChange = (e, name) => {
    setFilters({ ...filters, [name]: e.target.value })
  }

  const onModalCloseNoSubmit = () => {
    resetFilters()
    triggerFilterModalClose()
  }

  return (
    <Modal
      onClose={onModalCloseNoSubmit}
      height="53rem"
      width="35rem"
      isOpen={isFilterModalOpen}
      customOverlayStyles={customFilterOverlayStyles}
      customContentStyles={customFilterContentStyles}
    >
      <ModalInnerContainer>
        <ModalOuterContainer>
          <ModalForm onSubmit={getUpdatedListings}>
            <FormGroup>
              <AdditionalFilterRange
                name="lotSize"
                label="Lot Size"
                filter={filters.lotSize}
                setFilters={setFilters}
                filters={filters}
                options={propertyLotSizes}
              />
              <AdditionalFilterRange
                name="squareFootage"
                label="Square Footage"
                filter={filters.squareFootage}
                setFilters={setFilters}
                filters={filters}
                options={propertySquareFeet}
              />
              <LinkedSelectContainer>
                <FilterContainer>
                  <ModalOptionLabel>Number of Stories</ModalOptionLabel>
                  <AdditionalSelectFilter
                    filterValue={filters.numberOfStories}
                    onChangeHandler={(e) => onSelectChange(e, 'numberOfStories')}
                    options={propertyNumberOfStories}
                  />
                </FilterContainer>
                <FilterContainer>
                  <ModalOptionLabel>Parking Spots</ModalOptionLabel>
                  <AdditionalSelectFilter
                    filterValue={filters.parkingSpots}
                    onChangeHandler={(e) => onSelectChange(e, 'parkingSpots')}
                    options={propertyParkingSpots}
                  />
                </FilterContainer>
              </LinkedSelectContainer>
              <AdditionalFilterRange
                name="yearBuilt"
                label="Year Built"
                filter={filters.yearBuilt}
                setFilters={setFilters}
                filters={filters}
                options={propertyYearBuilt}
              />
              <AdditionalCheckboxFilter
                label="Has a Basement"
                name="hasABasement"
                checked={filters.hasABasement}
                onChangeHandler={onCheckboxChange}
              />
              <AdditionalCheckboxFilter
                label="Has a Garage"
                name="hasAGarage"
                checked={filters.hasAGarage}
                onChangeHandler={onCheckboxChange}
              />
            </FormGroup>
            <ModalPrimaryButton width={90} type="submit" disabled={!formChanged}>See Results</ModalPrimaryButton>
          </ModalForm>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  searchFilters: state.search,
})

export default connect(mapStateToProps)(FilterModal)
