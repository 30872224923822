import React, { CSSProperties, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import 'src/app/App.css'
import { routes } from 'src/app/routes'
import { Routes, useNavigate } from 'react-router-dom'
import { NavMenu } from 'src/app/components/NavMenu'
import { connect } from 'react-redux'
import { LoginModal } from 'src/home/components/modals/LoginModal'
import { SignUpModal } from 'src/home/components/modals/SignUpModal'
import { useAppDispatch } from 'src/app/hooks'
import { renderRoute } from 'src/app/utils/routeRender'
import { getAmplifyConfig } from 'src/app/utils/amplifyConfig'
import { postSessionRefresh } from 'src/app/actions/postSessionRefresh';
import ClipLoader from 'react-spinners/ClipLoader';
import { Analytics } from '@vercel/analytics/react';

interface AppProps {
    accessToken: string
    globalAccountType: string
    cognitoUser: any
    isSignUpModalOpen: boolean
    isLoginModalOpen: boolean
    isLoadingCount: number
}

getAmplifyConfig()

export const App = ({
  accessToken,
  globalAccountType,
  cognitoUser,
  isSignUpModalOpen,
  isLoginModalOpen,
  isLoadingCount,
}: AppProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const override: CSSProperties = {
    position: 'absolute',
    margin: 'auto',
    zIndex: 20,
  };

  useEffect(() => {
    postSessionRefresh(dispatch, cognitoUser)
  }, [])

  return (
    <div className="app-container">
      <div className="App">
        <Helmet>
          <title>HomeSlice | Grow your wealth while you rent</title>
          <meta
            name="description"
            content="HomeSlice makes it easy to find an affordable home to rent. With HomeSlice you build equity when
            the value of your rental home goes up. Now you can participate in the American dream by growing your wealth
            while renting. Are you searching for an affordable home to rent in a neighborhood with good schools? HomeSlice
            will help you find the perfect home for you and your family."
          />
        </Helmet>
        {isLoadingCount > 0 && (
          <div className="loader-container">
            <div className="loading-box">
              <ClipLoader color="#000000" loading={isLoadingCount > 0} cssOverride={override} size={70} />
            </div>
          </div>
        )}
        <NavMenu
          cognitoUser={cognitoUser}
          accountType={globalAccountType}
          navigate={navigate}
        />
        <SignUpModal isSignUpModalOpen={isSignUpModalOpen} />
        <LoginModal isLoginModalOpen={isLoginModalOpen} />
        <Routes>
          {routes.map((route) => renderRoute(accessToken, route))}
        </Routes>
        <Analytics />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  accessToken: state.user.accessToken,
  globalAccountType: state.user.globalAccountType,
  cognitoUser: state.user.cognitoUser,
  isSignUpModalOpen: state.modal.isSignUpModalOpen,
  isLoginModalOpen: state.modal.isLoginModalOpen,
  isLoadingCount: state.loading.isLoadingCount,
})

export default connect(mapStateToProps)(App)
