import React from 'react'
import _ from 'lodash'
import {
  Header,
  Logo,
  Nav,
  NavItem,
  NavAnchor,
  NavButton,
  Hamburger,
  HeaderWrap,
} from 'src/app/styles/Nav.style'
import Width from 'src/app/components/Width'
import { ACCOUNT_TYPES } from 'src/app/types/accountTypes'
import { useAppDispatch } from 'src/app/hooks'
import { setCognitoUser } from 'src/app/reducers/userSlice';
import { setLoginModalOpen, setSignUpModalOpen } from 'src/app/reducers/modalSlice';
import { resetStore } from 'src/app/utils/resetStore';
import { useLocation } from 'react-router-dom'
import { ReactComponent as LogoSvg } from '../assets/Logo.svg';
import { ReactComponent as X } from '../assets/X.svg';
import { ReactComponent as Burger } from '../assets/Burger.svg';

interface NavMenuProps {
    accountType: string
    cognitoUser: any
    navigate: any
}

export const NavMenu = ({
  cognitoUser,
  accountType,
  navigate,
}: NavMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation();

  const triggerSignUpModal = () => dispatch(setSignUpModalOpen(true))
  const triggerLoginModal = () => dispatch(setLoginModalOpen(true))

  const logoutHandler = (e: any) => {
    if (!_.isEmpty(cognitoUser)) cognitoUser.globalSignOut()
    dispatch(setCognitoUser({}))
    navigate('/')
    dispatch(resetStore())
  }

  const navigateToDashboard = () => {
    const userDashboardRoute = accountType === ACCOUNT_TYPES.RENTER.toLowerCase() ? '/home/renter' : '/home/agent'
    return navigate(userDashboardRoute)
  }

  const isAbsolute = location.pathname === '/'
  const isWhite = false

  return (
    <HeaderWrap isWhite={isWhite} isAbsolute={isAbsolute}>
      <Width statik>
        <Header isWhite={isWhite}>
          <Logo to="/" isWhite={isWhite} isOpen={isOpen}>
            <LogoSvg />
          </Logo>

          <Hamburger isWhite={isWhite} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <X /> : <Burger />}
          </Hamburger>

          <Nav isOpen={isOpen}>
            <NavItem>
              <NavAnchor to="/home/renter" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isWhite={isWhite}>Our Homes</NavAnchor>
            </NavItem>
            <NavItem>
              <NavAnchor to="/how-it-works" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isWhite={isWhite}>How It Works</NavAnchor>
            </NavItem>
            <NavItem>
              <NavAnchor to="/who-we-are" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isWhite={isWhite}>Who We Are</NavAnchor>
            </NavItem>
            <NavItem>
              <NavAnchor to="/why-homeslice" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isWhite={isWhite}>Why HomeSlice</NavAnchor>
            </NavItem>
            <NavItem>
              <NavAnchor to="/residents" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} isWhite={isWhite}>Residents</NavAnchor>
            </NavItem>

            {_.isEmpty(cognitoUser) && <NavButton isOpen={isOpen} isWhite={isWhite} onClick={triggerSignUpModal}>Sign Up</NavButton>}
            {_.isEmpty(cognitoUser) && <NavButton isOpen={isOpen} isWhite={isWhite} onClick={triggerLoginModal}>Login</NavButton>}
            {!_.isEmpty(cognitoUser) && <NavButton isOpen={isOpen} isWhite={isWhite} onClick={navigateToDashboard}>My Account</NavButton>}
            {!_.isEmpty(cognitoUser) && <NavButton isOpen={isOpen} isWhite={isWhite} onClick={logoutHandler}>Logout</NavButton>}
          </Nav>
        </Header>
      </Width>
    </HeaderWrap>
  )
}
