import axios from 'axios';
import { BASE_URL } from 'src/app/constants/baseUrl';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { Listing } from 'src/listing/types/listing.type';

export const patchListing = (
  updatedRent: number,
  pendingStatus: boolean,
  setEditRent: any,
  setError: (msg: string) => void,
  listing: Listing,
  accessToken: string,
  dispatch: any,
) => {
  const url = `${BASE_URL}/protected/listing/${listing.mls_id}`
  const body: any = {}
  if (updatedRent !== listing.rent_price) body.rent_price = updatedRent
  if (pendingStatus !== listing.is_draft) body.is_draft = pendingStatus
  dispatch(setIsLoading(true))

  axios.patch(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      setEditRent(false)
    })
    .catch((err) => setError(err.message))
    .finally(() => dispatch(setIsLoading(false)))
}
