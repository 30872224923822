import React from 'react'
import AgentDashboard from 'src/agentDashboard/AgentDashboard'
import RenterDashboard from 'src/renterDashboard/RenterDashboard'
import { RouteType } from 'src/app/types/routes.type'
import Home from 'src/home/Home'
import Listing from 'src/listing/Listing'
import WhoWeAre from 'src/whoWeAre/WhoWeAre'
import HowItWorks from 'src/howItWorks/HowItWorks'
import WhyHomeSlice from 'src/whyHomeSlice/WhyHomeSlice'
import Opps from 'src/opps/Opps'
import ComingSoon from 'src/comingSoon/ComingSoon'

export const routes: RouteType[] = [
  {
    name: 'AgentDashboard',
    component: <AgentDashboard />,
    protected: true,
    path: 'home/agent',
  },
  {
    name: 'RenterDashboard',
    component: <RenterDashboard />,
    protected: false,
    path: 'home/renter',
  },
  {
    name: 'Home',
    component: <Home />,
    protected: false,
    path: '',
  },
  {
    name: 'Listing',
    component: <Listing />,
    protected: false,
    path: 'listing/:id',
  },
  {
    name: 'WhoWeAre',
    component: <WhoWeAre />,
    protected: false,
    path: 'who-we-are',
  },
  {
    name: 'HowItWorks',
    component: <HowItWorks />,
    protected: false,
    path: 'how-it-works',
  },
  {
    name: 'WhyHomeSlice',
    component: <WhyHomeSlice />,
    protected: false,
    path: 'why-homeslice',
  },
  {
    name: 'Opps',
    component: <Opps />,
    protected: false,
    path: 'opps',
  },
  {
    name: 'ComingSoon',
    component: <ComingSoon />,
    protected: false,
    path: 'coming-soon',
  },
  {
    name: 'Residents',
    component: <ComingSoon />,
    protected: false,
    path: 'residents',
  },
  {
    name: 'My Account',
    component: <ComingSoon />,
    protected: false,
    path: 'my-account',
  },
]
