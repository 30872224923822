import styled from 'styled-components'

export const Width = styled.div<{fullWidth?: boolean, static?: boolean}>`
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: ${(({ fullWidth }) => fullWidth ? 'none' : '1510px')};
  position: ${(({ statik }) => statik ? 'static' : 'relative')};

  @media (min-width: 1040px) {
    /* width: calc(100% - 80px); */
  }
`
