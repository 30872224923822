import { BASE_URL } from 'src/app/constants/baseUrl';
import axios from 'axios';
import { agentSchedule } from 'src/agentDashboard/constants/agentSchedule';
import { setAgentSchedule } from 'src/agentDashboard/reducers/agentSchedule';
import { findDayOfWeek } from 'src/agentDashboard/utils/findDayOfWeek';
import { getNumberFromTimeString } from 'src/agentDashboard/utils/getNumberFromTimeString';
import { getLabelFromTimeString } from 'src/agentDashboard/utils/getLabelFromTimeString';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import _ from 'lodash'

export const getAgentAvailability = (agentId, accessToken) => (dispatch: any) => {
  const url = `${BASE_URL}/availability/${agentId}`

  dispatch(setIsLoading(true))

  axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      const scheduleResponse = _.cloneDeep(agentSchedule)
      if (res.data.length > 0) {
        res.data.forEach((scheduleEntry) => {
          const entryDay = findDayOfWeek(scheduleEntry.day_of_week)
          if (entryDay) {
            const startTime = scheduleEntry.start_time
            const startTimeNum = getNumberFromTimeString(scheduleEntry.start_time)
            const startTimeLabel = getLabelFromTimeString(scheduleEntry.start_time)
            const endTime = scheduleEntry.end_time
            const endTimeNum = getNumberFromTimeString(scheduleEntry.end_time)
            const endTimeLabel = getLabelFromTimeString(scheduleEntry.end_time)
            scheduleResponse[entryDay].schedule.push({
              startTime,
              startTimeNum,
              startTimeLabel,
              endTime,
              endTimeNum,
              endTimeLabel,
            })
            scheduleResponse[entryDay].isActive = true
          }
        })
      }
      dispatch(setAgentSchedule(scheduleResponse))
    })
    .catch((err) => console.error(err.message))
    .finally(() => dispatch(setIsLoading(false)))
}
