import React from 'react'
import {
  DisclaimerContainer,
  DisclaimerMessage,
} from 'src/listing/styles/PropertyDisclaimerMessages.style'
import { dollarFormatter } from 'src/app/utils/dollarFormatter';

interface PropertyDisclaimerMessagesProps {
    listPrice: number
    agent: string | undefined
    office: string | undefined
}

export const PropertyDisclaimerMessages = ({ listPrice, agent, office }: PropertyDisclaimerMessagesProps) => {
  const formattedListPrice = React.useMemo(() => dollarFormatter(listPrice), [listPrice])

  return (
    <DisclaimerContainer>
      <DisclaimerMessage>
        This home is listed for sale by {agent || '-'} at {office || '-'} for {formattedListPrice}.
      </DisclaimerMessage>
      <DisclaimerMessage>
        Information from third parties are deemed reliable but not guaranteed accurate.
      </DisclaimerMessage>
      <DisclaimerMessage>
        Listings details provided by the participants of Mid America Regional Information Systems Data Exchange.
      </DisclaimerMessage>
    </DisclaimerContainer>
  )
}
