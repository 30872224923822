import { AvailabilityOption, availabilityOptions } from 'src/agentDashboard/constants/availabilityOptions';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

interface AvailabilityDropdownProps {
    options: AvailabilityOption[]
    disabled: boolean
    value: string
    onChangeHandler: any
    dayOfWeek: string
    entryIndex: number
    inputField: string
}

export const ScheduleEntryDropdown = ({
  options,
  disabled,
  value,
  onChangeHandler,
  dayOfWeek,
  entryIndex,
  inputField,
}: AvailabilityDropdownProps) => {
  const renderedValue = React.useMemo(() => availabilityOptions.find((option) => option.label === value), [options, value])

  return (
    <Autocomplete
      disabled={disabled}
      disableClearable
      disablePortal
      options={options}
      sx={{ width: 125, boxShadow: 0 }}
      value={renderedValue}
      onChange={(e, value) => onChangeHandler(value, dayOfWeek, entryIndex, inputField)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              fontSize: 14, fontFamily: 'DM Sans', py: 0.5,
            },
          }}
        />
      )}
    />
  )
}
