import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LatLng } from 'use-places-autocomplete';
import { AddressDetails } from 'src/renterDashboard/types/AddressDetails.type';
import { AdditionalFilters } from 'src/renterDashboard/types/AdditionalFilters.type';

export interface InitialSearchState {
    address: string
    addressCoordinates: LatLng | {}
    addressDetails: AddressDetails
    bedrooms: string
    bathrooms: string
    minPrice: string
    maxPrice: string
    additionalFilters: AdditionalFilters
}

const initialState: InitialSearchState = {
  address: '',
  addressCoordinates: {},
  addressDetails: {},
  bedrooms: '',
  bathrooms: '',
  minPrice: '',
  maxPrice: '',
  additionalFilters: {
    hasABasement: false,
    hasAGarage: false,
    lotSize: {
      min: 'any',
      max: 'any',
    },
    squareFootage: {
      min: 'any',
      max: 'any',
    },
    yearBuilt: {
      min: 'any',
      max: 'any',
    },
    numberOfStories: 'any',
    parkingSpots: 'any',
  },
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload
    },
    setAddressCoordinates: (state, action: PayloadAction<LatLng | {}>) => {
      state.addressCoordinates = action.payload
    },
    setAddressDetails: (state, action: PayloadAction<AddressDetails>) => {
      state.addressDetails = action.payload
    },
    setBedrooms: (state, action: PayloadAction<string>) => {
      state.bedrooms = action.payload
    },
    setBathrooms: (state, action: PayloadAction<string>) => {
      state.bathrooms = action.payload
    },
    setMinPrice: (state, action: PayloadAction<string>) => {
      state.minPrice = action.payload
    },
    setMaxPrice: (state, action: PayloadAction<string>) => {
      state.maxPrice = action.payload
    },
    setAdditionalFilters: (state, action: PayloadAction<AdditionalFilters>) => {
      state.additionalFilters = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setAddress,
  setAddressCoordinates,
  setAddressDetails,
  setBedrooms,
  setBathrooms,
  setMinPrice,
  setMaxPrice,
  setAdditionalFilters,
  reset,
} = searchSlice.actions

export default searchSlice.reducer
