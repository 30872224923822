export interface Schedule {
  startTime: string
  startTimeNum: number | null
  endTime: string
  endTimeNum: number | null
  startTimeLabel: string
  endTimeLabel: string
}

export interface DaySection {
    label: string
    name: string
    hasUnfinished: boolean
    isActive: boolean
    schedule: Schedule[]
}

export interface AgentSchedule {
    [x: string]: DaySection
}

export const agentSchedule: AgentSchedule = {
  monday: {
    label: 'Monday',
    name: 'monday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  tuesday: {
    label: 'Tuesday',
    name: 'tuesday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  wednesday: {
    label: 'Wednesday',
    name: 'wednesday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  thursday: {
    label: 'Thursday',
    name: 'thursday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  friday: {
    label: 'Friday',
    name: 'friday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  saturday: {
    label: 'Saturday',
    name: 'saturday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
  sunday: {
    label: 'Sunday',
    name: 'sunday',
    hasUnfinished: false,
    isActive: false,
    schedule: [],
  },
}
