import _ from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/app/store';
import {
  Start,
  StartBlockk,
  StartHeading,
  StartP,
  StartText,
} from 'src/app/styles/Start.style'
import Width from 'src/app/components/Width'
import { useAppDispatch } from 'src/app/hooks'
import { setSignUpModalOpen } from 'src/app/reducers/modalSlice'
import { Button } from '../styles/Button.style'

interface IProps {
  color: 'purple' | 'green' | 'orange'
  bg: string
  alt?: boolean
}

interface HomeProps {
  isSignUpModalOpen: boolean
}

export default function StartBlock({ color, bg, alt }:IProps, { isSignUpModalOpen }: HomeProps) {
  const dispatch = useAppDispatch()
  const cognitoUser = useSelector((state: RootState) => state.user.cognitoUser)

  const toggleSignUpModal = (e:any) => {
    e.preventDefault()
    dispatch(setSignUpModalOpen(!isSignUpModalOpen))
  }

  const navigate = useNavigate();

  if (alt) {
    return (
      <Start>
        <Width>
          <StartBlockk className={color} bg={bg}>
            <StartText alt={alt}>
              <StartHeading>We Believe<br /> Everyone Deserves<br /> Their Dream Home</StartHeading>
              <Button onClick={(e: any) => toggleSignUpModal(e)}>Get Started</Button>
            </StartText>
          </StartBlockk>
        </Width>
      </Start>
    )
  }

  return (
    <Start>
      <Width>
        <StartBlockk className={color} bg={bg}>
          <StartText>
            <StartHeading>Get The Home <br /> Of Your Dreams</StartHeading>
            <StartP>Rent your dream home and build equity from the home’s price appreciation.</StartP>
            {_.isEmpty(cognitoUser) && <Button onClick={(e: any) => toggleSignUpModal(e)}>Get Started</Button>}
            {!_.isEmpty(cognitoUser) && <Button onClick={() => navigate('/home/renter')}>Browse Homes</Button>}
          </StartText>
        </StartBlockk>
      </Width>
    </Start>
  )
}
