import Amplify, { Auth } from 'aws-amplify';

export const getAmplifyConfig = () => Amplify.configure({
  Auth: {
    region: 'us-west-1',
    userPoolId: 'us-west-1_EUEugWAMH',
    userPoolWebClientId: '11339c1u3mirsvmaqp854nisi3',
    mandatorySignIn: true,
    oauth: {},
  },
})
