import styled from 'styled-components'

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    .MuiFormGroup-root {
      width: 100%;
    }

    .MuiFormControl-root {
      flex: 1;
    }
`

export const LinkedSelectContainer = styled.div`
    display: flex;
    align-items: center;
`

export const customOverlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  zIndex: 10,
}

export const customFilterOverlayStyles = {
  position: 'absolute',
  width: 'calc(100% - 40px)',
  maxWidth: '1510px',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  zIndex: 10,
}

export const customContentStyles = {
  position: 'absolute',
  padding: 0,
  margin: 0,
  top: '191px',
  left: '71.2%',
  right: 0,
  bottom: 0,
  border: '1px solid rgba(188, 185, 180, 0.4)',
  boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px',
}

export const customFilterContentStyles = {
  position: 'absolute',
  padding: 0,
  margin: 0,
  top: '168px',
  left: 'auto',
  right: 0,
  bottom: 0,
  border: '1px solid rgba(188, 185, 180, 0.4)',
  boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px',
}
