import React from 'react'
import Width from 'src/app/components/Width'
import {
  Split,
  SplitBlockk,
  SplitBlockLeft,
  SplitBlockRight,
  SplitBlockRightHeading,
  SplitBlockRightP,
  SplitBlockRightSource,
} from '../styles/WhoWeAreView.style'

interface IProps {
  heading: string
  image: React.ReactNode
  children: React.ReactNode
  source: string
  color?: 'transparent' | 'orange'
  reverse?: boolean
  className?: string
}

export default function SplitBlock({
  heading,
  image,
  children,
  source,
  color = 'transparent',
  reverse,
  className = '',
}:IProps) {
  return (
    <Split>
      <Width>
        <SplitBlockk className={`${color} ${reverse ? 'reverse' : ''} ${className}`}>
          <SplitBlockLeft>
            {image}
          </SplitBlockLeft>
          <SplitBlockRight>
            <SplitBlockRightHeading>{heading}</SplitBlockRightHeading>
            <SplitBlockRightP>{children}</SplitBlockRightP>
            <SplitBlockRightSource>{source}</SplitBlockRightSource>
          </SplitBlockRight>
        </SplitBlockk>
      </Width>
    </Split>
  )
}
