import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Hero from 'src/home/assets/Hero.webp'
import { AppColors } from 'src/app/constants/appColors'
import Hand from '../assets/Hand.svg'

export const HeroContainer = styled.div`
    background-image: url(${Hero});
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: bottom center;

    height: 100vh;
    min-height: 740px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 200px;
        background: linear-gradient(0deg,rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 90%);
        top: 0;
        left: 0;
    }
`

export const HeroHeader = styled.h1`
    font-family: 'DM Serif Display', serif;
    font-size: 6rem;
    color: ${AppColors.BLACK};
    margin-bottom: 10px;

    @media (min-width: 1040px) {
        font-size: 8rem;
    }

`

export const HeroSubHeader = styled.h2`
    font-family: 'DM Sans', sans-serif;
    font-size: 2.4rem;
    color: ${AppColors.BLACK};
    letter-spacing: 0.1rem;
    margin-top: 10px;
    max-width: 500px;

    @media (min-width: 1040px) {
        font-size: 3rem;
    }
`

export const HeroButton = styled.button`
    font-family: 'DM Serif Display', serif;
    color: ${AppColors.WHITE};
    background-color: ${AppColors.BLACK};
    padding: 0 32px;
    border-radius: 60px;
    font-size: 1.8rem;
    margin-top: 25px;
    line-height: 54px;
    transition: 0.2s opacity;
    display: inline-block;

    &:hover{
        opacity: 0.8;
    }
`

export const How = styled.div`
    padding: 60px 0;

    @media (min-width: 1040px) {
        padding: 100px 0;
    }
`

export const HowHeading = styled.h2`
    text-align: center;
    color: ${AppColors.BLACK};
    font-size: 48px;
    margin-bottom: 40px;

    @media (min-width: 1040px) {
        font-size: 64px;
        margin-bottom: 100px;
    }
`

export const HowBlocks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (min-width: 1040px) {
        flex-direction: row;
        gap: 40px;
    }


    @media (min-width: 1200px) {
        gap: 100px;
    }
`

export const HowBlock = styled.div`
    position: relative;
    max-width: 440px;
    margin: 0 auto;
    
    @media (min-width: 1040px) {
        flex: 1;
        max-width: none;
        margin: 0;
    }
`

export const HowBlockImage = styled.div`
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HowBlockImg = styled.img`
`

export const HowBlockHeading = styled.h3<{width?: number}>`
    font-size: 32px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
    display: inline-block;

    @media (min-width: 1040px) {
        font-size: 40px;
    }
`

export const HowBlockP = styled.p`
    font-size: 16px;

    @media (min-width: 1040px) {
        font-size: 18px;
    }
`

export const Make = styled.div`
    padding: 60px 0;

    overflow: hidden;
`

export const HomeBlock = styled.div<{image: string}>`
    background: rgba(205, 222, 224, 0.5);
    border-radius: 30px;
    background-image: url(${(props) => props.image});
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 700px;
    background-size: 400px;
    padding: 60px 40px;
    margin-bottom: 40px;

    @media (min-width: 1040px) {
        display: flex;
        padding: 60px;
        height: 600px;
        align-items: center;
        background-position: right bottom;
        background-size: 500px;
    }

    @media (min-width: 1200px) {
        padding: 96px;
        background-size: 700px;
    }

    &.reverse {
        @media (min-width: 1040px) {
            background-position: left bottom;
            flex-direction: row-reverse;
        }
    }

    &.orange {
        background-color: ${AppColors.FADED_ORANGE};
    }

    &.purple {
        background-color: ${AppColors.FADED_PURPLE};
    }
`

export const HomeBlockLeft = styled.div`
    max-width: 450px;
    margin: 0 auto;

    @media (min-width: 1040px) {
        width: 50%;
        margin: 0;
    }
`

export const HomeBlockLeftHeading = styled.h2`
    font-size: 3.6rem;
    margin-bottom: 15px;

    @media (min-width: 1040px) {
        font-size: 4.8rem;
    }
`

export const HomeBlockLeftP = styled.p`
font-size: 18px;
`

export const Featured = styled.div`
    margin: 60px 0;

    @media (min-width: 1040px) {
        margin: 100px 0;     
    }
`

export const FeaturedHeading = styled.h2`
    text-align: center;
    font-size: 26px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 50px;
    font-weight: bold;

`

export const FeaturedBlocks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 760px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

export const FeaturedBlock = styled(Link)`
    background: ${AppColors.WHITE};
    border-radius: 30px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    transition: 0.2s transform;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 760px) {
        max-width: none;
        margin: 0;
    }



    &:hover {
        transform: scale(1.02);
    }
`

export const FeaturedBlockImage = styled.span`
    border-radius: 30px;
    overflow: hidden;
    display: block;
    position: relative;
`

export const FeaturedBlockImg = styled.img`
    height: 480px;
    width: 100%;
    object-fit: cover;
`

export const FeaturedBlockPrice = styled.span`
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
    line-height: 40px;
    color: white;
    padding: 0 24px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 18px;
`

export const FeaturedBlockText = styled.span`
    padding: 24px;
    display: block;
    color: ${AppColors.BLACK};
    font-size: 1.8rem;
`

export const FeaturedBlockTitle = styled.h3`
    font-family: 'DM Sans', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.6;
`

export const FeaturedBlockAddress = styled.address`
    font-style: normal;
    margin: 2px 0;
`

export const FeaturedBlockDetails = styled.ul`
    display: inline-flex;
`

export const FeaturedBlockDetail = styled.li`
    &:after {
        content: '|';
        color: rgba(0,0,0,0.3);
        margin: 0 10px;
    }

    &:last-of-type:after {
        display: none;
    }
`

export const MoreHomes = styled(Link)`
    border-radius: 30px;
    background: ${AppColors.WHITE};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: ${AppColors.BLACK};
    text-align: center;
    font-weight: bold;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    transition: 0.2s transform;
    min-height: 400px;

    &:hover {
        transform: scale(1.02);
    }
`
