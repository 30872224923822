export interface PropertyParkingSpots {
    value: string
    label: string
}

export const propertyParkingSpots: PropertyParkingSpots[] = [
  {
    value: '1',
    label: '1+',
  },
  {
    value: '2',
    label: '2+',
  },
  {
    value: '3',
    label: '3+',
  },
  {
    value: '4',
    label: '4+',
  },
]
