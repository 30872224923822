import React from 'react'
import { QuestionOne } from 'src/listing/components/modals/PrequalApplicationForm/QuestionOne';
import { QuestionTwo } from 'src/listing/components/modals/PrequalApplicationForm/QuestionTwo';
import { QuestionThree } from 'src/listing/components/modals/PrequalApplicationForm/QuestionThree';
import { ApplicationAnswerType } from 'src/listing/types/applicationAnswers.type';
import { PrequalFormContainer } from 'src/listing/styles/PrequalApplication.style';

interface PrequalApplicationProps {
    selectedAnswers: ApplicationAnswerType
    setSelectedAnswers: (any) => void
}

export const PrequalApplication = ({
  selectedAnswers,
  setSelectedAnswers,
}: PrequalApplicationProps) => (
  <PrequalFormContainer>
    <QuestionOne selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} />
    <QuestionTwo selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} />
  </PrequalFormContainer>
)
