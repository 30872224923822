import React from 'react';
import { ScheduleEntryDropdownCombo } from 'src/agentDashboard/components/ScheduleEntryDropdownCombo';
import { ScheduleEntryIcons } from 'src/agentDashboard/components/ScheduleEntryIcons';
import { ScheduleEntryContainer } from 'src/agentDashboard/styles/AgentAvailabilityModal.style';
import { availabilityOptions } from 'src/agentDashboard/constants/availabilityOptions';
import { AgentSchedule, DaySection, Schedule } from 'src/agentDashboard/constants/agentSchedule'
import { isLastEntryOfDay } from 'src/agentDashboard/utils/isLastEntryOfDay'
import { isValidHourlyOption } from 'src/agentDashboard/utils/isValidHourlyOption';

interface ScheduleEntryRowProps {
    scheduleEntry: Schedule
    schedule: Schedule[]
    entryIndex: number
    daySection: DaySection
    pendingAgentSchedule: AgentSchedule
    setPendingAgentSchedule: any
    hasUnfinished: boolean
    onChangeHandler: any
    dayOfWeek: string
}

export const ScheduleEntryRow = ({
  scheduleEntry,
  schedule,
  entryIndex,
  daySection,
  pendingAgentSchedule,
  setPendingAgentSchedule,
  hasUnfinished,
  onChangeHandler,
  dayOfWeek,
}: ScheduleEntryRowProps) => {
  const isOptionInvalidStartTime = (schedule, option, currentIndex) => schedule.some((scheduleEntry, index) => {
    if (option.num > 1600) return true
    if (scheduleEntry.startTimeNum && index !== currentIndex) {
      return (scheduleEntry.startTimeNum - 200) < option.num && (scheduleEntry.endTimeNum + 200) > option.num
    }
    return false
  })

  const isOptionInvalidEndTime = (schedule, option, currentIndex) => schedule.some((scheduleEntry, index) => {
    const startTime = scheduleEntry.startTimeNum
    if (index === currentIndex) {
      return scheduleEntry.startTimeNum >= option.num || !isValidHourlyOption(scheduleEntry, option)
    }
    if (startTime) {
      const startTimeEarliest = scheduleEntry.startTimeNum - 200
      const endTimeLatest = scheduleEntry.endTimeNum + 200
      return startTimeEarliest < option.num && endTimeLatest > option.num
    }
    return false
  })

  const getStartOptions = (schedule, currentIndex) => availabilityOptions.filter((option) => !isOptionInvalidStartTime(schedule, option, currentIndex))

  const getEndOptions = (schedule, currentIndex) => availabilityOptions.filter((option) => !isOptionInvalidEndTime(schedule, option, currentIndex))

  return (
    <ScheduleEntryContainer key={JSON.stringify(scheduleEntry)}>
      <ScheduleEntryDropdownCombo
        scheduleEntry={scheduleEntry}
        isLastEntry={isLastEntryOfDay(schedule, entryIndex)}
        startOptions={getStartOptions(schedule, entryIndex)}
        endOptions={getEndOptions(schedule, entryIndex)}
        onChangeHandler={onChangeHandler}
        entryIndex={entryIndex}
        dayOfWeek={dayOfWeek}
      />
      {isLastEntryOfDay(schedule, entryIndex) && (
        <ScheduleEntryIcons
          hasUnfinished={hasUnfinished}
          isOnlyDayEntry={schedule.length === 1}
          pendingAgentSchedule={pendingAgentSchedule}
          daySection={daySection}
          setPendingAgentSchedule={setPendingAgentSchedule}
        />
      )}
    </ScheduleEntryContainer>
  )
}
