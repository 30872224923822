import React from 'react'
import { ErrorBanner } from 'src/app/styles/ErrorBanner.style'
import {
  ModalPrimaryButton,
  ModalForm,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { PublishModalPayload, setPublishModalOpen } from 'src/app/reducers/modalSlice';
import { Modal } from 'src/app/components/Modal';
import { useAppDispatch } from 'src/app/hooks';
import { connect } from 'react-redux';
import {
  ListingInfoContainer,
  PropertyInfoContainer,
  DescriptionContainer,
  PropertyInfoColumn,
  SectionLabel,
  RowLabel,
  RowValue,
  RowLabelValueContainer,
  DescriptionText, ColumnsContainer,
} from 'src/agentDashboard/styles/PublishModal.style'
import { rentCalcHelper } from 'src/app/utils/rentCalcHelper';
import { publishModalRowConfig } from 'src/agentDashboard/utils/publishModalRowConfig';
import { RentInfoRow } from 'src/agentDashboard/components/RentInfoRow';
import { patchListing } from 'src/agentDashboard/actions/patchListing';

interface PublishProps {
    publishModalOpen: PublishModalPayload
    accessToken: string
}

export const PublishModal = ({ publishModalOpen, accessToken }: PublishProps) => {
  const dispatch = useAppDispatch()
  const [error, setError] = React.useState('')
  const [pendingStatus, setPendingStatus] = React.useState<boolean>(false)
  const [editRent, setEditRent] = React.useState(false)
  const [updatedRent, setUpdatedRent] = React.useState(0)

  React.useEffect(() => {
    setPendingStatus(!!publishModalOpen.listing.is_draft)
    setUpdatedRent(publishModalOpen.listing.rent_price || 0)
  }, [publishModalOpen.isOpen])

  const onClickRent = () => {
    if (editRent) setEditRent(false)
    else setEditRent(true)
  }

  const triggerPublishModal = () => dispatch(setPublishModalOpen({
    listing: {},
    currentListingStatus: false,
    isOpen: false,
  }))

  const confirmPublishSubmit = async () => {
    await patchListing(updatedRent, pendingStatus, setEditRent, setError, publishModalOpen.listing, accessToken, dispatch)
    triggerPublishModal()
    setError('')
  }

  const isMonthlyRentWarning = React.useMemo(() => publishModalOpen.listing.rent_price
      && !(
        publishModalOpen.listing.rent_price
        === updatedRent
      ), [updatedRent, publishModalOpen.listing.rent_price])

  const infoColumnRowConfig = publishModalRowConfig(publishModalOpen.listing, isMonthlyRentWarning)

  const leftColumnDetails = React.useMemo(() => infoColumnRowConfig.slice(0, 8), [publishModalOpen.listing])
  const rightColumnDetails = React.useMemo(() => infoColumnRowConfig.slice(8, infoColumnRowConfig.length), [publishModalOpen.listing])

  return (
    <Modal
      onClose={triggerPublishModal}
      height="65rem"
      width="70rem"
      isOpen={publishModalOpen.isOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={triggerPublishModal}>X</ModalCloseButton>
        <ModalOuterContainer>
          {error && <ErrorBanner><h2>{error}</h2></ErrorBanner>}
          <ModalForm onSubmit={confirmPublishSubmit}>
            <ListingInfoContainer>
              <PropertyInfoContainer>
                <SectionLabel>Property Info</SectionLabel>
                <ColumnsContainer>
                  <PropertyInfoColumn>
                    {leftColumnDetails.map((info, i) => (
                      <RowLabelValueContainer topBorder={i !== 0} key={info.label}>
                        <RowLabel>{info.label}</RowLabel>
                        <RowValue>{info.value}</RowValue>
                      </RowLabelValueContainer>
                    ))}
                  </PropertyInfoColumn>
                  <PropertyInfoColumn>
                    {rightColumnDetails.map((info, i) => (
                      <RowLabelValueContainer topBorder={i !== 0} key={info.label}>
                        <RowLabel>{info.label}</RowLabel>
                        {info.label === 'Monthly Rent'
                          ? (
                            <RentInfoRow
                              editRent={editRent}
                              warning={!!isMonthlyRentWarning}
                              onClickRent={onClickRent}
                              info={info}
                              setUpdatedRent={setUpdatedRent}
                              updatedRent={updatedRent}
                            />
                          )
                          : (
                            <RowValue
                              color={info.label === 'MLS Status' ? pendingStatus ? '#EA4335' : '#34A853' : info.color}
                              isStatusRow={info.label === 'MLS Status'}
                              onClick={info.label === 'MLS Status'
                                ? () => setPendingStatus(!pendingStatus)
                                : null}
                            >
                              {info.label === 'MLS Status' ? pendingStatus ? 'Unpublished' : 'Published' : info.value}
                            </RowValue>
                          )}
                      </RowLabelValueContainer>
                    ))}
                  </PropertyInfoColumn>
                </ColumnsContainer>
              </PropertyInfoContainer>
              <DescriptionContainer>
                <SectionLabel>Description</SectionLabel>
                <DescriptionText>{publishModalOpen.listing.description || '-'}</DescriptionText>
              </DescriptionContainer>
            </ListingInfoContainer>
            <ModalPrimaryButton type="submit">Confirm Update</ModalPrimaryButton>
          </ModalForm>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
})

export default connect(mapStateToProps)(PublishModal)
