import React from 'react'
import {
  PropertyImagesContainer,
  PropertyHeadlineImage,
  PropertyImageRow,
  ThumbnailContainer,
  PropertyImageThumbnail,
  ThumbnailOverlay, HeadlineImageBtn,
} from 'src/listing/styles/PropertyImages.style';
import { useLightgallery } from 'react-lightgallery';

interface PropertyImagesProps {
  images: string[]
}

export const PropertyImages = ({ images }: PropertyImagesProps) => {
  const { openGallery } = useLightgallery();

  const onClickGalleryHandler = (index) => {
    openGallery('listing', index)
  }

  return (
    <PropertyImagesContainer>
      <HeadlineImageBtn onClick={() => onClickGalleryHandler(0)} type="button">
        <PropertyHeadlineImage src={images[0]} alt="Headline image" />
      </HeadlineImageBtn>
      <PropertyImageRow>
        {images.slice(1, 6).map((image, index) => (
          <ThumbnailContainer
            key={image}
            lastImage={index === 4}
            onClick={() => onClickGalleryHandler(index + 1)}
          >
            <PropertyImageThumbnail src={image} alt={`House ${index + 1}`} />
            {index === 4 && (
            <ThumbnailOverlay>
              <h4>More<br />Photos</h4>
            </ThumbnailOverlay>
            )}
          </ThumbnailContainer>
        ))}
      </PropertyImageRow>
    </PropertyImagesContainer>
  )
}
