import React from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'src/app/hooks'
import { ErrorBanner } from 'src/app/styles/ErrorBanner.style'
import {
  ModalPrimaryButton,
  ModalForm,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { Modal } from 'src/app/components/Modal';
import { setAgentAvailabilityModalOpen } from 'src/app/reducers/modalSlice';
import { AgentSchedule } from 'src/agentDashboard/constants/agentSchedule';
import { isFormChanged } from 'src/app/utils/isFormChanged';
import { ScheduleDayRow } from 'src/agentDashboard/components/ScheduleDayRow';
import { putAgentAvailability } from 'src/agentDashboard/actions/putAgentAvailability';

interface AgentAvailabilityProps {
  isAgentAvailabilityModalOpen: boolean
  agentSchedule: AgentSchedule
  agentId: string
  accessToken: string
}

export const AgentAvailabilityModal = ({
  isAgentAvailabilityModalOpen,
  agentSchedule,
  agentId,
  accessToken,
}: AgentAvailabilityProps) => {
  const [pendingAgentSchedule, setPendingAgentSchedule] = React.useState<AgentSchedule>(agentSchedule)
  const [error, setError] = React.useState('')
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setPendingAgentSchedule(agentSchedule)
  }, [agentSchedule])

  const triggerAgentAvailabilityModal = () => dispatch(setAgentAvailabilityModalOpen(false))

  const availabilitySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(putAgentAvailability(agentId, accessToken, pendingAgentSchedule))
  }

  const formChanged = React.useMemo(
    () => isFormChanged(agentSchedule, pendingAgentSchedule),
    [agentSchedule, pendingAgentSchedule],
  )

  return (
    <Modal onClose={triggerAgentAvailabilityModal} height="54rem" width="60rem" isOpen={isAgentAvailabilityModalOpen}>
      <ModalInnerContainer>
        <ModalCloseButton type="button" onClick={triggerAgentAvailabilityModal}>X</ModalCloseButton>
        <ModalOuterContainer>
          {error && <ErrorBanner><h2>{error}</h2></ErrorBanner>}
          <ModalForm onSubmit={(e: any) => availabilitySubmit(e)}>
            {Object.keys(pendingAgentSchedule).map((section) => (
              <ScheduleDayRow
                key={section}
                pendingAgentSchedule={pendingAgentSchedule}
                daySection={pendingAgentSchedule[section]}
                setPendingAgentSchedule={setPendingAgentSchedule}
                dayOfWeek={section}
              />
            ))}
            <br />
            <br />
            <ModalPrimaryButton disabled={!formChanged} type="submit">Set Availability</ModalPrimaryButton>
          </ModalForm>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  agentSchedule: state.agentSchedule.agentSchedule,
  isAgentAvailabilityModalOpen: state.modal.isAgentAvailabilityModalOpen,
  agentId: state.user.userId,
  accessToken: state.user.accessToken,
})

export default connect(mapStateToProps)(AgentAvailabilityModal)
