import { AppColors } from 'src/app/constants/appColors'
import styled from 'styled-components'

export const WhoTop = styled.div`
  padding-top: 20px;

  @media (min-width: 1040px) {
    margin-top: 40px;
  }
`

export const WhoTopFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1040px) {
    align-items: center;
    position: relative;
    flex-direction: row;
    height: 640px;
  }
`

export const WhoTopFlexLeft = styled.div`
  max-width: 600px;
  position: relative;
  z-index: 2;
`

export const WhoTopHeading = styled.h1`
  font-size: 5.5rem;
  margin-bottom: 20px;

  @media (min-width: 1040px) {
    font-size: 8rem;
  }
`

export const WhoTopP = styled.p`
  font-size: 2.1rem;
  margin-bottom: 20px;

  @media (min-width: 1040px) {
    font-size: 3rem;
  }
`

export const WhoTopImg = styled.img`
  border-radius: 30px;

  @media (min-width: 1040px) {
    position: absolute;
    right:0;
    top: 0;
    width: auto;
    height: 100%;
    max-width: 80%;
    object-fit: cover;
  }

  @media (min-width: 1100px) {
    max-width: none; 
  }
`

export const Wealth = styled.div`
  margin: 60px 0;

  @media (min-width: 1040px) {
    margin: 100px 0;
  }

`

export const WealthHeading = styled.h2`
  font-size: 4.8rem;
  text-align: center;
  margin-bottom: 100px;

  @media (min-width: 1040px) {
    font-size: 6.4rem;
  }
`

export const WealthSubHeading = styled.h3`
  font-size: 2.5rem;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (min-width: 1040px) {
    font-size: 3.33rem;
  }
`

export const WealthFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;

  @media (min-width: 1040px) {
    gap: 30px;
    flex-direction: row;
  }
`

export const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;

  @media (min-width: 1040px) {
    gap: 30px;
  }
`

export const WealthBlockC = styled.div`
  background: ${AppColors.FADED_BLUE};
  border-radius: 30px;
  text-align: center;
  padding: 0 60px 60px;

  @media (min-width: 1040px) {
    width: 50%;
  }

  &.orange {
    background: ${AppColors.FADED_ORANGE};
  }
`

export const WealthBlockD = styled.div`
  background: ${AppColors.FADED_BLUE};
  border-radius: 30px;
  text-align: center;
  padding: 0 60px 60px;

  @media (min-width: 1040px) {
    width: 80%;
  }

  &.orange {
    background: ${AppColors.FADED_ORANGE};
  }
`

export const WealthBlockImg = styled.img`
  margin: -60px auto 0;
  border-radius: 30px;
`

export const WealthBlockHeading = styled.h3`
  max-width: 490px;
  margin: 30px auto 15px;
  font-size: 4rem;
`

export const WealthBlockHeadingPadded = styled.h3`
  max-width: 628px;
  margin: 30px auto 15px;
  font-size: 4rem;
  @media (min-width: 1040px) {
    padding-top: 50px;
  }
`

export const WealthBlockDesc = styled.p`
  max-width: 490px;
  margin: 0 auto 20px;
  font-size: 1.8rem;
`

export const WealthBlockDescPadded = styled.p`
  max-width: 628px;
  margin: 0 auto 20px;
  font-size: 1.8rem;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (min-width: 1040px) {
    padding-top: 100px;
    padding-bottom: 70px;
  }
`

export const WealthBlockSource = styled.p`
  max-width: 490px;
  margin: 0 auto;
  font-size: 1.6rem;
  opacity: 0.5;
`

export const ChallengesHeading = styled.h2`
  font-size: 4.8rem;
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: 1040px) {
    font-size: 6.4rem;
    margin-bottom: 20px;
  }
`

export const Renting = styled.div`
  margin-bottom: 60px;

  @media (min-width: 1040px) {
    margin-bottom: 100px;
  }
`

export const RentingHeading = styled.h2`
  font-size: 4.8rem;
  text-align: center;

  @media (min-width: 1040px) {
    font-size: 6.4rem;
    margin-bottom: 100px;

  }
`

export const RentingFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1040px) {
    flex-direction: row;
    align-items: center;
    gap: 40px;
  }

  @media (min-width: 1100px) {
    gap: 0;
  }
`

export const RentingFlexLeft = styled.div`
  @media (min-width: 1040px) {
    width: 50%;
  }
`

export const RentingFlexLeftHeading = styled.h3`
  font-size: 3.2rem;
  max-width: 550px;

  @media (min-width: 1040px) {
    font-size: 4rem;
  }
`

export const RentingFlexLeftP = styled.p`
  font-size: 1.8rem;
  margin: 10px 0 10px;
  max-width: 550px;
`

export const RentingFlexLeftSource = styled.p`
  font-size: 1.6rem;
  opacity: 0.5;
`

export const RentingFlexRight = styled.div`
  order: -1;

  @media (min-width: 1040px) {
    width: 50%;
    order: initial;
  }
`

export const Split = styled.div`
  @media (min-width: 1040px) {
  }
`

export const SplitBlockk = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  border-radius: 30px;

  @media (min-width: 1040px) {
    flex-direction: row;
    align-items: center;
    padding: 100px 30px;
    gap: 40px;
  }

  @media (min-width: 1100px) {
    padding: 140px 60px;
    gap: 80px;
  }

  &.reverse {
    @media (min-width: 1040px) {
      flex-direction: row-reverse;
    }
  }

  &.orange {
    background: ${AppColors.FADED_ORANGE};
  }
`

export const SplitBlockLeft = styled.div`
  max-width: 400px;
  margin: 0 auto;

  @media (min-width: 1040px) {
    width: 50%;
    max-width: none;
    margin: 0;
  }

  .more & {
    @media (min-width: 1040px) {
      margin-top: -160px;
    }
  }
`

export const SplitBlockRight = styled.div`
  @media (min-width: 1040px) {
    width: 50%;
  }
`

export const SplitBlockRightHeading = styled.h3`
  font-size: 3.2rem;

  @media (min-width: 1040px) {
    font-size: 4rem;
  }
`

export const SplitBlockRightP = styled.p`
  font-size: 1.8rem;
  margin: 10px 0;

  @media (min-width: 1040px) {
  }
`

export const SplitBlockRightSource = styled.p`
  font-size: 1.6rem;
  opacity: 0.5;

  @media (min-width: 1040px) {
  }
`

export const FounderQuote = styled.p`
  max-width: 628px;
  margin: 0 auto 20px;
  font-size: 1.8rem;
  padding-top: 20px;
`

export const FounderQuoteBlock = styled.div`
  background: ${AppColors.FADED_BLUE};
  border-radius: 30px;
  text-align: center;
  margin: auto;
  padding: 50px;

  @media (min-width: 760px) {
    width: 75%;
  }
`

export const FounderQuoteBlockOrange = styled.div`
  background: ${AppColors.FADED_ORANGE};
  border-radius: 30px;
  text-align: center;
  margin: auto;
  padding: 50px;

  @media (min-width: 760px) {
    width: 75%;
  }
`

export const FounderImg = styled.img`
  border-radius: 30px;
  margin: auto;
  @media (min-width: 760px) {
    width: 75%;
  }
`

export const FounderSplitBlockLeft = styled.div`
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: 1040px) {
    width: 50%;
    max-width: none;
    margin: 0;
  }
`

export const FounderSplitBlockRight = styled.div`
  display: flex;
  margin: 0 auto;

  flex-direction: column;
  justify-content: center;
  align-content: center;
  
  @media (min-width: 1040px) {
    width: 50%;
    margin: 0;
  }
`

export const FounderHeading = styled.h1`
  font-size: 4rem;
  margin-bottom: 40px;

  @media (min-width: 1040px) {
    font-size: 6rem;
  }
`
