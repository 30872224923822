export const findDayOfWeek = (day) => {
  switch (day) {
    case 'Mon':
      return 'monday'
    case 'Tue':
      return 'tuesday'
    case 'Wed':
      return 'wednesday'
    case 'Thu':
      return 'thursday'
    case 'Fri':
      return 'friday'
    case 'Sat':
      return 'saturday'
    case 'Sun':
      return 'sunday'
    default:
      return null
  }
}
