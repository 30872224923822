import { IconButton } from 'src/agentDashboard/styles/AgentAvailabilityModal.style';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React from 'react';
import { AgentSchedule, DaySection } from 'src/agentDashboard/constants/agentSchedule';
import _ from 'lodash'

interface ScheduleEntryIconsProps {
  hasUnfinished: boolean
  isOnlyDayEntry: boolean
  pendingAgentSchedule: AgentSchedule
  setPendingAgentSchedule: any
  daySection: DaySection
}

export const ScheduleEntryIcons = ({
  hasUnfinished,
  isOnlyDayEntry,
  pendingAgentSchedule,
  setPendingAgentSchedule,
  daySection,
}: ScheduleEntryIconsProps) => {
  const onAddClick = () => {
    const schedule = _.cloneDeep(pendingAgentSchedule[daySection.name].schedule)
    schedule.push({
      startTime: '',
      startTimeNum: null,
      startTimeLabel: '',
      endTime: '',
      endTimeNum: null,
      endTimeLabel: '',
    })
    setPendingAgentSchedule({
      ...pendingAgentSchedule,
      [daySection.name]: {
        ...pendingAgentSchedule[daySection.name],
        schedule,
        hasUnfinished: true,
      },
    })
  }

  const onDeleteClick = () => {
    const schedule = _.cloneDeep(pendingAgentSchedule[daySection.name].schedule)
    schedule.pop()
    setPendingAgentSchedule({
      ...pendingAgentSchedule,
      [daySection.name]: {
        ...pendingAgentSchedule[daySection.name],
        schedule,
        hasUnfinished: false,
      },
    })
  }
  return (
    <>
      <IconButton marginleft="8px" disabled={hasUnfinished} onClick={() => onAddClick()} type="button">
        <AddRoundedIcon fontSize="large" sx={{ color: '#BCB9B4' }} />
      </IconButton>
      {!isOnlyDayEntry && (
        <IconButton onClick={() => onDeleteClick()} type="button">
          <DeleteRoundedIcon fontSize="large" sx={{ color: '#BCB9B4' }} />
        </IconButton>
      )}
    </>
  )
}
