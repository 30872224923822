import React from 'react'
import { connect } from 'react-redux';
import usePlacesAutocomplete, { getDetails, LatLng } from 'use-places-autocomplete';
import { PropertySearchBar } from 'src/renterDashboard/components/PropertySearchBar'
import { PropertySearchGrid } from 'src/renterDashboard/components/PropertySearchGrid'
import { PropertySearchMap } from 'src/renterDashboard/components/PropertySearchMap'
import { RenterDashboardContainer, RenterDashboardWrap } from 'src/renterDashboard/styles/RenterDashboard.style'
import { Listing } from 'src/listing/types/listing.type';
import { getSearchListings } from 'src/renterDashboard/actions/getSearchListings';
import { useAppDispatch } from 'src/app/hooks';
import { getAddressDetails, selectAutocompleteAddress } from 'src/renterDashboard/utils/selectAutocompleteAddress';
import { reset as setListingReset } from 'src/listing/reducers/listingsSlice';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Footer from 'src/app/components/Footer'
import { AddressDetails } from 'src/renterDashboard/types/AddressDetails.type';
import { InitialSearchState, setAddressDetails, reset as setSearchReset } from 'src/renterDashboard/reducers/searchSlice';
import Width from 'src/app/components/Width';

interface RenterDashboardProps {
    listings: Listing[]
    searchValue: string
    selectedAddressCoordinates: LatLng
    selectedAddressDetails: AddressDetails
    searchFilters: InitialSearchState
    isFilterModalOpen: boolean
}

export const RenterDashboard = ({
  listings,
  searchValue,
  selectedAddressCoordinates,
  selectedAddressDetails,
  searchFilters,
  isFilterModalOpen,
}: RenterDashboardProps) => {
  const [loading, setLoading] = React.useState(false)
  const [totalPages, setTotalPages] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [listingCardOnHover, setListingCardOnHover] = React.useState('')
  const hasNextPage = currentPage !== totalPages
  const dispatch = useAppDispatch()

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // types: ['postal_code', 'street_address'],
      componentRestrictions: {
        country: ['us'],
      },
    },
  });

  const getListings = (currentPg, searchFilterObj = searchFilters, addressDetails = selectedAddressDetails) => {
    dispatch(getSearchListings(
      currentPg,
      setTotalPages,
      setCurrentPage,
      listings,
      addressDetails,
      searchFilterObj,
    ))
  }

  React.useEffect(() => {
    if (searchValue) {
      getListings(1)
      setValue(searchValue, false)
      clearSuggestions()
    } else {
      getListings(1)
    }
  }, [])

  // Cleanup hook to remove stored listings on component unmount / page navigation
  React.useEffect(() => () => {
    dispatch(setListingReset())
    dispatch(setSearchReset())
  }, [])

  const handleSelect = async (address, placeId) => {
    await dispatch(selectAutocompleteAddress(address))
    const details: any = await getDetails({
      placeId,
      fields: ['address_components'],
    })
    const formattedAddressDetails = getAddressDetails(details.address_components)
    dispatch(setAddressDetails(formattedAddressDetails))
    getListings(1, searchFilters, formattedAddressDetails)
    setValue(address, false)
    clearSuggestions()
  };

  return (
    <>
    <Helmet>‍
        <title>Affordable Homes For Rent | HomeSlice</title>‍
        <meta
          name="description"
          content="Find affordable homes for rent in St. Louis, Richmond Heights, Dogtown, Brentwood, Maplewood, Rock Hill,
          Webster Groves, Kirkwood, Glendale, Ballwin, Maryland Heights, Creve Coeur, Olivette, St. Charles and West County.
          HomeSlice makes it easy to find an affordable home for rent. People in St. Louis ask how can I find an affordable home.
          HomeSlice helps you find an affodable home to rent. HomeSlice helps you find a home with a yard in a neighborhood with
          a good school district. Where are affordable homes to rent in St. Louis? HomeSlice will help you find an affordable
          home to rent in St. Louis."
        />
    </Helmet>
    <Width>
      <RenterDashboardWrap>
        <PropertySearchBar
          value={value}
          setValue={setValue}
          status={status}
          data={data}
          handleSelect={handleSelect}
          searchFilters={searchFilters}
          getListings={getListings}
          isFilterModalOpen={isFilterModalOpen}
        />

        <RenterDashboardContainer>
          <PropertySearchMap
            listingCardOnHover={listingCardOnHover}
            listings={listings}
            borderRadius={false}
            noZoomControl={false}
            mapWidth="100%"
            mapHeight="100%"
            containerWidth="52%"
            containerHeight="84vh"
            center={!_.isEmpty(selectedAddressCoordinates) ? selectedAddressCoordinates : { lat: 38.652, lng: -90.3308 }}
            zoom={!_.isEmpty(selectedAddressCoordinates) ? 13 : 10}
          />
          <PropertySearchGrid
            listings={listings}
            loading={loading}
            setLoading={setLoading}
            currentPage={currentPage}
            hasNextPage={hasNextPage}
            getListings={getListings}
            setListingCardOnHover={setListingCardOnHover}
          />
        </RenterDashboardContainer>
      </RenterDashboardWrap>
    </Width>
    <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  listings: state.listings.listings,
  searchFilters: state.search,
  listingsCount: state.listings.totalListingCount,
  searchValue: state.search.address,
  selectedAddressCoordinates: state.search.addressCoordinates,
  selectedAddressDetails: state.search.addressDetails,
  isFilterModalOpen: state.modal.isFilterModalOpen,
})

export default connect(mapStateToProps)(RenterDashboard)
