import React from 'react'
import {
  BecomeBlock,
  BecomeBlockDesc,
  BecomeBlockImage,
  BecomeBlockImg,
  BecomeBlockTitle,
} from 'src/howItWorks/styles/HowItWorksView.style'

interface IProps{
  title: string
  image: string
  children: React.ReactNode
  maxWidth: number
  className?: string
}

export default function BecomeBlockk({
  title,
  image,
  children,
  maxWidth,
  className,
}:IProps) {
  return (
    <BecomeBlock className={className} maxWidth={maxWidth}>
      <BecomeBlockImage>
        <BecomeBlockImg src={image} />
      </BecomeBlockImage>
      <BecomeBlockTitle>{title}</BecomeBlockTitle>
      <BecomeBlockDesc>{children}</BecomeBlockDesc>
    </BecomeBlock>
  )
}
