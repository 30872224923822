import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'src/app/store'
import App from 'src/app/App'
import 'src/index.css'
import 'lightgallery.js/dist/css/lightgallery.css'
import { LightgalleryProvider } from 'react-lightgallery'

const container = window.document.getElementById('root')!
const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <LightgalleryProvider>
        <App />
      </LightgalleryProvider>
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>,
)
