import { setAddress, setAddressCoordinates, setAddressDetails } from 'src/renterDashboard/reducers/searchSlice';
import { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';

export const getAddressDetails = (details) => {
  const detailObj: any = {}
  details.forEach((detail) => {
    if (detail.types.includes('street_number')) detailObj.address = detail.long_name
    if (detail.types.includes('route')) detailObj.address = detailObj.address ? `${detailObj.address} ${detail.long_name}` : detail.long_name
    if (detail.types.includes('locality') && detail.types.includes('political')) detailObj.city = detail.long_name
    if (detail.types.includes('administrative_area_level_1') && detail.types.includes('political')) detailObj.state = detail.short_name
    if (detail.types.includes('postal_code')) detailObj.zipCode = detail.long_name
  })
  return detailObj
}

export const getAddressLatLng = async (address) => {
  const results = await getGeocode({ address });
  return getLatLng(results[0])
}

export const selectAutocompleteAddress = (address) => async (dispatch) => {
  dispatch(setAddress(address))
  const { lat, lng } = await getAddressLatLng(address)
  dispatch(setAddressCoordinates({ lat, lng }))
};
