import React from 'react'
import { ApplicationAnswerType } from 'src/listing/types/applicationAnswers.type';
import {
  AnswerChoice,
  AnswerDivider,
  AnswerInput, AnswerInputContainer,
  QuestionContainer,
  QuestionHeader,
  QuestionSubHeader,
} from 'src/listing/styles/PrequalApplication.style';

interface QuestionTwoProps {
    selectedAnswers: ApplicationAnswerType
    setSelectedAnswers: (any) => void
}

export const QuestionTwo = ({ selectedAnswers, setSelectedAnswers }: QuestionTwoProps) => {
  const onButtonChangeHandler = (e) => {
    const value = e.target.value === 'Yes'
    setSelectedAnswers({
      ...selectedAnswers,
      questionTwo: {
        hasSecondaryApplicant: value,
        secondaryApplicant: {},
      },
    })
  }
  const onChangeHandler = (e) => {
    setSelectedAnswers({
      ...selectedAnswers,
      questionTwo: {
        ...selectedAnswers.questionTwo,
        secondaryApplicant: {
          ...selectedAnswers.questionTwo.secondaryApplicant,
          [e.target.name]: e.target.value,
        },
      },
    })
  }
  const answers = ['Yes', 'No']
  return (
    <QuestionContainer bottomBorder>
      <QuestionHeader>2. Are you applying with another person?</QuestionHeader>
      <QuestionSubHeader>A co-applicant is any person over 18 years old who will be living in your home with you.</QuestionSubHeader>
      <AnswerDivider>
        <input
          type="radio"
          id={answers[0]}
          value={answers[0]}
          checked={!!selectedAnswers.questionTwo.hasSecondaryApplicant}
          onChange={onButtonChangeHandler}
        />
        <AnswerChoice htmlFor={answers[0]}>{answers[0]}</AnswerChoice>
      </AnswerDivider>
      <AnswerDivider>
        <input
          type="radio"
          id={answers[1]}
          value={answers[1]}
          // Default value is falsy (null) so strict check needed
          checked={selectedAnswers.questionTwo.hasSecondaryApplicant === false}
          onChange={onButtonChangeHandler}
        />
        <AnswerChoice htmlFor={answers[1]}>{answers[1]}</AnswerChoice>
      </AnswerDivider>
      {selectedAnswers.questionTwo.hasSecondaryApplicant && (
        <AnswerInputContainer>
          <AnswerInput
            name="name"
            type="text"
            value={selectedAnswers.questionTwo.secondaryApplicant?.name || ''}
            onChange={onChangeHandler}
            placeholder="Co-applicant's Name"
          />
          <AnswerInput
            name="email"
            type="text"
            value={selectedAnswers.questionTwo.secondaryApplicant?.email || ''}
            onChange={onChangeHandler}
            placeholder="Co-applicant's Email"
          />
        </AnswerInputContainer>
      )}
      <br />
      <QuestionSubHeader>We&apos;ll follow up with your co-applicant via email about their part of the application.</QuestionSubHeader>
    </QuestionContainer>
  )
}
