import styled, { css } from 'styled-components'

export const IconButton = styled.button<{ marginLeft?: string }>`
    border: none;
    background-color: transparent;
    cursor: pointer;
    ${({ marginLeft }) => marginLeft && css`margin-left: ${marginLeft};`}
    
    :disabled {
      cursor: not-allowed;
    }
`

export const NoScheduleEntryText = styled.div`
    display: block;
    font-size: 1.5rem;
    color: #BCB9B4;
    margin-top: 23px;
    margin-left: 8px;
    height: 36.13px;
`

export const ScheduleEntryContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 8px;
`

export const ScheduleEntryDropdownDivider = styled.span`
    display: inline-block;
    margin: 0 10px;
    color: #BCB9B4;
    font-size: 1.5rem;
    font-weight: bold;
`

export const DayOfWeekCheckboxContainer = styled.div`
    margin-left: 8px;
    margin-top: 8px;
    width: 25%;
`

export const DayScheduleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 75%;
`

export const DayContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 90%;
`
