import { BASE_URL } from 'src/app/constants/baseUrl'
import { setListings, setTotalListingCount } from 'src/listing/reducers/listingsSlice';
import { Listing } from 'src/listing/types/listing.type';
import { AddressDetails } from 'src/renterDashboard/types/AddressDetails.type';
import { setIsLoading } from 'src/app/reducers/loadingSlice';
import { InitialSearchState } from 'src/renterDashboard/reducers/searchSlice';
import axios from 'axios';

export const getSearchListings = (
  currentPage: number,
  setTotalPages: (num: number) => void,
  setCurrentPage: (num: number) => void,
  listings: Listing[],
  addressDetails: AddressDetails,
  searchFilters: InitialSearchState,
) => (dispatch: any) => {
  let url = BASE_URL
  url = `${BASE_URL}/listing?page=${currentPage}&page_size=10`
  if (addressDetails.address) {
    const {
      address, city, state, zipCode,
    } = addressDetails
    url += `&address=${address}&state=${state}&zip_code=${zipCode}`
  } else if (addressDetails.zipCode) {
    const { zipCode } = addressDetails
    url += `&zip_code=${zipCode}`
  } else if (addressDetails.state && addressDetails.city) {
    const { city, state } = addressDetails
    url += `&city=${city}&state=${state}`
  } else if (addressDetails.state) {
    const { state } = addressDetails
    url += `&state=${state}`
  } else if (addressDetails.city) {
    const { city } = addressDetails
    url += `&city=${city}`
  }

  if (searchFilters.bedrooms && searchFilters.bedrooms !== 'any') url += `&bedrooms=${searchFilters.bedrooms}`
  if (searchFilters.bathrooms && searchFilters.bathrooms !== 'any') url += `&bathrooms=${searchFilters.bathrooms}`
  if (searchFilters.minPrice && searchFilters.minPrice !== 'any') url += `&minimum_price=${searchFilters.minPrice}`
  if (searchFilters.maxPrice && searchFilters.maxPrice !== 'any') url += `&maximum_price=${searchFilters.maxPrice}`

  if (searchFilters.additionalFilters) {
    const {
      hasABasement,
      hasAGarage,
      lotSize,
      squareFootage,
      yearBuilt,
      numberOfStories,
      parkingSpots,
    } = searchFilters.additionalFilters
    if (hasABasement) url += `&basement=${hasABasement}`
    if (hasAGarage) url += `&garage=${hasAGarage}`
    if (lotSize.min && lotSize.min !== 'any') url += `&lot_size_min=${lotSize.min}`
    if (lotSize.max && lotSize.max !== 'any') url += `&lot_size_max=${lotSize.max}`
    if (squareFootage.min && squareFootage.min !== 'any') url += `&sq_ft_min=${squareFootage.min}`
    if (squareFootage.max && squareFootage.max !== 'any') url += `&sq_ft_max=${squareFootage.max}`
    if (yearBuilt.min && yearBuilt.min !== 'any') url += `&year_built_min=${yearBuilt.min}`
    if (yearBuilt.max && yearBuilt.max !== 'any') url += `&year_built_max=${yearBuilt.max}`
    if (numberOfStories && numberOfStories !== 'any') url += `&floors=${numberOfStories}`
    if (parkingSpots && parkingSpots !== 'any') url += `&parking_spots=${parkingSpots}`
  }

  dispatch(setIsLoading(true))
  axios.get(url)
    .then((res) => {
      if (res?.data?.listings) {
        const { listings: newListings, count } = res.data
        const formattedListings = newListings.map((listing) => ({ ...listing }))
        setTotalPages(Math.ceil(count / 10))
        setCurrentPage(currentPage)
        if (currentPage === 1) {
          dispatch(setListings([...formattedListings]))
          dispatch(setTotalListingCount(count))
        } else {
          dispatch(setListings([...listings, ...formattedListings]))
        }
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err.message)
    })
    .finally(() => dispatch(setIsLoading(false)))
}
