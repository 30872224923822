import moment from 'moment'
import { dollarFormatter } from 'src/app/utils/dollarFormatter';

export const publishModalRowConfig = (listing, isMonthlyRentWarning) => [
  { label: 'Address', value: listing.address || '-' },
  { label: 'Name/Unit', value: '-' },
  { label: 'City', value: listing.city || '-' },
  { label: 'State', value: listing.state || '-' },
  { label: 'Zip Code', value: listing.zip_code || '-' },
  { label: 'Bedrooms', value: listing.beds || '-' },
  { label: 'Bathrooms', value: listing.bath || '-' },
  { label: 'Square Ft.', value: `${listing.sq_ft} ft.²` || '-' },
  { label: 'List Price', value: dollarFormatter(listing.list_price) || '-' },
  {
    label: 'Monthly Rent',
    value: dollarFormatter(listing.rent_price) || '-',
    color: isMonthlyRentWarning && '#EA4335',
  },
  {
    label: 'MLS Status',
    value: listing.is_draft ? 'Unpublished' : 'Published',
    color: listing.is_draft ? '#EA4335' : '#34A853',
  },
  { label: 'Listed', value: moment(listing.created_ts).format('M/D/YY') || '-' },
  { label: 'Updated', value: moment(listing.last_updated_ts).format('M/D/YY') || '-' },
  { label: 'MLS ID', value: listing.mls_id || '-' },
]
