import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DayOfWeekCheckboxContainer } from 'src/agentDashboard/styles/AgentAvailabilityModal.style';

interface AvailabilityCheckboxProps {
    checked: boolean
    onChangeHandler: (any) => void
    label: string
    name: string
}

export const AvailabilityCheckbox = ({
  checked,
  onChangeHandler,
  label,
  name,
}: AvailabilityCheckboxProps) => (
  <DayOfWeekCheckboxContainer>
    <FormControlLabel
      label={label}
      sx={{ '& .MuiTypography-root': { fontSize: 13 } }}
      control={(
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
          name={name}
          checked={checked}
          onChange={onChangeHandler}
        />
      )}
    />
  </DayOfWeekCheckboxContainer>
)
