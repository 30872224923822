import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Listing } from 'src/listing/types/listing.type';

export interface PublishModalPayload {
  listing: Listing
  currentListingStatus: boolean
  isOpen: boolean
}

const initialState = {
  isSignUpModalOpen: false,
  isLoginModalOpen: false,
  isAgentAvailabilityModalOpen: false,
  isPublishModalOpen: {
    listing: {},
    currentListingStatus: false,
    isOpen: false,
  },
  isScheduleModalStepOneOpen: false,
  isScheduleModalStepTwoOpen: false,
  isScheduleModalStepThreeOpen: false,
  isScheduleModalStepFourOpen: false,
  isScheduleModalStepFiveOpen: false,
  isFilterModalOpen: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setSignUpModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSignUpModalOpen = action.payload
    },
    setLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload
    },
    setAgentAvailabilityModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAgentAvailabilityModalOpen = action.payload
    },
    setPublishModalOpen: (state, action: PayloadAction<PublishModalPayload>) => {
      state.isPublishModalOpen = action.payload
    },
    setScheduleModalStepOneOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleModalStepOneOpen = action.payload
    },
    setScheduleModalStepTwoOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleModalStepTwoOpen = action.payload
    },
    setScheduleModalStepThreeOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleModalStepThreeOpen = action.payload
    },
    setScheduleModalStepFourOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleModalStepFourOpen = action.payload
    },
    setScheduleModalStepFiveOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleModalStepFiveOpen = action.payload
    },
    setFilterModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterModalOpen = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  setSignUpModalOpen,
  setLoginModalOpen,
  setAgentAvailabilityModalOpen,
  setPublishModalOpen,
  setScheduleModalStepOneOpen,
  setScheduleModalStepTwoOpen,
  setScheduleModalStepThreeOpen,
  setScheduleModalStepFourOpen,
  setScheduleModalStepFiveOpen,
  setFilterModalOpen,
  reset,
} = modalSlice.actions

export default modalSlice.reducer
