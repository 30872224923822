import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

interface AdditionalSelectFilterProps {
    filterValue: string
    onChangeHandler: (any) => void
    options: any[]
}

export const AdditionalSelectFilter = ({
  filterValue, onChangeHandler, options,
}: AdditionalSelectFilterProps) => (
  <FormControl sx={{ m: 1, minWidth: 120 }}>
    <Select value={filterValue} onChange={onChangeHandler} sx={{ '& .MuiSelect-select': { py: 1.5 } }}>
      <MenuItem autoFocus value="any">Any</MenuItem>
      {options.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
    </Select>
  </FormControl>
)
