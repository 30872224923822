import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors'

export const Button = styled.button`
  display: inline-block;
  padding: 0 32px;
  border-radius: 40px;
  background-color: ${AppColors.BLACK};
  color: ${AppColors.WHITE};
  font-family: 'DM Serif Text', serif;
  font-size: 1.8rem;
  line-height: 54px;
  border: 1px solid ${AppColors.BLACK};
  transition: 0.2s opacity, 0.2s transform;

  &.white {
    background-color: ${AppColors.WHITE};
    color: ${AppColors.BLACK};
  }

  &:hover {
    opacity: 0.7;
    transform: scale(1.05);
  }
`
