import React from 'react'
import { Width } from 'src/app/styles/Width.style'

interface IProps {
  children?: React.ReactNode
  fullWidth?: boolean
  statik?: boolean
}

export default function Widthh({ children, fullWidth, statik }:IProps) {
  return (
    <Width fullWidth={fullWidth} statik={statik}>{children}</Width>
  )
}
