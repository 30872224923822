import React from 'react'
import { SignUpForm, signUpInitialState } from 'src/home/types/signUp'
import { InputField } from 'src/app/components/InputField'
import { InputFieldType } from 'src/app/types/inputField.type'
import { ErrorBanner } from 'src/app/styles/ErrorBanner.style'
import {
  ModalHeader,
  ModalSubHeader,
  ModalTagline,
  ModalPrimaryButton,
  ModalAltText,
  ModalAltButton,
  ModalForm,
  ModalAltContainer,
  ModalOuterContainer,
  ModalInnerContainer,
  ModalCloseButton,
} from 'src/app/styles/modalStyles'
import { useAppDispatch } from 'src/app/hooks'
import { postSignUp } from 'src/home/actions/postSignUp'
import { setLoginModalOpen, setSignUpModalOpen } from 'src/app/reducers/modalSlice';
import { Modal } from 'src/app/components/Modal';
import { useNavigate } from 'react-router-dom';

interface SignUpProps {
  isSignUpModalOpen: boolean
}

export const SignUpModal = ({ isSignUpModalOpen }: SignUpProps) => {
  const [signUp, setSignUp] = React.useState<SignUpForm>(signUpInitialState)
  const [error, setError] = React.useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const triggerSignUpModal = (flag: boolean) => dispatch(setSignUpModalOpen(flag))
  const triggerLoginModal = (flag: boolean) => dispatch(setLoginModalOpen(flag))
  const resetSignUp = () => {
    setSignUp(signUpInitialState)
    setError('')
  }
  const switchModals = (e:any) => {
    e.preventDefault()
    triggerSignUpModal(false)
    triggerLoginModal(true)
  }

  const isFormComplete = signUp.email && signUp.password && signUp.firstName && signUp.lastName && signUp.username

  const signUpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormComplete) await postSignUp(signUp, setError, dispatch, resetSignUp, navigate)
  }

  const onChangeHandler = (e: { target: { name: string; value: string; }; }) => {
    setSignUp({ ...signUp, [e.target.name]: e.target.value })
  }

  const inputFields: InputFieldType[] = [
    {
      placeholder: 'First Name',
      value: signUp.firstName,
      name: 'firstName',
    },
    {
      placeholder: 'Last Name',
      value: signUp.lastName,
      name: 'lastName',
    },
    {
      placeholder: 'Email',
      value: signUp.email,
      name: 'email',
    },
    {
      placeholder: 'Username',
      value: signUp.username,
      name: 'username',
    },
    {
      placeholder: 'Password',
      value: signUp.password,
      name: 'password',
      type: 'password',
    },
  ]

  return (
    <Modal
      onClose={() => {
        resetSignUp()
        triggerSignUpModal(false)
      }}
      height="auto"
      width="90%"
      maxWidth="880px"
      isOpen={isSignUpModalOpen}
    >
      <ModalInnerContainer>
        <ModalCloseButton
          type="button"
          onClick={() => {
            triggerSignUpModal(false)
            resetSignUp()
          }}
        >
          <svg width="20" viewBox="0 0 20 20"><path d="M2 2L18 18M18 2L2 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" /></svg>
        </ModalCloseButton>
        <ModalOuterContainer>
          {error && (
            <ErrorBanner>
              <h2>{error}</h2>
            </ErrorBanner>
          )}
          <ModalHeader>Sign Up</ModalHeader>
          <ModalSubHeader>Welcome! Please sign up to continue.</ModalSubHeader>
          <ModalTagline>Create an account to start your pre-qualification application.</ModalTagline>
          <ModalForm onSubmit={(e: any) => signUpSubmit(e)}>
            {inputFields.map((field) => (
              <InputField
                key={field.placeholder}
                placeholder={field.placeholder}
                value={field.value}
                name={field.name}
                onChangeHandler={onChangeHandler}
                type={field.type || 'text'}
              />
            ))}
            <ModalPrimaryButton width="100" disabled={!isFormComplete} type="submit">Sign Up</ModalPrimaryButton>
          </ModalForm>
          <ModalAltContainer>
            <ModalAltText>Already have an account?</ModalAltText>
            <ModalAltButton
              type="button"
              onClick={(e: any) => {
                resetSignUp()
                switchModals(e)
              }}
            >
              Login
            </ModalAltButton>
          </ModalAltContainer>
        </ModalOuterContainer>
      </ModalInnerContainer>
    </Modal>
  )
}
