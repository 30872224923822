import styled from 'styled-components'
import { AppColors } from 'src/app/constants/appColors'

interface IProps {
  color: string
  bg: string
  alt?: boolean
}

export const PageHeaderr = styled.div`
  padding-top: 20px;

  @media (min-width: 1040px) {
    margin-top: 40px;
  }
`

export const PageHeaderFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1040px) {
    align-items: center;
    position: relative;
    flex-direction: row;
    height: 640px;
  }
`

export const PageHeaderFlexLeft = styled.div<{wide?: boolean}>`
  max-width: ${({ wide }) => wide ? '840px' : '600px'};
  position: relative;
  z-index: 2;
`

export const PageHeaderHeading = styled.h1`
  font-size: 5.5rem;
  margin-bottom: 20px;

  @media (min-width: 1040px) {
    font-size: 8rem;
  }
`

export const PageHeaderP = styled.p`
  font-size: 2.1rem;
  margin-bottom: 20px;

  @media (min-width: 1040px) {
    font-size: 3rem;
    max-width: 80%;
  }
`

export const PageHeaderImg = styled.img`
  border-radius: 30px;

  @media (min-width: 1040px) {
    position: absolute;
    right:0;
    top: 0;
    width: auto;
    height: 100%;
    max-width: 80%;
    object-fit: cover;
  }

  @media (min-width: 1100px) {
    max-width: none; 
  }
`
