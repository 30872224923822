import styled from 'styled-components';
import { AppColors } from 'src/app/constants/appColors';
import DownTriangle from 'src/renterDashboard/assets/DownTriangle.svg'

export const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1040px) {
    }
`

export const InputSideContainer = styled.div`
    flex: 1;
    @media (min-width: 1040px) {
      width: 47%;
    }
`

export const SelectSideContainer = styled.div`
    @media (min-width: 1040px) {
      width: 65%;
      display: flex;
      gap: 10px;
    } 

    @media (min-width: 1240px) {
      width: 53%;
    } 
`

export const SearchSelect = styled.select`
    background-color: ${AppColors.LINEN};
    border: 1px solid ${AppColors.SILVER};
    border-radius: 5px;
    color: ${AppColors.BLACK};
    padding: 0 16px;
    height: 46px;
    flex: 1;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.6rem;
    background-image: url(${DownTriangle});
    background-repeat: no-repeat;
    background-position: right 10px center;
    display: none;

    @media (min-width: 1040px) {
      display: block;
    } 
`

export const PropertySearchInput = styled.input`
    background: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: ${AppColors.BLACK};
    padding: 10px 10px;
    width: 100%;
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 2rem;
    position: relative;
    
    ::placeholder {
      color: ${AppColors.SILVER};
      letter-spacing: 0.08px;
    }
    
    @media screen and (max-width: 1400px) {
        font-size: 1.6rem;
    }
`

export const SuggestionContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans';
  width: 96%;
`

export const SuggestionItem = styled.div`
  border: 1px solid black;
  background-color: #fff;
  padding: 5px 2px;
  font-family: 'DM Sans';
  font-size: 2rem;
  cursor: point;
  width: 100%;
  padding: 8px 0 8px 8px;
  
  &:hover {
    background-color: #41b6e6;
  }
  
  @media screen and (max-width: 1400px) {
    font-size: 1.6rem;
  }
`

export const FilterModalBtn = styled.button`
    background-color: ${AppColors.LINEN};
    border: 1px solid ${AppColors.SILVER};
    border-radius: 5px;
    color: ${AppColors.BLACK};
    padding: 9px 3px 10px 3px;
    width: 55px;
    cursor: pointer;
    position: relative;

    img {
      margin: auto;
    }
`

export const SuggestionOuterContainer = styled.div`
    width: 46.5%;
    padding-left: 40px;
    position: absolute;
    z-index: 20;
    margin-top: -10px;
    left: 0;
`
