import React from 'react';
import {
  ListingTableRow,
  ListingTableCell,
  StatusText,
  ShowDescriptionBtn,
  InnerRowContainer,
  ListingImageContainer,
  ListingImage,
  StatusHighlight, PlaceholderImage,
} from 'src/agentDashboard/styles/AgentDashboard.style'
import { AppColors } from 'src/app/constants/appColors'
import moment from 'moment';
import { TableRowDrawer } from 'src/agentDashboard/components/TableRowDrawer';
import { setPublishModalOpen } from 'src/app/reducers/modalSlice';
import { useAppDispatch } from 'src/app/hooks';
import { Listing } from 'src/listing/types/listing.type';
import { dollarFormatter } from 'src/app/utils/dollarFormatter';

interface TableRowProps {
    listing: Listing
    isShaded: boolean
}

export const TableRow = ({ listing, isShaded }: TableRowProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch = useAppDispatch()

  const formattedListPrice = React.useMemo(() => dollarFormatter(listing.rent_price || 0), [listing.rent_price])

  const formattedCreationDate = React.useMemo(() => moment(listing.created_ts).format('M/D/YY'), [listing.created_ts])

  const formattedUpdatedDate = React.useMemo(() => moment(listing.last_updated_ts).format('M/D/YY'), [listing.last_updated_ts])

  const triggerPublishModal = () => {
    dispatch(setPublishModalOpen({
      listing: listing || {},
      currentListingStatus: listing.is_draft || false,
      isOpen: true,
    }))
  }

  return (
    <ListingTableRow isShaded={isShaded}>
      <InnerRowContainer>
        <ListingTableCell omitPadding width="6.5%">
          <ListingImageContainer>
            {listing.images?.[0] ? (
              <ListingImage
                src={listing.images[0]}
                alt={listing.address}
                height="40px"
                width="50px"
              />
            ) : <PlaceholderImage />}
          </ListingImageContainer>
        </ListingTableCell>
        <ListingTableCell width="8%">
          <StatusHighlight isPublished={!listing.is_draft} onClick={triggerPublishModal}>
            <StatusText>{listing.is_draft ? 'Not Published' : 'Published'}</StatusText>
          </StatusHighlight>
        </ListingTableCell>
        <ListingTableCell width="12%">{listing.address}</ListingTableCell>
        <ListingTableCell width="6.5%">{listing.city}</ListingTableCell>
        <ListingTableCell width="5%">{listing.state}</ListingTableCell>
        <ListingTableCell width="5%">{listing.zip_code}</ListingTableCell>
        <ListingTableCell width="6.5%">{formattedListPrice}</ListingTableCell>
        <ListingTableCell width="4%">{listing.beds}BR</ListingTableCell>
        <ListingTableCell width="4%">{listing.bath}BA</ListingTableCell>
        <ListingTableCell width="5%">{listing.sq_ft}ft&#178;</ListingTableCell>
        <ListingTableCell width="6.5%">{formattedCreationDate}</ListingTableCell>
        <ListingTableCell width="6.5%">{formattedUpdatedDate}</ListingTableCell>
        <ListingTableCell width="5.5%">
          <ShowDescriptionBtn type="button" onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? 'More >' : 'Less v'}
          </ShowDescriptionBtn>
        </ListingTableCell>
      </InnerRowContainer>
      {isOpen && <TableRowDrawer description={listing.description || '-'} mlsId={listing.mls_id || '-'} />}
    </ListingTableRow>
  )
}
