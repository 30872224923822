import { AppColors } from 'src/app/constants/appColors'
import styled from 'styled-components'

export const BuildEquity = styled.div`
  margin: 60px 0;
  display: flex;
  justify-content: center;

  @media (min-width: 1040px) {
    margin: 100px 0;
  }

`

export const BuildEquityHeading = styled.h2`
  font-size: 4.8rem;
  text-align: center;
  margin-bottom: 40px;
  

  @media (min-width: 1040px) {
    font-size: 6.4rem;
    margin-bottom: 20px;
    max-width: 80%;
  }
`

export const Become = styled.div`
  margin-top: 100px;

  @media (min-width: 1410px) {
    margin-top: 200px;
  }
`

export const BecomeBlocks = styled.div`
  .desktop-path {
    display: none;

    @media (min-width: 1410px) {
      margin: 0 auto;
      display: block;
    }
  }
`

export const BecomeBlock = styled.div<{maxWidth: number}>`
  text-align: center;
  margin: 0 auto 100px;
  position: relative;
  max-width: 320px;

  @media (min-width: 1410px) {
    position: absolute;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
  }

  &.apply {
    @media (min-width: 1410px) {
      top: -130px;
      left: 150px;
    }
  }

  &.choose {
    @media (min-width: 1410px) {
      top: 7px;
      right: 120px;
    }
  }

  &.rent {
    @media (min-width: 1410px) {
      top: 350px;
      left: 400px;
    }
  }


  &.move {
    @media (min-width: 1410px) {
      position: static;
    }   
  }

  .path,
  .path2 {
    position: absolute;
    top: 180px;
    left: -60px;

    @media (min-width: 1410px) {
      display: none;
    }
  }

  .right-path {
    position: absolute;
    top: 180px;
    right: -60px;

    @media (min-width: 1410px) {
      display: none;
    }
  }
`

export const BecomeBlockImage = styled.div`
  @media (min-width: 1040px) {
  }
`

export const BecomeBlockImg = styled.img`
  margin: 0 auto 24px;

  @media (min-width: 1040px) {
  }
`

export const BecomeBlockTitle = styled.h3`
  font-size: 3.2rem;
  margin-bottom: 10px;

  @media (min-width: 1040px) {
    font-size: 4rem;
  }
`

export const BecomeBlockDesc = styled.p`
  font-size: 1.8rem;

  @media (min-width: 1040px) {
  }
`
export const Questions = styled.div`
  margin: 60px 0;

  @media (min-width: 1040px) {
    margin: 100px 0;
  }
`

export const QuestionsWidth = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

export const QuestionsHeading = styled.h2`
  font-size: 4.8rem;
  text-align: center;

  @media (min-width: 1040px) {
    font-size: 6.4rem;
  }
`

export const QuestionsSubheading = styled.p`
  font-size: 1.8rem;
  text-align: center;
  margin: 10px 0 20px;
`

export const QuestionsForm = styled.form`
`

export const QuestionsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

export const QuestionsField = styled.input`
  line-height: 52px;
  padding: 0 16px;
  background: ${AppColors.WHITE};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export const QuestionsTextarea = styled.textarea`
  padding: 16px;
  height: 200px;
  width: 100%;
  background: ${AppColors.WHITE};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export const QuestionsSubmit = styled.button`
  display: block;
  text-align: center;
  margin-top: 20px;
  padding: 0 32px;
  width: 100%;
  border-radius: 40px;
  background-color: ${AppColors.BLACK};
  color: ${AppColors.WHITE};
  font-family: 'DM Serif Text', serif;
  font-size: 1.8rem;
  line-height: 54px;
  border: 1px solid ${AppColors.BLACK};
  transition: 0.2s opacity, 0.2s transform;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05);
  }
`

export const ColorBlocks = styled.div`
  margin-top: 60px;

  @media (min-width: 1040px) {
    margin-top: 100px;
  }
`

export const ColorBlocksFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 1040px) {
    flex-direction: row;
  }
`

export const ColorBlock = styled.div`
  border-radius: 30px;
  padding: 40px 30px;

  @media (min-width: 1040px) {
    width: 50%;
    padding: 56px;
  }

  &.purple {
    background: ${AppColors.FADED_PURPLE}
  }

  &.blue {
    background: ${AppColors.FADED_BLUE}
  }
`

export const ColorBlockTitle = styled.h3`
  font-size: 3.4rem;

  @media (min-width: 1040px) { 
    font-size: 4rem;
  }
`

export const ColorBlockText = styled.div`
  font-size: 1.8rem;
  margin-top: 15px;

  & > * + * {
    margin-top: 10px;
  }

  li {
    margin-left: 18px;
  }

  li + li {
    margin-top: 5px;
  }

  ol li {
    list-style: decimal;
  }

  ul li {
    list-style: disc;
  }
`
