import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import userReducer from 'src/app/reducers/userSlice'
import modalReducer from 'src/app/reducers/modalSlice'
import searchReducer from 'src/renterDashboard/reducers/searchSlice'
import listingsReducer from 'src/listing/reducers/listingsSlice'
import selectedListingReducer from 'src/listing/reducers/selectedListingSlice'
import loadingReducer from 'src/app/reducers/loadingSlice'
import agentScheduleReducer from 'src/agentDashboard/reducers/agentSchedule'

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    search: searchReducer,
    listings: listingsReducer,
    selectedListing: selectedListingReducer,
    loading: loadingReducer,
    agentSchedule: agentScheduleReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['user/setCognitoUser'],
      ignoredPaths: ['user.cognitoUser'],
    },
  }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
